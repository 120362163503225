import {StageFormFieldProps} from "../../../../core/interface/StageFormFieldProps"; // Adjust the import according to your project structure

export const transformStages = (stages: StageFormFieldProps[]) => {
  return stages.map((stage) => {
    const hasSessionInfo =
      stage.sessionName &&
      stage.sessionMode &&
      stage.sessionAudience &&
      stage.sessionDuration !== null &&
      stage.hostUserType;
    
    return {
      name: stage.name,
      order_number: stage.order_number,
      type: stage.type,
      ...(hasSessionInfo ? {
        session: {
          name: stage.sessionName,
          mode: stage.sessionMode,
          audience: stage.sessionAudience,
          duration: stage.sessionDuration,
          host_user_type: stage.hostUserType,
        }
      } : {}),
    };
  });
};
