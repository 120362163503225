import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {QueryInterface} from "../../../core/interface/query/QueryInterface";
import {fetchQueriesApi, fetchQueryDetailsApi, replyQueryApi} from "./queryApi";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetQueriesApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<QueryInterface[]>>({
    queryKey: ['queries', {page, pageSize}],
    queryFn: () => fetchQueriesApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchQueryDetailsApi = (queryId: any) => {
  return useQuery<DetailsCustomDataResponse<QueryInterface>>({
    queryKey: ['query', {webinarId: queryId}],
    queryFn: () => fetchQueryDetailsApi(queryId),
    enabled: !!queryId
  })
}

export const useReplyQueryApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: ({queryId, data}: { queryId: string; data: any }) => replyQueryApi(queryId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['queries']});
      const message = response?.message || 'Reply message successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['queries']});
    }
  });
};
