import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import {EntityPlanInterface} from "../../../../core/interface/entity/EntityPlanInterface";
import {useToast} from "../../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../../core/enums/toastType";
import {
  createEntityPlanApi, entityPlanCustomerUploadStatusApi,
  fetchEntityPlanCustomersApi,
  fetchEntityPlanDetailsApi,
  fetchEntityPlansApi
} from "./entityPlanApi";
import {
  EntityPlanCustomerInterface,
  EntityPlanCustomerRequest
} from "../../../../core/interface/entity-plan-customer/EntityPlanCustomerInterface";
import {
  EntityPlanCustomerUploadStatusInterface
} from "../../../../core/interface/entity/EntityPlanCustomerUploadStatusInterface";


export const useEntityPlansApi = (entityId: any, page: number, pageSize: number, status?: any) => {
  return useQuery<CustomResponse<EntityPlanInterface[]>>({
    queryKey: ['entityPlans', {entityId, page, pageSize, status}],
    queryFn: () => fetchEntityPlansApi(entityId, page, pageSize, status),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchEntityPlanDetailsApi = (entityId: any, entityPlanId: any) => {
  return useQuery<DetailsCustomDataResponse<EntityPlanInterface>>({
    queryKey: ['entityPlanDetails', {entityId, entityPlanId}],
    queryFn: () => fetchEntityPlanDetailsApi(entityId, entityPlanId),
    enabled: !!entityId && !!entityPlanId,
  });
}

export const useAddEntityPlanApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: ({entityId, planData}: {
      entityId: string | undefined;
      planData: any
    }) => createEntityPlanApi(entityId, planData),
    
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['entities']});
      queryClient.invalidateQueries({queryKey: ['entityPlans']});
      const message = response?.message || 'Entity plan created successfully!';
      showToastMessage({type: ToastType.Success, message});
      return response;
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['entityPlans']});
    }
  });
};

export const useGetEntityPlanCustomersApi = (entityPlanCustomerRequest: EntityPlanCustomerRequest) => {
  return useQuery<CustomResponse<EntityPlanCustomerInterface[]>>({
    queryKey: ['entityPlanCustomers', { entityPlanCustomerRequest }],
    queryFn: () => fetchEntityPlanCustomersApi(entityPlanCustomerRequest),
    retry: false,
    staleTime: 300000,
  });
};

export const useEntityPlanCustomerUploadStatusApi = (entityId: any, entityPlanId: any) => {
  return useQuery<DetailsCustomDataResponse<EntityPlanCustomerUploadStatusInterface>>({
    queryKey: ['entityPlanCustomerUploadStatus', {entityId, entityPlanId}],
    queryFn: () => entityPlanCustomerUploadStatusApi(entityId, entityPlanId),
    enabled: !!entityId && !!entityPlanId,
  });
}
