import React, {useState} from "react";
import {Avatar, Button, Pagination, Spinner, Table, Tooltip} from "flowbite-react";
import {Link} from "react-router-dom";
import {IoEyeOutline} from "react-icons/io5";
import {useGetWebinarsApi} from "../services/useWebinarApi";
import {WebinarInterface} from "../../../core/interface/webinar/WebinarInterface";
import {FiEdit} from "react-icons/fi";
import {FaPlus} from "react-icons/fa6";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";

const Webinars: React.FC = () => {
  
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, isLoading} = useGetWebinarsApi(currentPage, pageSize);
  const webinars: WebinarInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-semibold">Webinars</h1>
        <div className="flex gap-4">
          <Link to="create-webinar">
            <Button color="blue">
              <span className={`flex items-center`}>
                <FaPlus className={`mr-2`}/> Add Webinars
              </span>
            </Button>
          </Link>
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className="border-b border-gray-200">
            <Table.HeadCell className="text-gray-500">Image</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Webinar Titlle</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Description</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Date and time</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Host</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Actions</Table.HeadCell>
          </Table.Head>
          
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : totalCount === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <div className="flex justify-center items-center py-5">
                    <div className="text-center text-gray-500">No query records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {webinars.map((webinar) => (
                <Table.Row key={webinar.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>
                    <Avatar rounded img={webinar?.image_url}/>
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {webinar.name.length > 20 ? `${webinar.name.substring(0, 20)}...` : webinar.name}
                  </Table.Cell>
                  <Table.Cell>
                    {webinar.info.length > 50 ? `${webinar.info.substring(0, 50)}...` : webinar.info}
                  </Table.Cell>
                  <Table.Cell>
                    {dateAndTimeFormatter(webinar?.datetime, 'DD-MM-YYYY,  h:mm a')}
                  </Table.Cell>
                  <Table.Cell>
                    {webinar.host_name}
                  </Table.Cell>
                  <Table.Cell className="flex items-center space-x-2">
                    <Tooltip content="View" placement="bottom">
                      <Link to={`view-webinar/${webinar.id}`}>
                        <Button color="none" size="xs">
                          <IoEyeOutline size={`20`}/>
                        </Button>
                      </Link>
                    </Tooltip>
                    <Tooltip content="Edit" placement="bottom">
                      <Link to={`update-webinar/${webinar.id}`}>
                        <Button color="none" size="xs">
                          <FiEdit size="20"/>
                        </Button>
                      </Link>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
        
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing{" "}
              <span className="font-semibold text-black">
          {pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}
        </span>{" "}
              of <span className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Webinars;
