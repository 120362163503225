import React from "react";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import { Button, Spinner } from "flowbite-react";
import { useFetchEntityPlanDetailsApi } from "../services/useEntityPlanApi";
import StageSwiper from "../../../../shared/components/custom-swiper/stageSwiper";

const EntityPlanDetails: React.FC = () => {
  const location = useLocation();
  const { entityId, entityPlanId } = useParams<{ entityId: string; entityPlanId: string }>();
  const { entityName } = location.state || {};
  const { data, isLoading } = useFetchEntityPlanDetailsApi(entityId, entityPlanId);
  const entityPlanDetails = data?.data;
  
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <BackButton
            label="Back"
            to={`/entities/${entityId}/entity-plans`}
            stateName="entityName"
            stateValue={entityName}
          />
          <h1 className="text-xl font-bold mb-4">View Entity Plan</h1>
          <CustomBreadcrumb
            items={[
              { label: "Entities", to: "/entities" },
              { label: entityName },
              {
                label: "Entity Plans",
                to: `/entities/${entityId}/entity-plans`,
                stateName: "entityName",
                stateValue: entityName,
              },
              { label: "Entity Plan Details" },
            ]}
          />
        </div>
        <Button disabled color="blue">
          Upgrade Plan
        </Button>
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg" />
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <div className="flex justify-between items-start mb-2">
            <h5 className="text-lg font-semibold text-gray-900">{entityPlanDetails?.plan_name}</h5>
            <span className="md:hidden px-2 py-1 text-xs font-medium text-blue-600 bg-blue-100 rounded-md">
              Plan Type: {entityPlanDetails?.plan_type || "N/A"}
            </span>
          </div>
          <div className="flex flex-wrap items-center text-sm font-medium mb-5">
            <span className="hidden md:flex items-center mr-4 px-2 py-1 rounded-md text-blue-700 bg-blue-100">
              Plan Type: <span className="ml-2">{entityPlanDetails?.plan_type}</span>
            </span>
            <span className="mr-4 text-gray-900">
              Start Date: <span className="ml-2 text-blue-700">{entityPlanDetails?.start_date || ""}</span>
            </span>
            <span className="mr-4 text-gray-900">
              End Date: <span className="ml-2 text-blue-700">{entityPlanDetails?.expiry_date || ""}</span>
            </span>
          </div>
          
          {entityPlanDetails?.entity_plan_stages && (
            <StageSwiper stages={entityPlanDetails.entity_plan_stages} entityName={entityName} />
          )}
        </div>
      )}
    </div>
  );
};

export default EntityPlanDetails;
