import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Button, Pagination, Spinner, Table, Tooltip} from "flowbite-react";
import {FaCircleExclamation, FaPlus} from "react-icons/fa6";
import {IoEyeOutline} from "react-icons/io5";
import {FiEdit} from "react-icons/fi";
import {useDeleteBlogApi, useGetBlogsApi} from "../services/useBlogsApi";
import {BlogInterface} from "../../../core/interface/blog/BlogInterface";
import ConfirmDialog from "../../../shared/components/modals/confirmDialog";
import {RiDeleteBin5Line} from "react-icons/ri";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";

const Blogs: React.FC = () => {
  
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [blogId, setBlogId] = useState('');
  const [blogTitle, setBlogTitle] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  
  const {mutate: deleteBlog} = useDeleteBlogApi();
  const {data, isLoading} = useGetBlogsApi(currentPage, pageSize);
  const blogs: BlogInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const openConfirmDialog = (blog: BlogInterface) => {
    setIsConfirmDialogVisible(true);
    setBlogId(blog.id);
    setBlogTitle(blog.title);
  }
  
  const handleConfirmDialogClick = () => {
    setLoading(true);
    deleteBlog(blogId, {
      onSettled: () => {
        setLoading(false);
        setIsConfirmDialogVisible(false);
      }
    });
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-semibold">Blogs</h1>
        <div className="flex gap-4">
          <Link to="create-blog">
            <Button color="blue">
              <span className={`flex items-center`}>
                <FaPlus className={`mr-2`}/> Add Blogs
              </span>
            </Button>
          </Link>
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className="border-b border-gray-200">
            <Table.HeadCell className="text-gray-500">Image</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Blog Title</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Description</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Date</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Actions</Table.HeadCell>
          </Table.Head>
          
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : totalCount === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <div className="text-center text-gray-500">No query records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {blogs.map((blog) => (
                <Table.Row key={blog.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>
                    <img
                      src={blog?.thumbnail_image_url}
                      alt={blog?.title}
                      className="w-32 h-20 object-cover rounded-md"
                    />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {blog.title.length > 20 ? `${blog.title.substring(0, 20)}...` : blog.title}
                  </Table.Cell>
                  <Table.Cell>
                    {blog.desc.length > 50 ? `${blog.desc.substring(0, 50)}...` : blog.desc}
                  </Table.Cell>
                  <Table.Cell>
                    {dateAndTimeFormatter(blog?.created_at, 'DD-MM-YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex items-center space-x-2">
                      <Tooltip content="View" placement="bottom">
                        <Link to={`view-blog/${blog.id}`}>
                          <Button color="none" size="xs">
                            <IoEyeOutline size={`20`}/>
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Edit" placement="bottom">
                        <Link to={`update-blog/${blog.id}`}>
                          <Button color="none" size="xs">
                            <FiEdit size="20"/>
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Delete" placement="bottom">
                        <Button color="none" size="xs" onClick={() => openConfirmDialog(blog)}>
                          <RiDeleteBin5Line size="20"/>
                        </Button>
                      </Tooltip>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
        
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing{" "}
              <span className="font-semibold text-black">
          {pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}
        </span>{" "}
              of <span className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons
            />
          </div>
        )}
      </div>
      
      <ConfirmDialog
        submitButtonText={`Confirm`}
        submitButtonClass={`blue`}
        cancelButtonText={`Cancel`}
        showCancelButton={true}
        loading={loading}
        icon={<FaCircleExclamation className="mx-auto mb-4 h-14 w-14 text-red-700"/>}
        isVisible={isConfirmDialogVisible}
        onClose={() => setIsConfirmDialogVisible(false)}
        onClick={handleConfirmDialogClick}
        title="Delete Blog"
        subTitle={
          <>
            Once a blog is delete, blog can't be used anymore. Do you really want to delete the blog <span
            className="text-blue-600">{blogTitle}</span>?
          </>
        }
      />
    </div>
  );
};

export default Blogs;
