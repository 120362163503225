import React from "react";
import {EntityAdminViewProps} from "../../../../../core/interface/entity-admin/EntityAdminViewProps";
import ProfileField from "../../../../../shared/components/profile-field/profileField";
import {Button} from "flowbite-react";
import {FaCircleExclamation} from "react-icons/fa6";
import ConfirmDialog from "../../../../../shared/components/modals/confirmDialog";
import {useDisableEntityAdminApi} from "../../services/useEntityAdminApi";
import {DisableRequest} from "../../../../../core/interface/DisableRequest";

const EntityAdminView: React.FC<EntityAdminViewProps> = (props: EntityAdminViewProps) => {
  
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  
  const {mutate: disableEntityAdmin} = useDisableEntityAdminApi();
  
  const handleCreateClick = () => {
    setLoading(true);
    const data: DisableRequest = {
      id: props.entityAdmin?.id,
      reason: '',
      status: 'DISABLE',
    }
    
    disableEntityAdmin({data}, {
      onSuccess: () => {
        setLoading(false);
      },
      onSettled: () => {
        setIsConfirmDialogVisible(false);
        setLoading(true);
      }
    })
  }
  
  return (
    <div>
      <h3 className={`text-base font-bold border-b pb-2 mb-5`}>{props.title}</h3>
      <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
        <ProfileField label={`First name`} value={props.entityAdmin?.first_name}/>
        <ProfileField label={`Last name`} value={props.entityAdmin?.last_name}/>
        <ProfileField label={`Email`} value={props.entityAdmin?.email}/>
        <ProfileField label={`Phone`} value={props.entityAdmin?.phone_number}/>
      </div>
      
      {props.isButton && (
        <Button outline color='blue' size='lg' onClick={() => setIsConfirmDialogVisible(true)}>
          Delete Admin
        </Button>
      )}
      
      <ConfirmDialog
        submitButtonText={`Confirm`}
        submitButtonClass={`blue`}
        cancelButtonText={`Cancel`}
        showCancelButton={true}
        icon={<FaCircleExclamation className="mx-auto mb-4 h-14 w-14 text-red-700"/>}
        isVisible={isConfirmDialogVisible}
        loading={loading}
        onClose={() => setIsConfirmDialogVisible(false)}
        onClick={handleCreateClick}
        title="Delete Admin"
        subTitle='Are you sure you want to really delete the admin?'
      />
    </div>
  )
}

export default EntityAdminView;
