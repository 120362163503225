import React, {useState} from "react";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import SearchInputBox from "../../../../shared/components/custom-search-box/searchInputBox";
import {Button, Pagination, Spinner, Table, ToggleSwitch, Tooltip} from "flowbite-react";
import {useLocation, useParams} from "react-router-dom";
import {FiEdit} from "react-icons/fi";
import {useGetEntityPlanCustomersApi} from "../services/useEntityPlanApi";
import {PiUploadBold} from "react-icons/pi";
import {TbFileExport} from "react-icons/tb";
import AddCustomerDialog from "../../../users/customers/components/add-customer-dialog/addCustomerDialog";
import {
  EntityPlanCustomerInterface,
  EntityPlanCustomerRequest,
} from "../../../../core/interface/entity-plan-customer/EntityPlanCustomerInterface";
import {FaCircleExclamation} from "react-icons/fa6";
import ConfirmDialog from "../../../../shared/components/modals/confirmDialog";
import {DisableRequest} from "../../../../core/interface/DisableRequest";
import {useDisableCustomerApi, useExportCustomersApi} from "../../../users/customers/services/useCustomerApi";
import CustomLoaderButton from "../../../../shared/components/custom-buttons/customLoaderButton";
import {useToast} from "../../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../../core/enums/toastType";

const EntityPlanCustomers: React.FC = () => {
  const pageSize = 10;
  const location = useLocation();
  
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isAddCustomerFormDialog, setIsAddCustomerFormDialog] = useState(false);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<EntityPlanCustomerInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isExportCustomer, setIsExportCustomer] = useState<boolean>(false);
  
  const {showToastMessage} = useToast();
  const {entityId} = useParams<{ entityId: string }>();
  const {entityPlanId} = useParams<{ entityPlanId: string }>();
  const {entityName} = location.state || {};
  
  const searchValue = searchQuery.length > 2 ? searchQuery : "";
  
  const {mutate: disableCustomer} = useDisableCustomerApi();
  const entityPlanCustomerRequest: EntityPlanCustomerRequest = {
    entityId,
    entityPlanId,
    currentPage,
    searchValue,
  };
  const {data, isLoading} = useGetEntityPlanCustomersApi(entityPlanCustomerRequest);
  const entityPlanCustomers: EntityPlanCustomerInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const {
    refetch: fetchExportCustomers,
    isFetching: isExporting
  } = useExportCustomersApi(isExportCustomer, entityId, entityPlanId);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(false);
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  
  const openConfirmDialog = (customer: EntityPlanCustomerInterface) => {
    setSelectedCustomer(customer);
    setIsConfirmDialogVisible(true);
  };
  
  const handleConfirmDialogClick = () => {
    setLoading(true);
    const data: DisableRequest = {
      id: selectedCustomer?.id,
      reason: "",
      status: "DISABLE",
    };
    
    disableCustomer(
      {data},
      {
        onSuccess: () => {
          setLoading(false);
        },
        onSettled: () => {
          setLoading(true);
          setIsConfirmDialogVisible(false);
        },
      }
    );
  };
  
  const handleCustomerExport = async () => {
    setIsExportCustomer(true);
    try {
      const result = await fetchExportCustomers();
      
      if (result?.data) {
        const { data, headers } = result.data;
        const blob = new Blob([data], { type: "application/pdf" });
        const contentDisposition = headers["content-disposition"];
        const filename = contentDisposition
          ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
          : `customers_export_${entityId}_${entityPlanId}.pdf`;
        
        // Create and download the file
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
        
        // Show success toast message
        showToastMessage({ type: ToastType.Success, message: "PDF file downloaded successfully!" });
      } else {
        throw new Error("No data received");
      }
      setIsExportCustomer(false);
    } catch (error) {
      showToastMessage({ type: ToastType.Error, message: "Failed to download the PDF file." });
      setIsExportCustomer(false);
    }
  };
  
  
  
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <div className="mb-4">
            <BackButton to="/entities" label="Back"/>
          </div>
          <h1 className="text-xl font-bold mb-4">Customers</h1>
          <CustomBreadcrumb
            items={[
              {label: "Entities", to: "/entities"},
              {label: entityName},
              {
                label: "Entity Plans",
                to: `/entities/${entityId}/entity-plans`,
                stateName: "entityName",
                stateValue: entityName,
              },
              {label: "Customers"},
            ]}
          />
        </div>
        <div className="flex gap-4">
          <SearchInputBox
            className="w-80"
            placeholderName="Search Name, Entity Name..."
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
          />
          <Button color="blue" onClick={() => setIsAddCustomerFormDialog(true)}>
            Add Customer
          </Button>
          <Button outline color="blue" disabled={true}>
            <PiUploadBold className="text-blue-600 mr-1" size={20}/>
            Upload CSV
          </Button>
          <CustomLoaderButton
            outline={true} color='blue' loading={isExporting}
            disabled={entityPlanCustomers.length === 0} onClick={handleCustomerExport}>
            <TbFileExport className="text-blue-600 mr-1" size={20}/> Export
          </CustomLoaderButton>
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        {/* Render Table */}
        <Table striped>
          <Table.Head className="border-b border-gray-200">
            <Table.HeadCell>NAME</Table.HeadCell>
            <Table.HeadCell>ENROLLMENT NUMBER</Table.HeadCell>
            <Table.HeadCell>GRADE</Table.HeadCell>
            <Table.HeadCell>PHONE</Table.HeadCell>
            <Table.HeadCell>STATUS</Table.HeadCell>
            <Table.HeadCell>ACTION</Table.HeadCell>
          </Table.Head>
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : entityPlanCustomers.length === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <div className="flex justify-center items-center py-5">
                    <div className="text-center text-gray-500">No entity plan customer records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {entityPlanCustomers.map((entityPlanCustomer) => (
                <Table.Row key={entityPlanCustomer.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>
                    {entityPlanCustomer.first_name} {entityPlanCustomer.last_name}
                  </Table.Cell>
                  <Table.Cell>{entityPlanCustomer.enrollment_number}</Table.Cell>
                  <Table.Cell>{entityPlanCustomer.grade}</Table.Cell>
                  <Table.Cell>{entityPlanCustomer.phone_number}</Table.Cell>
                  <Table.Cell>
                    <ToggleSwitch
                      disabled={!entityPlanCustomer.is_active}
                      checked={entityPlanCustomer?.is_active}
                      label={entityPlanCustomer.is_active ? "Active" : "Inactive"}
                      onChange={() => openConfirmDialog(entityPlanCustomer)}
                    />
                  </Table.Cell>
                  <Table.Cell className="flex items-center space-x-2">
                    <Tooltip content="Edit" placement="bottom">
                      <Button
                        color="none"
                        size="xs"
                        onClick={() => {
                          setIsAddCustomerFormDialog(true);
                          setSelectedCustomer(entityPlanCustomer);
                        }}
                      >
                        <FiEdit size="20"/>
                      </Button>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
        
        {/* Pagination */}
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing{" "}
              <span className="font-semibold text-black">
                {pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}
              </span>{" "}
              of <span className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
      
      {/* Modals */}
      <AddCustomerDialog
        entityPlanCustomer={selectedCustomer}
        entityId={entityId}
        entityPlanId={entityPlanId}
        isOpen={isAddCustomerFormDialog}
        onClose={() => {
          setIsAddCustomerFormDialog(false);
          setSelectedCustomer(null);
        }}
      />
      <ConfirmDialog
        title="Delete Customer"
        submitButtonText={`Confirm`}
        submitButtonClass={`blue`}
        cancelButtonText={`Cancel`}
        showCancelButton={true}
        icon={<FaCircleExclamation className="mx-auto mb-4 h-14 w-14 text-red-700"/>}
        isVisible={isConfirmDialogVisible}
        loading={loading}
        onClose={() => setIsConfirmDialogVisible(false)}
        onClick={handleConfirmDialogClick}
        subTitle={
          <>
            Once a customer is disabled, plan can't be used anymore. Do you really want to disable the customer{" "}
            <span className="text-blue-600">
              "{selectedCustomer?.first_name} {selectedCustomer?.last_name}"
            </span>
            ?
          </>
        }
      />
    </div>
  );
};

export default EntityPlanCustomers;
