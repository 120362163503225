import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Checkbox, Spinner, Table } from "flowbite-react";
import DropdownCheckboxFilter from "../../../../../shared/components/custom-dropdown-box/dropdownCheckboxFilter";
import { Grades } from "../../../../../core/enums/grades";
import { useGetPlansApi } from "../../../../plans/services/usePlanApi";
import { PlanInterface } from "../../../../../core/interface/plan/PlanInterface";
import {ChoosePlanStepProps} from "../../../../../core/interface/entity/ChoosePlanStepProps";

const ChoosePlanStep: React.FC<ChoosePlanStepProps> = (props: ChoosePlanStepProps) => {
  const gradesOptions = useMemo(() => Object.values(Grades), []);
  const { data, isLoading } = useGetPlansApi();
  
  const plans: PlanInterface[] = useMemo(() => data?.data.items || [], [data]);
  const totalCount = data?.data.total || 0;
  
  const [selectedGrades, setSelectedGrades] = useState<string[]>([]);
  const [filteredPlans, setFilteredPlans] = useState<PlanInterface[]>(plans);
  const [selectedPlan, setSelectedPlan] = useState<PlanInterface | null>(null);
  
  useEffect(() => {
    if (selectedGrades.length > 0) {
      const filtered = plans.filter(plan =>
        selectedGrades.every(grade => plan.applicable_grades.includes(grade as any))
      );
      setFilteredPlans(filtered);
    } else {
      setFilteredPlans(plans);
    }
  }, [plans, selectedGrades]);
  
  const handleSelectedGradesChange = (newSelectedGrades: string[]) => {
    setSelectedPlan(null);
    setSelectedGrades(newSelectedGrades);
  };
  
  const handleRowClick = (plan: PlanInterface) => {
    setSelectedPlan(plan);
  };
  
  const handleNext = () => {
    if (selectedPlan) {
      props.onSelectPlan(selectedPlan);
      props.goToNextStep();
    }
  };
  
  return (
    <div>
      <div className="flex justify-end items-center mb-4 px-4">
        <DropdownCheckboxFilter
          title="Grades"
          className="w-48"
          options={gradesOptions.map(option => ({
            value: option,
            label: option.replace(/-/g, " ")
          }))}
          selectedOptions={selectedGrades}
          onChange={handleSelectedGradesChange}
        />
      </div>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <div className="overflow-x-auto">
          <Table striped>
            <Table.Head className="border-b border-gray-200">
              <Table.HeadCell className="text-gray-500"></Table.HeadCell>
              <Table.HeadCell className="text-gray-500">Plan Name</Table.HeadCell>
              <Table.HeadCell className="text-gray-500">Plan Type</Table.HeadCell>
              <Table.HeadCell className="text-gray-500">Applicable Grades</Table.HeadCell>
              <Table.HeadCell className="text-gray-500">Validity</Table.HeadCell>
            </Table.Head>
            {isLoading ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={4}>
                    <div className="flex justify-center items-center py-5">
                      <Spinner size="lg" />
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : totalCount === 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={4}>
                    <div className="flex justify-center items-center py-5">
                      <div className="text-center text-gray-500">No plan records found.</div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body className="divide-y">
                {filteredPlans.map(plan => (
                  <Table.Row
                    key={plan.id}
                    className={`cursor-pointer ${
                      selectedPlan?.id === plan.id ? "bg-gray-200" : "bg-white"
                    } dark:border-gray-700 dark:bg-gray-800 text-gray-900 dark:text-white`}
                    onClick={() => handleRowClick(plan)}
                  >
                    <Table.Cell>
                      <Checkbox
                        id={plan.id}
                        name="selectedPlan"
                        checked={selectedPlan?.id === plan.id}
                        onChange={() => handleRowClick(plan)}
                      />
                    </Table.Cell>
                    <Table.Cell>{plan.name}</Table.Cell>
                    <Table.Cell>{plan.type}</Table.Cell>
                    <Table.Cell className="flex gap-2">
                      {plan.applicable_grades.map((grade, idx) => (
                        <Badge className="px-1 text-sm text-gray-600" key={idx} color="gray">
                          {grade}
                        </Badge>
                      ))}
                    </Table.Cell>
                    <Table.Cell>{plan.validity_duration} {plan.validity_type}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>
        </div>
      </div>
      
      <div className="flex space-x-2 justify-end items-center p-6 border-t">
        <Button type="button" disabled={!selectedPlan} color="blue" onClick={handleNext}>
          Next: Create Plan
        </Button>
      </div>
    </div>
  );
};

export default ChoosePlanStep;
