import React, {useEffect, useMemo, useState} from "react";
import {Avatar, Button, FileInput, Label} from "flowbite-react";
import {useNavigate, useParams} from "react-router-dom";
import {IoClose} from "react-icons/io5";
import {Department} from "../../../../core/enums/department";
import {Gender} from "../../../../core/enums/Gender";
import {Roles} from "../../../../core/enums/roles";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import authService from "../../../auth/api/authService";
import {useCreateStaffApi, useFetchStaffDetailsApi, useUpdateStaffApi} from "../services/useStaffApi";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Designation} from "../../../../core/enums/designation";
import {ExpertGroup, ExpertSubGroup} from "../../../../core/enums/expertGroup";
import FormInput from "../../../../shared/components/form-wrapper/FormInput";
import FormTextarea from "../../../../shared/components/form-wrapper/FormTextarea";
import FormSelect from "../../../../shared/components/form-wrapper/FormSelect";
import CustomLoaderButton from "../../../../shared/components/custom-buttons/customLoaderButton";
import {StaffInterface} from "../../../../core/interface/staff/StaffInterface";
import {genderLabels} from "../../../../shared/utils/custom-lavel/genderLabels";
import FormDatePicker from "../../../../shared/components/form-wrapper/formDatePicker";

const validationSchema = (isExpertRole: boolean) =>
  yup.object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    designation: yup.string().required("Designation is required"),
    department: yup.string().required("Department is required"),
    date_of_joining: yup.string().required("Date of joining is required"),
    gender: yup.string().required("Gender is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    phone_number: yup.string()
      .required('Phone number is required')
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .test('not-starts-with-0-5', 'Enter a valid phone number', (value: any) => {
        return value && !/^[0-5]/.test(value);
      }),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    pincode: yup.string().matches(/^\d{6}$/, "Pincode must be exactly 6 digits").required("Pincode is required"),
    date_of_resignation: yup.string().required("Date of resignation is required"),
    upload_profile_image: yup.mixed(),
    date_of_birth: yup.string().required("Date of birth is required"),
    address: yup.string(),
    qualifications: yup.array().of(yup.string().required("Qualification is required")).required("Qualifications are required"),
    bio_data: yup.string(),
    skills: yup.array().of(yup.string().required("Skill is required")).required("Skills are required"),
    role: yup.string().required("Role is required"),
    group: isExpertRole ? yup.string().required("Expert group is required") : yup.string(),
    subgroup: isExpertRole ? yup.string().required("Expert sub-group is required") : yup.string(),
    video: yup.mixed(),
  });

const StaffCreate: React.FC = () => {
  
  const {id} = useParams<{ id: string }>();
  const {data} = useFetchStaffDetailsApi(id);
  const staffDetails: StaffInterface | undefined = data?.data;
  
  const navigate = useNavigate();
  const designationOptions = useMemo(() => Object.values(Designation), []);
  const departmentOptions = useMemo(() => Object.values(Department), []);
  const genderOptions = useMemo(() => {
    return Object.values(Gender).map(option => ({
      value: option,
      label: genderLabels[option],
    }));
  }, []);
  const expertGroupOptions = useMemo(() => Object.values(ExpertGroup), []);
  const expertSubGroupOptions = useMemo(() => Object.values(ExpertSubGroup), []);
  
  const [roleOptions, setRoleOptions] = useState<string[]>([]);
  const [skills, setSkills] = useState<string[]>([]);
  const [qualifications, setQualifications] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isExpertRole, setIsExpertRole] = useState(false);
  const [isChangeImage, setIsChangeImage] = useState(false);
  const [isChangeVideo, setIsChangeVideo] = useState(false);
  
  const {mutate: createStaff} = useCreateStaffApi();
  const {mutate: updateStaff} = useUpdateStaffApi();
  const {register, handleSubmit, setValue, formState: {errors}} = useForm({
    resolver: yupResolver(validationSchema(isExpertRole)),
  });
  
  const handleKeyDownQualification = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget.value.trim() !== "") {
      event.preventDefault();
      const newQualification = event.currentTarget.value.trim();
      setQualifications((prev) => [...prev, newQualification]);
      setValue('qualifications', [...qualifications, newQualification]);
      event.currentTarget.value = "";
    }
  };
  
  const removeQualification = (qualification: string) => {
    const updatedQualifications = qualifications.filter(q => q !== qualification);
    setQualifications(updatedQualifications);
    setValue('qualifications', updatedQualifications);
  };
  
  const handleKeyDownSkill = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget.value.trim() !== "") {
      event.preventDefault();
      const newSkill = event.currentTarget.value.trim();
      setSkills((prev) => [...prev, newSkill]);
      setValue('skills', [...skills, newSkill]);
      event.currentTarget.value = "";
    }
  };
  
  const removeSkill = (skill: string) => {
    const updatedSkills = skills.filter(s => s !== skill);
    setSkills(updatedSkills);
    setValue('skills', updatedSkills);
  };
  
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const role = await authService.getUserRole();
        
        if (role === Roles.SUPER_ADMIN) {
          setRoleOptions([Roles.STAFF_ADMIN]);
        } else if (role === Roles.STAFF_ADMIN) {
          const filteredRoles = Object.values(Roles).filter(
            (r) => r !== Roles.SUPER_ADMIN && r !== Roles.STAFF_ADMIN
          );
          setRoleOptions(filteredRoles);
        }
      } catch (error) {
        await authService.signOut();
      }
    };
    
    fetchUserRole();
  }, []);
  
  useEffect(() => {
    if (staffDetails) {
      Object.entries(staffDetails).forEach(([key, value]) => {
        setValue(key as any, value);
      });
      setSkills(staffDetails?.skills);
      setQualifications(staffDetails?.qualifications);
      
      const formattedPhoneNumber = staffDetails?.phone_number?.replace(/^\+91/, '') || '';
      setValue("phone_number", formattedPhoneNumber);
      
      if (staffDetails?.group && staffDetails?.subgroup) {
        setIsExpertRole(true);
        setValue("group", staffDetails?.group);
        setValue("subgroup", staffDetails?.subgroup);
        if (!isChangeVideo) {
          setValue("video", '');
        } else {
          setValue("video", staffDetails?.video);
        }
      }
    }
  }, [id, setValue, staffDetails, isChangeVideo]);
  
  const handleRoleChange = (e: any) => {
    const selectedRole = e.target.value;
    if (selectedRole.includes('INDUSTRY-EXPERT')) {
      setIsExpertRole(true);
    } else {
      setIsExpertRole(false);
      setValue('group', '');
      setValue('subgroup', '');
      setValue('video', '');
    }
  };
  
  const onSubmit = async (data: any) => {
    setLoading(true);
    data.phone_number = `+91${data.phone_number}`;
    const {upload_profile_image, group, subgroup, video, ...json_data} = data;
    
    if (isExpertRole) {
      Object.assign(json_data, {group, subgroup});
    }
    
    const staffFormData = {
      json_data,
      image: upload_profile_image?.[0],
      video: video?.[0],
    };
    
    const callbackConfig = {
      onSuccess: () => navigate('/staffs'),
      onSettled: () => setLoading(false),
      onError: () => setLoading(false),
    };
    
    id ? updateStaff({staffId: id, data: staffFormData}, callbackConfig) : createStaff(staffFormData, callbackConfig);
  };
  
  
  return (
    <div>
      <div className="mb-4">
        <div className="mb-4">
          <BackButton to="/staffs" label="Back"/>
        </div>
        <h1 className="text-xl font-bold mb-4">
          {id ? 'Update Staff' : 'Create Staff'}
        </h1>
        <CustomBreadcrumb
          items={[
            {label: 'User'},
            {label: 'Staffs', to: '/staffs'},
            {label: `${id ? 'Update Staff' : 'Create Staff'}`},
          ]}
        />
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex space-x-6 mb-4">
            <FormInput
              className='w-1/2'
              label="First name"
              important={true}
              register={register("first_name")}
              error={errors.first_name?.message}
              placeholder="Enter first name"
              disabled={loading}
            />
            <FormInput
              className='w-1/2'
              label="Last name"
              important={true}
              register={register("last_name")}
              error={errors.last_name?.message}
              placeholder="Enter last name"
              disabled={loading}
            />
          </div>
          
          <div className="flex space-x-6 mb-4">
            <FormSelect
              className='w-1/2'
              label="Designation"
              important={true}
              options={designationOptions.map((option) => ({value: option, label: option}))}
              register={register("designation")}
              disabled={loading}
              error={errors.designation?.message}
            />
            <FormSelect
              className='w-1/2'
              label="Department"
              important={true}
              options={departmentOptions.map((option) => ({value: option, label: option}))}
              register={register("department")}
              disabled={loading}
              error={errors.department?.message}
            />
          </div>
          
          <div className="flex space-x-6 mb-4">
            <FormDatePicker
              className='w-1/2'
              important={true}
              label="Date of joining"
              register={register("date_of_joining")}
              placeholder="Select a date"
              disabled={loading}
              error={errors.date_of_joining?.message}
            />
            <FormDatePicker
              className='w-1/2'
              important={true}
              label="Date of resignation"
              register={register("date_of_resignation")}
              placeholder="Select a date"
              disabled={loading}
              error={errors.date_of_resignation?.message}
            />
          </div>
          
          <div className="flex space-x-6 mb-4">
            <FormSelect
              className='w-1/2'
              label="Gender"
              important={true}
              options={genderOptions}
              register={register("gender")}
              disabled={loading}
              error={errors.gender?.message}
            />
            <FormDatePicker
              className='w-1/2'
              important={true}
              label="Date of birth"
              register={register("date_of_birth")}
              placeholder="Select a date"
              disabled={loading}
              error={errors.date_of_birth?.message}
            />
          </div>
          
          <div className="flex space-x-6 mb-4">
            <FormInput
              className='w-1/2'
              label="Email"
              disabled={!!id || loading}
              important={true}
              type="email"
              register={register("email")}
              error={errors.email?.message}
              placeholder="Enter email"
            />
            <FormInput
              className='w-1/2'
              label="Phone number"
              important={true}
              addon="+91"
              type="tel"
              register={register("phone_number")}
              error={errors.phone_number?.message}
              placeholder="Enter phone number"
              disabled={loading}
            />
          </div>
          
          <div className={`flex space-x-6 mb-4`}>
            <FormInput
              className='w-1/2'
              label="City"
              important={true}
              type="text"
              register={register("city")}
              error={errors.city?.message}
              placeholder="Enter city"
              disabled={loading}
            />
            <FormInput
              className='w-1/2'
              label="State"
              important={true}
              type="text"
              register={register("state")}
              error={errors.state?.message}
              placeholder="Enter state"
              disabled={loading}
            />
          </div>
          
          <div className={`flex space-x-6 mb-4`}>
            <FormInput
              className='w-1/2'
              label="Pincode"
              type="tel"
              important={true}
              register={register("pincode")}
              error={errors.pincode?.message}
              placeholder="Enter pincode"
              disabled={loading}
            />
            <FormTextarea
              className={`w-1/2`}
              label="Address"
              register={register("address")}
              error={errors.address?.message}
              placeholder="Enter address"
              disabled={loading}
            />
          </div>
          
          <div className="flex space-x-6 mb-4">
            <div className="w-1/2">
              <Label htmlFor="upload_profile_image" className="mb-2 block">
                Upload profile image
              </Label>
              {id && !isChangeImage ? (
                <div className='flex items-center space-x-4'>
                  <Avatar size='lg' img={staffDetails?.profile_image} alt={staffDetails?.first_name} rounded/>
                  <Button outline color='blue' onClick={() => setIsChangeImage(true)}>Change Image</Button>
                </div>
              ) : (
                <FileInput {...register('upload_profile_image')} disabled={loading}/>
              )}
            </div>
            <div className="w-1/2">
              <Label htmlFor="qualification" className="mb-2 block">
                Qualifications<em>*</em>
              </Label>
              
              <div
                className="flex items-center flex-wrap border border-gray-300 rounded px-2 py-1 focus-within:border-green-500">
                {qualifications.map((qualification, index) => (
                  <span
                    key={index}
                    className="flex items-center bg-gray-100 rounded-md px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-1">
                    {qualification}
                    <button type="button" className="ml-1 text-gray-500" disabled={loading}
                            onClick={() => removeQualification(qualification)}>
                      <IoClose size={20}/>
                      </button>
                  </span>
                ))}
                
                <input
                  disabled={loading}
                  id="qualification"
                  type="text"
                  className="flex-grow border-none focus:ring-0 outline-none"
                  placeholder="Enter qualification and press Enter"
                  onKeyDown={handleKeyDownQualification}
                />
              </div>
              
              {errors.qualifications && (
                <span className="text-red-600">{errors.qualifications.message}</span>
              )}
            </div>
          </div>
          
          <div className="flex space-x-6 mb-4">
            <FormTextarea
              className={`w-1/2`}
              label="Bio data"
              register={register("bio_data")}
              error={errors.address?.message}
              placeholder="Write bio..."
              disabled={loading}
            />
            <div className="w-1/2">
              <Label htmlFor="skills" className="mb-2 block">
                Skills<em>*</em>
              </Label>
              
              <div
                className="flex items-center flex-wrap border border-gray-300 rounded px-2 py-1 focus-within:border-blue-500">
                {skills.map((skill, index) => (
                  <span
                    key={index}
                    className="flex items-center bg-gray-100 rounded-md px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-1">
                    {skill}
                    
                    <button disabled={loading} type="button" className="ml-1 text-gray-500"
                            onClick={() => removeSkill(skill)}>
                      <IoClose size={20}/>
                      </button>
                  </span>
                ))}
                
                <input
                  disabled={loading}
                  id="skills"
                  type="text"
                  className="flex-grow border-none focus:ring-0 outline-none"
                  placeholder="Enter skill and press Enter"
                  onKeyDown={handleKeyDownSkill}
                />
              </div>
              
              {errors.skills && (
                <span className="text-red-600">{errors.skills.message}</span>
              )}
            </div>
          </div>
          
          <div className="mb-4">
            <FormSelect
              className='w-1/2'
              label="Role"
              important={true}
              options={roleOptions.map((option) => ({value: option, label: option}))}
              register={register("role")}
              disabled={loading}
              onChangeSelect={handleRoleChange}
              error={errors.role?.message}
            />
          </div>
          
          {isExpertRole && (
            <div className={`mb-4`}>
              <h2 className={`font-bold text-base mb-4`}>Expert Details</h2>
              <div className="flex space-x-6 mb-4">
                <FormSelect
                  className='w-1/2'
                  label="Expert Group"
                  options={expertGroupOptions.map((option) => ({value: option, label: option}))}
                  register={register("group")}
                  error={errors.group?.message}
                  disabled={loading}
                />
                <FormSelect
                  className='w-1/2'
                  label="Expert Sub-Group"
                  options={expertSubGroupOptions.map((option) => ({value: option, label: option}))}
                  register={register("subgroup")}
                  error={errors.subgroup?.message}
                  disabled={loading}
                />
              </div>
              
              <div className="flex space-x-6">
                <div className="w-1/2">
                  <Label htmlFor="video" className="mb-2 block">
                    Video
                  </Label>
                  {id && !isChangeVideo ? (
                    <div className='flex items-center space-x-4'>
                      <video width="400" controls src={staffDetails?.video}/>
                      <Button outline color='blue' onClick={() => setIsChangeVideo(true)}>Change Video</Button>
                    </div>
                  ) : (
                    <FileInput {...register('video')} disabled={loading}/>
                  )}
                </div>
              </div>
            </div>
          )}
          
          <CustomLoaderButton
            size="lg"
            color="blue"
            type="submit"
            loading={loading}>
            {id ? 'Update' : 'Create'}
          </CustomLoaderButton>
        </form>
      </div>
    </div>
  );
};

export default StaffCreate;
