import React from 'react';
import { IoIosSearch } from 'react-icons/io';
import { TextInput } from 'flowbite-react';
import {SearchInputProps} from "../../../core/interface/SearchInputProps";

const SearchInputBox: React.FC<SearchInputProps> = (props: SearchInputProps) => {
  return (
    <div className="relative">
      <TextInput
        type="search"
        id="search-dropdown"
        placeholder={props.placeholderName}
        required
        className={`${props.className}`}
        value={props.searchQuery}
        onChange={props.onSearchChange}
      />
      <div className="absolute top-0 right-0 h-full w-10 flex items-center justify-center bg-blue-600 rounded-r-md">
        <IoIosSearch size={20} color="white" />
      </div>
    </div>
  );
};

export default SearchInputBox;
