import React, {useState} from "react";
import {useParams} from "react-router-dom";
import CustomLoaderButton from "../../../../../shared/components/custom-buttons/customLoaderButton";
import {CreateEntityPlanStepProps} from "../../../../../core/interface/entity/CreateEntityPlanStepProps";
import ProfileField from "../../../../../shared/components/profile-field/profileField";
import {useAddEntityPlanApi} from "../../services/useEntityPlanApi";

const CreateEntityPlanStep: React.FC<CreateEntityPlanStepProps> = (props: CreateEntityPlanStepProps) => {
  
  const {mutate: createEntityPlan} = useAddEntityPlanApi();
  const {entityId} = useParams<{ entityId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  
  const handleCreateEntityPlan = async () => {
    if (!props.selectedPlan) return;
    setLoading(true);
    const createPlanData = {
      entityId,
      planData: {plan_id: props.selectedPlan?.id},
    };
    
    createEntityPlan(createPlanData, {
      onSuccess: (response) => {
        const entityPlanId = response.data.id;
        if (entityPlanId) {
          props.onEntityPlanId(entityPlanId);
        }
        props.goToNextStep();
      },
      onSettled: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      }
    });
  };
  
  return (
    <div className="relative">
      <div className="grid grid-cols-2 gap-8 mb-8">
        <ProfileField label={`Entity name`} value={props.selectedPlan?.name}/>
        <ProfileField label={`Entity type`} value={props.selectedPlan?.type}/>
        <ProfileField label={`Applicable grades`} value={props.selectedPlan?.applicable_grades?.join(", ")}/>
          <ProfileField label={`Validity`}
                          value={`${props.selectedPlan?.validity_duration} ${props.selectedPlan?.validity_type}`}/>
      </div>
      
      <div className="flex space-x-2 justify-end items-center p-6 border-t">
        <CustomLoaderButton outline={true} size="md" color="blue" type="button" onClick={() => props.backToPrevStep()}>
          Change Plan
        </CustomLoaderButton>
        
        <CustomLoaderButton size="md" color="blue" type="button" loading={loading}
                                 onClick={handleCreateEntityPlan}>
          Next: Upload User
        </CustomLoaderButton>
      </div>
    </div>
  );
};

export default CreateEntityPlanStep;
