import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {Avatar, Button, FileInput, Label} from "flowbite-react";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {useCreateWebinarApi, useFetchWebinarDetailsApi, useUpdateWebinarApi,} from "../services/useWebinarApi";
import {useGetStaffsApi} from "../../users/staffs/services/useStaffApi";
import {StaffInterface} from "../../../core/interface/staff/StaffInterface";
import {Roles} from "../../../core/enums/roles";

import FormInput from "../../../shared/components/form-wrapper/FormInput";
import FormTextarea from "../../../shared/components/form-wrapper/FormTextarea";
import FormDateTimePicker from "../../../shared/components/form-wrapper/formDateTimePicker";
import FormSelect from "../../../shared/components/form-wrapper/FormSelect";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";

const durationOptions = [
  { label: "30 mins", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 120 },
];

const staffRequest = {
  selectedRolesOptions: [
    Roles.HOD_COUNSELLOR,
    Roles.IN_HOUSE_COUNSELLOR,
    Roles.FREELANCE_COUNSELLOR,
    Roles.INDUSTRY_EXPERT,
    Roles.STUDY_ABROAD_EXPERT,
    Roles.ENTRANCE_PATHWAY_EXPERT,
  ],
};

const validationSchema = yup.object({
  webinar_image: yup.mixed(),
  name: yup.string().required("Webinar title is required"),
  info: yup.string().required("Webinar description is required"),
  datetime: yup.string().required("Date and time is required"),
  host_name: yup.string(),
  host_id: yup.string().required("Host is required"),
  duration_in_mins: yup.string().required("Duration is required"),
});

const CreateWebinar: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [isChangeImage, setIsChangeImage] = useState(false);
  
  const { mutate: createWebinar } = useCreateWebinarApi();
  const { mutate: updateWebinar } = useUpdateWebinarApi();
  const { data: webinarDetails } = useFetchWebinarDetailsApi(id);
  const { data: staffListData } = useGetStaffsApi(staffRequest);
  
  
  const staffList: StaffInterface[] = staffListData?.data.items || [];
  const hostNameOptions = staffList.map((staff) => ({
    id: staff.id,
    value: `${staff.first_name} ${staff.last_name}`,
    label: `${staff.first_name} ${staff.last_name}`,
  }));
  
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  
  useEffect(() => {
    if (id && webinarDetails) {
      Object.entries(webinarDetails.data).forEach(([key, value]) => {
        setValue(key as any, value);
      });
    }
  }, [id, setValue, webinarDetails]);
  
  const onSubmit = (data: any) => {
    setLoading(true);
    const { webinar_image, ...json_data } = data;
    
    const webinarFormData = {
      json_data,
      image: webinar_image?.[0],
    };
    
    const callbackConfig = {
      onSuccess: () => navigate("/webinars"),
      onSettled: () => setLoading(false),
      onError: () => setLoading(false),
    };
    
    id
      ? updateWebinar({ webinarId: id, data: webinarFormData }, callbackConfig)
      : createWebinar(webinarFormData, callbackConfig);
  };
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/webinars" label="Back" />
        <h1 className="text-xl font-bold mb-4">{id ? "Update Webinar" : "Create Webinar"}</h1>
        <CustomBreadcrumb
          items={[
            { label: "Webinars", to: "/webinars" },
            { label: `${id ? "Update Webinar" : "Create Webinar"}` },
          ]}
        />
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex space-x-6 mb-4">
            <div className="w-1/2">
              <Label htmlFor="upload_profile_image" className="mb-2 block">
                Upload Profile Image
              </Label>
              {id && !isChangeImage ? (
                <div className="flex items-center space-x-4">
                  <Avatar size="lg" img={webinarDetails?.data.image_url} alt={webinarDetails?.data.name} rounded />
                  <Button outline color="blue" onClick={() => setIsChangeImage(true)}>
                    Change Image
                  </Button>
                </div>
              ) : (
                <FileInput {...register("webinar_image")} disabled={loading} />
              )}
            </div>
            <FormInput
              className="w-1/2"
              label="Webinar Title"
              important
              register={register("name")}
              error={errors.name?.message}
              placeholder="Enter Webinar Title"
              disabled={loading}
            />
          </div>
          <FormTextarea
            className="w-full mb-4"
            label="Description"
            important
            register={register("info")}
            error={errors.info?.message}
            placeholder="Write text here..."
            disabled={loading}
          />
          <div className="flex space-x-4 mb-4">
            <FormDateTimePicker
              className={`flex flex-col w-1/2`}
              name="datetime"
              important
              label="Date and Time"
              register={{
                value: watch("datetime") ? new Date(watch("datetime")) : null,
                onChange: (value: Date | null) =>
                  setValue("datetime", value ? value.toISOString() : "", { shouldValidate: true }),
              }}
              minDate={new Date()}
              placeholder="Select date and time"
              disabled={loading}
              error={errors.datetime?.message}
            />
            <FormSelect
              className="w-1/2"
              label="Host Name"
              important
              options={hostNameOptions}
              register={register("host_name")}
              error={errors.host_name?.message}
              onChangeSelect={(e) => {
                const selectedHostId: any = e.target.options[e.target.selectedIndex]?.getAttribute("id");
                setValue("host_id", selectedHostId, { shouldValidate: true });
              }}
              disabled={loading}
            />
          </div>
          <FormSelect
            className="w-1/2 mb-10"
            label="Duration"
            important
            options={durationOptions}
            register={register("duration_in_mins")}
            error={errors.duration_in_mins?.message}
            disabled={loading}
          />
          <CustomLoaderButton size="lg" color="blue" type="submit" loading={loading}>
            {id ? "Save" : "Create"}
          </CustomLoaderButton>
        </form>
      </div>
    </div>
  );
};

export default CreateWebinar;
