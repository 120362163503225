import React, {useMemo, useState} from "react";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {useLocation, useParams} from "react-router-dom";
import {useGetCustomerPlansApi} from "../services/useCustomerApi";
import {CustomerPlanInterface, CustomerPlanRequest} from "../../../../core/interface/customer/CustomerPlanInterface";
import {EntityPlanStatus} from "../../../../core/enums/entityPlanStatus";
import {Pagination, Spinner, Table} from "flowbite-react";
import DropdownCheckboxFilter from "../../../../shared/components/custom-dropdown-box/dropdownCheckboxFilter";
import moment from "moment/moment";
import StatusBadge from "../../../../shared/components/status-badge/statusBadge";


const CustomerPlans: React.FC = () => {
  
  const pageSize = 10;
  const location = useLocation();
  const customerStatus = useMemo(() => Object.values(EntityPlanStatus), []);
  
  const {customerName} = location.state || {};
  const {id} = useParams<{ id: string }>();
  
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedPlanStatus, setSelectedPlanStatus] = useState<string[]>([]);
  
  const customerPlanRequest: CustomerPlanRequest = {
    customerId: id,
    currentPage,
    pageSize,
    selectedPlanStatus
  }
  const {data, isLoading} = useGetCustomerPlansApi(customerPlanRequest);
  
  const customerPlans: CustomerPlanInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <div className="mb-4">
            <BackButton to="/customers" label="Back"/>
          </div>
          <h1 className="text-xl font-bold mb-4">Customer Plan</h1>
          <CustomBreadcrumb items={[
            {label: 'Customers', to: '/customers'},
            {label: customerName},
            {label: 'Customer Plans'},
          ]}
          />
        </div>
        <div className='flex gap-4'>
          <DropdownCheckboxFilter
            title='Entity Plan Status'
            className='w-56'
            options={customerStatus.map((option) => ({value: option, label: option.replace(/-/g, ' ')}))}
            selectedOptions={selectedPlanStatus}
            onChange={setSelectedPlanStatus}
          />
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className='border-b border-gray-200'>
            <Table.HeadCell>PLAN NAME</Table.HeadCell>
            <Table.HeadCell>PLAN TYPE</Table.HeadCell>
            <Table.HeadCell>START DATE</Table.HeadCell>
            <Table.HeadCell>EXPIRY DATE</Table.HeadCell>
            <Table.HeadCell>STATUS</Table.HeadCell>
          </Table.Head>
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : totalCount === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <div className="tet-center text-gray-500">No customer records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {customerPlans?.map((customerPlan) => (
                <Table.Row key={customerPlan.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>{customerPlan?.plan_name}</Table.Cell>
                  <Table.Cell>{customerPlan?.plan_type}</Table.Cell>
                  <Table.Cell>
                    {customerPlan?.start_date ? moment(customerPlan.start_date).format("YYYY-MM-DD") : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {customerPlan?.expired_date ? moment(customerPlan.expired_date).format("YYYY-MM-DD") : ""}
                  </Table.Cell>
                  <Table.Cell>
                    <div className='flex'>
                      {StatusBadge(customerPlan?.status)}
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        
        </Table>
        
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerPlans;
