import React from "react";
import { FormFieldProps } from "../../../core/interface/FormFieldProps";
import { Label, TextInput } from "flowbite-react";

const FormInput: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  return (
    <div className={props.className}>
      <Label htmlFor={props.name} className="mb-2 block">
        {props.label}
        {props.subLabel && <span className="pl-1 text-blue-600">{props.subLabel}</span>}
        {props.important && <em>*</em>}
      </Label>
      <TextInput
        autoComplete="off"
        disabled={props.disabled}
        addon={props.addon}
        {...props.register}
        type={props.type}
        placeholder={props.placeholder}
        defaultValue={props.value}
        onChange={props.onChangeInput}
      />
      {props.error && <span className="text-red-600">{props.error}</span>}
    </div>
  );
};

export default FormInput;
