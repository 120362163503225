import React from 'react';
import DashboardCard from "./dashboard-card/dashboardCard";
import { HiOutlineUserGroup } from "react-icons/hi";

const Dashboard: React.FC = () => {
  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2`}>
      <DashboardCard
        icon={<HiOutlineUserGroup size={24} />}
        description={`100 Schools`}
        mainText={`15,551 Students`}
      />
      <DashboardCard
        icon={<HiOutlineUserGroup size={24} />}
        description={`50 Colleges`}
        mainText={`2100 Students`}
      />
      <DashboardCard
        icon={<HiOutlineUserGroup size={24} />}
        description={`50 Schools`}
        mainText={`7,950 Students`}
      />
    </div>
  );
};

export default Dashboard;
