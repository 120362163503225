import React, {useMemo, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {useFetchEntityPlanDetailsApi} from "../services/useEntityPlanApi";
import {Spinner} from "flowbite-react";
import FormInput from "../../../../shared/components/form-wrapper/FormInput";
import {EntityPlanInterface, Meeting} from "../../../../core/interface/entity/EntityPlanInterface";
import {CreateMeetingDialog} from "./create-meeting-dialog/createMeetingDialog";

const UpdateEntityPlanStageDetails: React.FC = () => {
  
  const {entityName} = useLocation().state || {};
  const {entityId, entityPlanId} = useParams<{ entityId: string; entityPlanId: string }>();
  const {data, isLoading} = useFetchEntityPlanDetailsApi(entityId, entityPlanId);
  const entityPlanDetails: EntityPlanInterface | undefined = data?.data;
  
  const [isOpenMeetingDialog, setIsOpenMeetingDialog] = useState(false);
  
  const breadcrumbItems = useMemo(() => [
    {label: "Entities", to: "/entities"},
    {label: entityName},
    {
      label: "Entity Plans",
      to: `/entities/${entityId}/entity-plans`,
      stateName: "entityName",
      stateValue: entityName,
    },
    {
      label: 'Entity Plan Details',
      to: `/entities/${entityId}/entity-plans/entity-plan-details/${entityPlanId}`,
      stateName: "entityName",
      stateValue: entityName,
    },
    {label: "Update Stage Details"},
  ], [entityName, entityId, entityPlanId]);
  
  const handleSaveStage = (meetingList: Meeting[]) => {
    console.log('Meeting List', meetingList);
  }
  
  return (
    <div>
      <div className="mb-4">
        <BackButton
          label="Back"
          to={`/entities/${entityId}/entity-plans/entity-plan-details/${entityPlanId}`}
          stateName="entityName"
          stateValue={entityName}
        />
        <h1 className="text-xl font-bold mb-4">Update Stage Details</h1>
        <CustomBreadcrumb items={breadcrumbItems}/>
      </div>
      <div className="flex flex-col overflow-x-auto relative shadow-md sm:rounded-lg px-10 py-4">
        {isLoading ? (
          <div className="flex justify-center items-center py-5">
            <Spinner size="lg"/>
          </div>
        ) : (
          entityPlanDetails?.entity_plan_stages?.map((stage, index) => (
            <div key={index}>
              <h1 className="text-base font-bold border-b pb-2 mb-5">Stage Details</h1>
              <div className="flex space-x-6 mb-4">
                <FormInput
                  value={stage?.stage_name || ""}
                  disabled={true}
                  className="w-1/2"
                  label="Stage name"
                  type="text"
                  placeholder="Enter stage name"
                />
                <FormInput
                  value={stage?.stage_type || ""}
                  disabled={true}
                  className="w-1/2"
                  label="Stage type"
                  type="text"
                  placeholder="Enter stage type"
                />
              </div>
            </div>
          ))
        )}
      </div>
      <CreateMeetingDialog
        isOpen={isOpenMeetingDialog}
        onClose={() => setIsOpenMeetingDialog(false)}
        onSave={handleSaveStage}
      />
    </div>
  );
};

export default UpdateEntityPlanStageDetails;
