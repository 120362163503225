import React from "react";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import {useFetchPlanDetailsApi} from "../services/usePlanApi";
import {useParams} from "react-router-dom";
import {PlanInterface} from "../../../core/interface/plan/PlanInterface";
import ProfileField from "../../../shared/components/profile-field/profileField";
import DetailsStageCard from "./detailsStageCard/detailsStageCard";
import {Spinner} from "flowbite-react";


const PlanDetails: React.FC = () => {
  
  const { id } = useParams<{ id: string }>();
  const {data, isLoading} = useFetchPlanDetailsApi(id);
  
  const planDetails: PlanInterface | undefined = data?.data;
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/plans" label="Back"/>
        <h1 className="text-xl font-bold mb-4">View Plan</h1>
        <CustomBreadcrumb
          items={[
            {label: "Plans", to: "/plans"},
            {label: "View Plan"},
          ]}
        />
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <h1 className={`text-base font-bold border-b pb-2 mb-5`}>Basic Plan</h1>
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
            <ProfileField label={`Plan name`} value={planDetails?.name}/>
            <ProfileField label={`Business vertical`} value={planDetails?.business_vertical}/>
            <ProfileField label={`Validity type`} value={planDetails?.validity_type}/>
            <ProfileField label={`Validity`} value={planDetails?.validity_duration}/>
            <ProfileField label={`Applicable grades`} value={planDetails?.applicable_grades}/>
            <ProfileField label={`Price`} value={`Rs. ${planDetails?.price}`}/>
          </div>
          <div className="grid grid-cols-3 gap-8">
            {planDetails?.stages?.map((stage) => (
              <DetailsStageCard key={stage.id} stage={stage}/>
            ))}
          </div>
        </div>
      )}
      
    </div>
  )
}

export default PlanDetails;
