export enum Roles {
  SUPER_ADMIN = "SUPER-ADMIN",
  STAFF_ADMIN = "STAFF-ADMIN",
  FINANCE_ADMIN = "FINANCE-ADMIN",
  BD_ADMIN = "BD-ADMIN",
  MARKETING_ADMIN = "MARKETING-ADMIN",
  HOD_COUNSELLOR = "HOD-COUNSELLOR",
  IN_HOUSE_COUNSELLOR = "IN-HOUSE-COUNSELLOR",
  FREELANCE_COUNSELLOR = "FREELANCE-COUNSELLOR",
  INDUSTRY_EXPERT = "INDUSTRY-EXPERT",
  STUDY_ABROAD_EXPERT = "STUDY-ABROAD-EXPERT",
  ENTRANCE_PATHWAY_EXPERT = "ENTRANCE-PATHWAY-EXPERT",
  CPCC_TRAINER = "CPCC-TRAINER"
}

export enum RoleGroup {
  STAFF = "STAFF",
  CUSTOMER = "CUSTOMER"
}

export enum RoleType {
  INTERNAL_ADMIN = "INTERNAL-ADMIN",
  EXPERT = "EXPERT",
  COUNSELLOR = "COUNSELLOR",
  TRAINER = "TRAINER",
  COUNSELEE = "COUNSELEE",
  ENTITY_ADMIN = "ENTITY-ADMIN"
}
