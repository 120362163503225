  import React from 'react';
  import BackButton from "../../../../shared/components/custom-buttons/backButton";
  import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
  import {useParams} from "react-router-dom";
  import {StaffInterface} from "../../../../core/interface/staff/StaffInterface";
  import {useFetchStaffDetailsApi} from "../services/useStaffApi";
  import {Avatar} from "flowbite-react";
  import ProfileField from "../../../../shared/components/profile-field/profileField";
  
  const StaffDetails: React.FC = () => {
    
    const {id} = useParams<{ id: string }>();
    const {data, isLoading} = useFetchStaffDetailsApi(id);
    
    const staffDetails: StaffInterface | undefined = data?.data;
    
    if (isLoading) {
      return <div>Loading...</div>;
    }
    
    return (
      <div>
        <div className="mb-4">
          <BackButton to="/staffs" label="Back"/>
          <h1 className="text-xl font-bold mb-4">View Staff</h1>
          <CustomBreadcrumb
            items={[
              {label: "Staffs", to: "/staffs"},
              {label: "View Staff"}
            ]}
          />
        </div>
        <div className="border border-gray-300 rounded-md shadow-md p-6 pb-20 bg-white">
          <div className="flex justify-start space-x-10">
            <div>
              <Avatar size='xl' img={staffDetails?.profile_image} alt={`${staffDetails?.first_name} ${staffDetails?.last_name}`} rounded />
            </div>
            <div className='w-full'>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4 mb-5">
                <ProfileField label={`First Name`} value={staffDetails?.first_name}/>
                <ProfileField label={`Last Name`} value={staffDetails?.last_name}/>
                <ProfileField label={`Designation`} value={staffDetails?.designation}/>
                <ProfileField label={`Department`} value={staffDetails?.department}/>
                <ProfileField label={`Date of joining`} value={staffDetails?.date_of_joining}/>
                <ProfileField label={`Date of resignation`} value={staffDetails?.date_of_resignation}/>
                <ProfileField label={`Gender`} value={staffDetails?.gender}/>
                <ProfileField label={`DOB`} value={staffDetails?.date_of_birth}/>
                <ProfileField label={`Email`} value={staffDetails?.email}/>
                <ProfileField label={`Phone`} value={staffDetails?.phone_number}/>
                <ProfileField label={`City`} value={staffDetails?.city}/>
                <ProfileField label={`State`} value={staffDetails?.state}/>
                <ProfileField label={`Address`} value={staffDetails?.address ? staffDetails?.address : 'N/A'}/>
                <ProfileField label={`Qualifications`} value={staffDetails?.qualifications}/>
                <ProfileField label={`Skills`} value={staffDetails?.skills}/>
                <ProfileField label={`Role`} value={staffDetails?.role}/>
              </div>
              <div className="w-full grid grid-cols-1 gap-8 mb-5">
                <ProfileField label={`Bio data`} value={staffDetails?.bio_data ? staffDetails?.bio_data : 'N/A'}/>
              </div>
              {staffDetails?.group && staffDetails?.subgroup && (
                <div>
                  <h2 className={`text-base font-bold border-b pb-2 mb-5`}>Expert Details</h2>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-8 mb-5">
                    <ProfileField label={`Expert group`} value={staffDetails?.group}/>
                    <ProfileField label={`Expert subgroup`} value={staffDetails?.subgroup}/>
                    <div>
                      <h3 className="text-xs font-medium text-blue-700 mb-2">Video</h3>
                      <video className="w-full" controls src={staffDetails?.video}>
                        <source src={staffDetails?.video} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      
      </div>
    );
  };
  
  export default StaffDetails;
