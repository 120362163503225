import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Avatar, Button, Checkbox, FileInput, Label} from "flowbite-react";
import FormInput from "../../../shared/components/form-wrapper/FormInput";
import FormTextarea from "../../../shared/components/form-wrapper/FormTextarea";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";
import {useNavigate, useParams} from "react-router-dom";
import {useCreateBlogApi, useFetchBlogDetailsApi, useUpdateBlogApi} from "../services/useBlogsApi";
import FormSelect from "../../../shared/components/form-wrapper/FormSelect";

  const durationOptions = [
    { label: "1 mins", value: 1 },
    { label: "2 mins", value: 2 },
    { label: "3 mins", value: 3 },
  ];

const validationSchema = (isChangeThumbnailImage: boolean) => yup.object({
  blog_thumbnail_image: isChangeThumbnailImage ?
    yup.mixed() :
    yup.mixed()
      .required("Thumbnail image is required")
      .test("fileType", "Only image files are allowed", (value) =>
        value instanceof FileList && value.length > 0 && value[0].type.startsWith("image/")
      ),
  
  title: yup
    .string()
    .required("Blog title is required")
    .trim()
    .min(1, "Blog title must be at least 1 character"),
  
  desc: yup
    .string()
    .required("Blog description is required")
    .trim()
    .min(1, "Blog description must be at least 1 character"),
  reading_time_in_mins: yup
    .string()
    .required('Reading time is required'),
  is_popular: yup
    .boolean()
});

const CreateBlog: React.FC = () => {
  
  const [loading, setLoading] = useState(false);
  const [isChangeThumbnailImage, setIsChangeThumbnailImage] = useState(false);
  
  const navigate = useNavigate();
  const {id} = useParams<{ id: string }>();
  const {mutate: createBlog} = useCreateBlogApi();
  const {mutate: updateBlog} = useUpdateBlogApi();
  const {data: blogDetails} = useFetchBlogDetailsApi(id);
  
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(validationSchema(isChangeThumbnailImage)),
  });
  
  useEffect(() => {
    if (id && blogDetails) {
      setValue('title', blogDetails.data.title);
      setValue('desc', blogDetails.data.desc);
      setValue('is_popular', blogDetails.data.is_popular);
      setValue('reading_time_in_mins', blogDetails.data.reading_time_in_mins);
      setIsChangeThumbnailImage(true);
    }
  }, [id, setValue, blogDetails]);
  
  const onSubmit = (data: any) => {
    setLoading(true);
    const {blog_thumbnail_image, ...json_data} = data;
    const blogFormData = {
      thumbnail_image: blog_thumbnail_image?.[0],
      json_data,
    }
    
    const callbackConfig = {
      onSuccess: () => navigate("/blogs"),
      onSettled: () => setLoading(false),
      onError: () => setLoading(false),
    };
    
    id
      ? updateBlog({blogId: id, data: blogFormData}, callbackConfig)
      : createBlog(blogFormData, callbackConfig);
  };
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/blogs" label="Back"/>
        <h1 className="text-xl font-bold mb-4">{id ? "Update Blog" : "Create Blog"}</h1>
        <CustomBreadcrumb
          items={[
            {label: "Blogs", to: "/blogs"},
            {label: `${id ? "Update Blog" : "Create Blog"}`},
          ]}
        />
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex space-x-6 mb-4">
            <div className="w-1/2">
              <Label htmlFor="upload_thumbnail_image" className="mb-2 block">
                Upload Thumbnail Image
              </Label>
              {id && isChangeThumbnailImage ? (
                <div className="flex items-center space-x-4">
                  <Avatar size="lg" img={blogDetails?.data.thumbnail_image_url} alt={blogDetails?.data.title} rounded/>
                  <Button disabled={loading} outline color="blue" onClick={() => setIsChangeThumbnailImage(false)}>
                    Change Thumbnail Image
                  </Button>
                </div>
              ) : (
                <>
                  <FileInput
                    {...register("blog_thumbnail_image")}
                    id="upload_thumbnail_image"
                    accept="image/*"
                    disabled={loading}
                  />
                  {errors?.blog_thumbnail_image && (
                    <span className="text-red-600">{errors.blog_thumbnail_image.message as string}</span>
                  )}
                </>
              )}
            </div>
            <FormInput
              className="w-1/2 mb-4"
              label="Blog Title"
              important
              register={register("title")}
              error={errors.title?.message}
              placeholder="Enter blog Title"
              disabled={loading}
            />
          </div>
          <FormTextarea
            className="w-full mb-4"
            subClassName="h-32"
            label="Blog Description"
            important
            register={register("desc")}
            error={errors.desc?.message}
            placeholder="Enter Description"
            disabled={loading}
          />
          <FormSelect
            className="w-1/2 mb-4"
            label="Reading Time"
            important
            options={durationOptions}
            register={register("reading_time_in_mins")}
            error={errors.reading_time_in_mins?.message}
            disabled={loading}
          />
          <div className="flex items-center space-x-3 mb-4">
            <Checkbox
              {...register("is_popular")}
              id="isPopular"
            />
            <div>
              <Label htmlFor="isPopular" className="font-medium text-gray-900">
                Set as a popular
              </Label>
            </div>
          </div>
          <CustomLoaderButton size="lg" color="blue" type="submit" loading={loading}>
            {id ? "Save" : "Create"}
          </CustomLoaderButton>
        </form>
      </div>
    </div>
  )
    ;
};

export default CreateBlog;
