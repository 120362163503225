import {useToast} from "../../../../shared/components/notification-messages/toastNotification";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ToastType} from "../../../../core/enums/toastType";
import {createEntityAdminApi, disableEntityAdminApi} from "./entityAdminApi";
import {DisableRequest} from "../../../../core/interface/DisableRequest";
import {AddEntityAdminRequest} from "../../../../core/interface/entity-admin/AddEntityAdminProps";

export const useCreateEntityAdminApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (data: AddEntityAdminRequest) => createEntityAdminApi(data),
    
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['entities']});
      const message = response?.message || 'Entity admin created successfully!';
      showToastMessage({type: ToastType.Success, message});
      return response;
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['entities']});
    }
  });
};

export const useDisableEntityAdminApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: ({data}: {data: DisableRequest}) => disableEntityAdminApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['entity'] });
      const message = response?.message || 'Entity admin deleted successfully!';
      showToastMessage({ type: ToastType.Success, message });
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['entity']});
    }
  })
}
