export enum Grades {
  EIGHT = "08TH",
  NINE = "09TH",
  TENTH = "10TH",
  ELEVEN = "11TH",
  TWELVE = "12TH",
  COLLEGE_GRADS = "COLLEGE-GRADS",
  WORKING_PROFESSIONAL = "WORKING-PROFESSIONAL",
  CPCC = "CPCC"
}
