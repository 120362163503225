import React from 'react';
import {DetailsStageCardProps} from "../../../../core/interface/plan/PlanInterface";

const DetailsStageCard: React.FC<DetailsStageCardProps> = (props: DetailsStageCardProps) => {
  return (
    <>
      <div className='flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col px-5'>
        <div className='flex h-full flex-col justify-start gap-4 p-6'>
          <div>
            <h2 className={`text-center text-lg font-semibold mb-0`}>{`Stage ${props.stage?.order_number}`}</h2>
            <h3 className="text-center text-lg font-semibold text-blue-700">{props.stage?.name}</h3>
          </div>
          
          <ul className="list-disc text-base text-gray-900">
            {props.stage?.type && <li>Stage type: {props.stage.type}</li>}
            {props.stage?.session?.name && <li>Session name: {props.stage.session.name}</li>}
            {props.stage?.session?.mode && <li>Session mode: {props.stage.session.mode}</li>}
            {props.stage?.session?.audience && <li>Session audience: {props.stage.session.audience}</li>}
            {(props.stage?.session?.duration !== undefined && props.stage.session?.duration !== null) && (
              <li>Session duration (minutes): {props.stage.session.duration} Min.</li>
            )}
            {props.stage?.session?.host_user_type &&
                <li>Session allowed host roles: {props.stage.session.host_user_type}</li>}
          </ul>
        </div>
      </div>
    </>
  );
};

export default DetailsStageCard;
