import React from 'react';
import {FormFieldProps} from "../../../core/interface/FormFieldProps";
import {Label, Select} from "flowbite-react";

const FormSelect: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  return (
    <div className={props.className}>
      <Label htmlFor={props.name} className="mb-2 block">
        {props.label}{props.important && (<em>*</em>)}
      </Label>
      <Select {...props.register} onChange={props.onChangeSelect} disabled={props.disabled}>
        <option value="">Select</option>
        {props.options?.map(option => (
          <option key={option.value} value={option.value} id={option.id}>
            {option.label}
          </option>
        ))}
      </Select>
      {props.error && <span className="text-red-600">{props.error}</span>}
    </div>
  );
};

export default FormSelect;
