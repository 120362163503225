import axios from 'axios';
import authService from "../../features/auth/api/authService";

const navigateToSignIn = () => {
  window.location.href = '/signin';
};

// Add a request interceptor to include the token in the headers
axios.interceptors.request.use(
  async (config) => {
    try {
      // Retrieve the current session
      const session = await authService.currentSessionUser();
      const idToken = session?.tokens?.idToken;
      const accessToken = session?.tokens?.accessToken;
      
      // Add the Authorization header if tokens are available
      if (idToken && accessToken) {
        config.headers['Authorization'] = `${idToken}`;
      }
    } catch (error) {
      await authService.signOut();
      navigateToSignIn();
    }
    
    return config;
  },
  (error) => {
    // Handle any request-level errors and navigate to sign-in
    navigateToSignIn();
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle global responses
axios.interceptors.response.use(
  (response) => {
    // Return successful response
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      // Handle 401 errors by signing out and navigating to sign-in
      await authService.signOut();
      navigateToSignIn();
    }
    // Return other errors as a rejected promise
    return Promise.reject(error);
  }
);
