import React, {useMemo, useState} from "react";
import {Button, Pagination, Spinner, Table, Tooltip} from "flowbite-react";
import {Link} from "react-router-dom";
import {IoEyeOutline} from "react-icons/io5";
import {FiEdit} from "react-icons/fi";
import {useGetEntitiesApi} from "../services/useEntityApi";
import {EntityType} from "../../../../core/enums/entityType";
import DropdownCheckboxFilter from "../../../../shared/components/custom-dropdown-box/dropdownCheckboxFilter";
import SearchInputBox from "../../../../shared/components/custom-search-box/searchInputBox";
import {EntityInterface} from "../../../../core/interface/entity/EntityInterface";
import {BusinessModel} from "../../../../core/enums/businessModel";

const Entities: React.FC = () => {
  
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedEntityType, setSelectedEntityType] = useState<string[]>([]);
  const [selectedBusinessModels, setSelectedBusinessModels] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  
  const filteredSearchQuery = searchQuery.length > 2 ? searchQuery : '';
  const {
    data,
    isLoading
  } = useGetEntitiesApi(currentPage, pageSize, filteredSearchQuery, selectedBusinessModels, selectedEntityType);
  const entityList: EntityInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const businessModelsOptions = useMemo(() => Object.values(BusinessModel), []);
  const entityTypeOptions = useMemo(() => Object.values(EntityType), []);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-semibold">Entities</h1>
        <div className="flex gap-4">
          <DropdownCheckboxFilter
            title='Business Model'
            className='w-56'
            options={businessModelsOptions.map((option) => ({value: option, label: option.replace(/-/g, ' ')}))}
            selectedOptions={selectedBusinessModels}
            onChange={setSelectedBusinessModels}
          />
          <DropdownCheckboxFilter
            title='Entity type'
            className='w-48'
            options={entityTypeOptions.map((option) => ({value: option, label: option.replace(/-/g, ' ')}))}
            selectedOptions={selectedEntityType}
            onChange={setSelectedEntityType}
          />
          <SearchInputBox
            className='w-80'
            placeholderName='Search entity name...'
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
          />
          <Link to="create-entity">
            <Button color="blue">
              Create Entity
            </Button>
          </Link>
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className='border-b border-gray-200'>
            <Table.HeadCell>Entity Name</Table.HeadCell>
            <Table.HeadCell>Entity Type</Table.HeadCell>
            <Table.HeadCell>Total Plans</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={4}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : entityList?.length === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={4}>
                  <div className="flex justify-center items-center py-5">
                    <div className="tet-center text-gray-500">No entity records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {entityList?.map((entity) => (
                <Table.Row key={entity.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>
                    <div className="text-sm font-semibold">{entity.name}</div>
                    <div className="text-sm text-gray-500">{entity.business_model}</div>
                  </Table.Cell>
                  <Table.Cell>{entity.type}</Table.Cell>
                  <Table.Cell className='text-blue-600 underline'>
                    {entity.plans_count > 0 ?
                      (
                        <Link
                          to={`${entity.id}/entity-plans`}
                          state={{entityName: entity.name}}>
                          {entity.plans_count}
                        </Link>
                      ) :
                      (
                        <Link
                          to={`${entity.id}/add-entity-plan`}
                          state={{entityName: entity.name}}>
                          Add Plan
                        </Link>
                      )}
                  </Table.Cell>
                  <Table.Cell className="flex items-center space-x-2">
                    <Tooltip content="View" placement="bottom">
                      <Link to={`view-entity/${entity.id}`}>
                        <Button color="none" size="xs">
                          <IoEyeOutline size={`20`}/>
                        </Button>
                      </Link>
                    </Tooltip>
                    <Tooltip content="Edit" placement="bottom">
                      <Link to={`update-entity/${entity.id}`}>
                        <Button color="none" size="xs">
                          <FiEdit size="20"/>
                        </Button>
                      </Link>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        
        </Table>
        
        {entityList?.length !== 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Entities;

