import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Label, TextInput} from 'flowbite-react';
import {IoClose} from 'react-icons/io5';
import {BusinessVertical} from "../../../core/enums/businessVertical";
import {ValidityType} from "../../../core/enums/validityType";
import {Grades} from "../../../core/enums/grades";
import {CreatePlanFormProps} from "../../../core/interface/CreatePlanFormProps";
import {PlanType} from "../../../core/enums/planType";
import FormInput from "../../../shared/components/form-wrapper/FormInput";
import FormSelect from "../../../shared/components/form-wrapper/FormSelect";

export const CreatePlanForm = (props: CreatePlanFormProps) => {
  const [selectedGrades, setSelectedGrades] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  
  const planTypeOptions = useMemo(() => Object.values(PlanType), []);
  const businessVerticalOptions = useMemo(() => Object.values(BusinessVertical), []);
  const validityTypeOptions = useMemo(() => Object.values(ValidityType), []);
  // Handle grade selection
  const handleGradeToggle = (grade: string) => {
    setSelectedGrades(prevGrades =>
      prevGrades.includes(grade)
        ? prevGrades.filter(g => g !== grade)
        : [...prevGrades, grade]
    );
  };
  
  // Remove a grade from the selectedGrades array
  const handleRemoveGrade = (grade: string) => {
    setSelectedGrades(prevGrades => prevGrades.filter(g => g !== grade));
  };
  
  // Update form state when selectedGrades changes
  useEffect(() => {
    props.setValue('applicable_grades', selectedGrades);
  }, [selectedGrades, props.setValue, props]);
  
  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <>
      <div className="flex space-x-6 mb-4">
        <FormInput
          className='w-1/2'
          type='text'
          label="Plan Name"
          important={true}
          register={props.register("name")}
          error={props.errors.name?.message}
          placeholder="Enter plan name"
        />
        
        <FormSelect
          className='w-1/2'
          label="Plan Type"
          important={true}
          options={planTypeOptions.map((option) => ({value: option, label: option}))}
          register={props.register("type")}
          error={props.errors.type?.message}
        />
      </div>
      
      <div className="flex space-x-6 mb-4">
        <FormSelect
          className='w-1/2'
          label="Business Vertical"
          important={true}
          options={businessVerticalOptions.map((option) => ({value: option, label: option}))}
          register={props.register("business_vertical")}
          error={props.errors.business_vertical?.message}
        />
        
        <FormSelect
          className='w-1/2'
          label="Validity Type"
          important={true}
          options={validityTypeOptions.map((option) => ({value: option, label: option}))}
          register={props.register("validity_type")}
          error={props.errors.validity_type?.message}
        />
      </div>
      
      <div className="flex space-x-6 mb-4">
        <FormInput
          className='w-1/2'
          type='number'
          label="Validity Duration"
          important={true}
          register={props.register("validity_duration")}
          error={props.errors.validity_duration?.message}
          placeholder="Validity duration (Days, Weeks, Months)"
        />
        
        <FormInput
          className='w-1/2'
          type='number'
          label="Price"
          addon="Rs"
          important={true}
          register={props.register("price")}
          error={props.errors.price?.message}
          placeholder="999"
        />
      </div>
      
      <div className="flex space-x-6 mb-4">
        <div className="w-full relative" ref={dropdownRef}>
          <Label htmlFor="grades" className="mb-2 block">
            Applicable Grades
          </Label>
          <div className="flex items-center flex-wrap border border-gray-300 rounded px-2 py-2 focus-within:border-blue-500"
               onClick={() => setShowDropdown(prev => !prev)}>
            <div className="flex flex-wrap">
            {selectedGrades.length === 0 ? (
                <span className={`text-gray-500`}>Select grades...</span>
              ) : (
                selectedGrades.map(grade => (
                  <div key={grade} className="flex items-center bg-gray-200 p-2 rounded mb-2 mr-2">
                    <span className="mr-2">{grade}</span>
                    <button type="button" onClick={() => handleRemoveGrade(grade)}>
                      <IoClose className="text-gray-500"/>
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>
          
          {showDropdown && (
            <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg">
              {Object.values(Grades).map((grade) => (
                <div
                  key={grade}
                  className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleGradeToggle(grade)}
                >
                  <TextInput
                    type="checkbox"
                    checked={selectedGrades.includes(grade)}
                    readOnly
                    className="mr-2"
                  />
                  {grade}
                </div>
              ))}
            </div>
          )}
          
          {props.errors.applicable_grades && (
            <p className="text-red-500 text-sm">{props.errors.applicable_grades.message}</p>
          )}
        </div>
      </div>
    </>
  );
};

