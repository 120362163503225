export const formatCurrency = (amount: number, currency: string = 'INR') => {
  const options = {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  };
  
  const formatter = new Intl.NumberFormat('en-IN', options);
  return currency === 'INR' ? formatter.format(amount).replace('₹', 'Rs.') : formatter.format(amount);
};
