import { confirmSignIn, fetchAuthSession, resendSignUpCode, signIn, signOut } from "aws-amplify/auth";

class AuthService {
  private userRoleCache: string | null = null;
  
  // Sign in method
  async signIn(username: string, password: string) {
    try {
      return await signIn({ username, password, options: { authFlowType: 'CUSTOM_WITH_SRP' } });
    } catch (error) {
      throw error;
    }
  }
  
  async signInVerifyOTP(confirmationCode: string) {
    try {
      return await confirmSignIn({
        challengeResponse: confirmationCode,
      });
    } catch (error) {
      throw error;
    }
  }
  
  // Confirm sign in new password
  async confirmSignInNewPassword(newPassword: string) {
    try {
      return await confirmSignIn({
        challengeResponse: newPassword,
        options: {
          ChallengeName: "NEW_PASSWORD_REQUIRED",
        },
      });
    } catch (error) {
      throw error;
    }
  }
  
  // Resend Sign Up Code
  async resendOTP(username: string) {
    try {
      await resendSignUpCode({ username });
    } catch (error) {
      return error;
    }
  }
  
  // Sign out method
  async signOut() {
    try {
      await signOut();
      this.userRoleCache = null;
    } catch (error) {
      throw error;
    }
  }
  
  // Fetch user role from session
  async getUserRole(): Promise<string | null> {
    if (this.userRoleCache) {
      return this.userRoleCache; // Return cached user role if available
    }
    
    try {
      const session = await this.currentSessionUser();
      const role = session?.tokens?.idToken?.payload['custom:user_role'];
      this.userRoleCache = typeof role === 'string' ? role : null; // Ensure it's a string or null
      return this.userRoleCache;
    } catch (error) {
      throw error;
    }
  }
  
  async currentSessionUser() {
    try {
      return await fetchAuthSession();
    } catch (error) {
      throw error;
    }
  }
}

const authService = new AuthService();
export default authService;
