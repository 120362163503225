import React, { useState } from "react";
import { Table } from "flowbite-react";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";

interface Approver {
  id: string;
  name: string;
  role: string;
}

const ApproverList: React.FC = () => {
  const [selectedApprover, setSelectedApprover] = useState<string | null>(null);
  
  const approvers: Approver[] = [
    { id: "1", name: "Ahmad Westervelt", role: "Host Role" },
    { id: "2", name: "Ahmad Dokidis", role: "Host Role" },
    { id: "3", name: "Kaylynn Rhiel Madsen", role: "Host Role" },
    { id: "4", name: "Kadin Press", role: "Host Role" },
  ];
  
  const handleRowClick = (id: string) => {
    setSelectedApprover(id);
  };
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/assign-approvers" label="Back"/>
        <h1 className="text-xl font-bold mb-4">Approver list</h1>
        <CustomBreadcrumb
          items={[
            {label: "Assign Approvers", to: "/assign-approvers"},
            {label: "Approver list"},
          ]}
        />
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-6">
        <Table className="w-full">
          <Table.Head>
            <Table.HeadCell>Approver Name</Table.HeadCell>
            <Table.HeadCell>Role</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {approvers.map((approver) => (
              <Table.Row
                key={approver.id}
                className={`cursor-pointer hover:bg-gray-100 ${
                  selectedApprover === approver.id ? "bg-blue-50" : ""
                }`}
                onClick={() => handleRowClick(approver.id)}
              >
                <Table.Cell>
                  <label className="flex items-center space-x-4">
                    <input
                      type="radio"
                      name="approver"
                      checked={selectedApprover === approver.id}
                      onChange={() => handleRowClick(approver.id)}
                      className="cursor-pointer"
                    />
                    <span>{approver.name}</span>
                  </label>
                </Table.Cell>
                <Table.Cell>{approver.role}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      
      <div className="flex justify-start mt-6">
        <CustomLoaderButton color='blue' disabled={!selectedApprover}>
          Assign
        </CustomLoaderButton>
      </div>
    </div>
  );
};

export default ApproverList;
