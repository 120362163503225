import { useState } from 'react';
import { AddStageModal } from './addPlanStage';
import { StageProgressBar } from './stageProgressBar/stageProgressBar';
import { StageInput } from './stageInput/stageInput';
import { StageList } from './stageList/stageList';
import {PlanStagesStepProps} from "../../../core/interface/StageFormFieldProps";

export const PlanStagesStep: React.FC<PlanStagesStepProps> = (props) => {
  const [isOpenStageDialog, setIsOpenStageDialog] = useState(false);
  const [stageCount, setStageCount] = useState(0);
  const [currentStageIndex, setCurrentStageIndex] = useState<number | null>(null);
  const [stages, setStages] = useState<any[]>([]);
  const [selectedStageData, setSelectedStageData] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  
  const handleGo = () => {
    const count = parseInt(inputValue) || 0;
    if (count > 0) {
      const stagesArray = Array(count).fill(null);
      setStageCount(count);
      setStages(stagesArray);
      props.onStagesComplete(false, stagesArray);
    }
  };
  
  const openStageFormDialog = (index: number) => {
    setCurrentStageIndex(index);
    const defaultStageData = {
      name: '',
      type: '',
      sessionName: '',
      sessionMode: '',
      sessionAudience: '',
      sessionDuration: null,
      hostUserType: '',
      order_number: index + 1,
    };
    setSelectedStageData(stages[index] || defaultStageData);
    setIsOpenStageDialog(true);
  };
  
  const handleSaveStage = (stageData: any) => {
    const updatedStages = [...stages];
    if (currentStageIndex !== null) {
      updatedStages[currentStageIndex] = { ...stageData, order_number: currentStageIndex + 1 };
      setStages(updatedStages);
      const allFilled = updatedStages.every(stage => stage !== null);
      props.onStagesComplete(allFilled, updatedStages);
    }
    setIsOpenStageDialog(false);
  };
  
  const getProgress = () => {
    const completedStages = stages.filter(stage => stage !== null).length;
    return (completedStages / stageCount) * 100;
  };
  
  return (
    <div>
      <StageInput
        inputValue={inputValue}
        setInputValue={setInputValue}
        stageCount={stageCount}
        handleGo={handleGo}
      />
      {stageCount > 0 && <StageProgressBar progress={getProgress()} stageCount={stageCount} />}
      {stages.length > 0 && <StageList stages={stages} handleOpenModal={openStageFormDialog} />}
      <AddStageModal
        isOpen={isOpenStageDialog}
        onClose={() => setIsOpenStageDialog(false)}
        onSave={handleSaveStage}
        stageData={selectedStageData}
      />
    </div>
  );
};
