export enum StageType {
  ORIENTATION = "ORIENTATION",
  SESSION_COUNSELLING = "SESSION-COUNSELLING",
  SESSION_EXPERT = "SESSION-EXPERT",
  PSYCHOMETRIC_ASSESSMENT = "PSYCHOMETRIC-ASSESSMENT",
  PSYCHOMETRIC_ASSESSMENT_REPORT = "PSYCHOMETRIC-ASSESSMENT-REPORT",
  ACTION_PLAN = "ACTION-PLAN",
  B2B_1ON1_COUNSELLING_SESSION = "B2B-1ON1-COUNSELLING-SESSION",
  B2B_GROUP_COUNSELLING_SESSION = "B2B-GROUP-COUNSELLING-SESSION",
  B2C_COUNSELLING_SESSION = "B2C-COUNSELLING-SESSION",
  COUNSELLOR_CREATE_ACTION_PLAN = "COUNSELLOR-CREATE-ACTION-PLAN",
  COUNSELLOR_CREATE_GENERALISED_ACTION_PLAN = "COUNSELLOR-CREATE-GENERALISED-ACTION-PLAN",
  EXPERT_SESSION = "EXPERT-SESSION",
  EXPERT_SESSION_REPORT = "EXPERT-SESSION-REPORT",
  B2B_1ON1_ENTRANCE_PATHWAY_SESSION = "B2B-1ON1-ENTRANCE-PATHWAY-SESSION",
  B2B_GROUP_ENTRANCE_PATHWAY_SESSION = "B2B-GROUP-ENTRANCE-PATHWAY-SESSION",
  B2C_ENTRANCE_PATHWAY_SESSION = "B2C-ENTRANCE-PATHWAY-SESSION",
  B2B_GROUP_STUDY_ABROAD_OVERVIEW_SESSION = "B2B-GROUP-STUDY-ABROAD-OVERVIEW-SESSION",
  B2B_STUDENT_ALACARTE_STUDY_ABROAD_EXPERT_CONNECT_SESSION = "B2B-STUDENT-ALACARTE-STUDY-ABROAD-EXPERT-CONNECT-SESSION",
  PARENT_SESSION = "PARENT-SESSION",
  PARENT_SESSION_REPORT = "PARENT-SESSION-REPORT",
  PSYCHOMETRIC_ASSESSMENT_REPORT_INTERPRETATION_SESSION = "PSYCHOMETRIC-ASSESSMENT-REPORT-INTERPRETATION-SESSION",
  COUNSELLING_1ON1_DEMO_SESSION = "COUNSELLING-1ON1-DEMO-SESSION",
  COUNSELLING_IDENTIFY_SKILL_APTITUDE_SESSION = "COUNSELLING-IDENTIFY-SKILL-APTITUDE-SESSION",
  COUNSELLING_CAREER_EXPLORATION_SESSION = "COUNSELLING-CAREER-EXPLORATION-SESSION",
  COUNSELLING_CAREER_PATH_IDENTIFIER_SESSION = "COUNSELLING-CAREER-PATH-IDENTIFIER-SESSION",
  COUNSELLING_SOFT_SKILL_SESSION = "COUNSELLING-SOFT-SKILL-SESSION",
  TENTH_TWELVETH_ACCELERATE_FINAL_REPORT = "10-12-ACCELERATE-FINAL-REPORT",
  COUNSELLING_HIGHER_EDUCATION_SESSION = "COUNSELLING-HIGHER-EDUCATION-SESSION",
  COUNSELLING_CAREER_JOURNEY_SESSION = "COUNSELLING-CAREER-JOURNEY-SESSION",
  COUNSELLING_GENERAL_HIGHER_EDUCATION_SESSION = "COUNSELLING-GENERAL-HIGHER-EDUCATION-SESSION",
  COUNSELLING_WORK_LIFE_BALANCE_SESSION = "COUNSELLING-WORK-LIFE-BALANCE-SESSION",
  STUDY_ABROAD_SESSION = "STUDY-ABROAD-SESSION",
  STUDY_ABROAD_MOCK_INTERVIEW_SESSION = "STUDY-ABROAD-MOCK-INTERVIEW-SESSION",
  STUDY_ABROAD_REPORT = "STUDY-ABROAD-REPORT",
  STUDY_ABROAD_SUCCESS_STORY = "STUDY-ABROAD-SUCCESS-STORY",
}
