import {Button} from 'flowbite-react';
import {MdModeEdit} from 'react-icons/md';
import {StageCardProps} from "../../../../core/interface/StageFormFieldProps";
import React from "react";

const CreateStageCard: React.FC<StageCardProps> = (props) => {
  return (
    <div className={`p-4 border rounded-lg shadow-lg ${!props.showStage ? 'hidden' : ''}`}>
      <div className={`flex-grow ${props.stage ? 'w-full' : 'hidden'}`}>
        {props.stage ? (
          <>
            <div className="relative flex flex-col justify-center items-center">
              <h3 className="flex-grow text-center font-bold text-lg">Stage {props.index + 1}</h3>
              <span className="text-center font-bold text-lg">{props.stage.name}</span>
              <div className="absolute top-0 right-0 flex items-center">
                <button type="button" className="text-gray-500 ml-auto hover:!text-blue-700"
                        onClick={() => props.handleOpenModal(props.index)}>
                  <MdModeEdit className="text-2xl"/>
                </button>
              </div>
            </div>
            <ul className="list-disc text-base text-gray-900 px-5">
              {props.stage.order_number && <li>Order number: {props.stage.order_number}</li>}
              {props.stage.type && <li>Type: {props.stage.type}</li>}
              {props.stage.sessionName && <li>Session name: {props.stage.sessionName}</li>}
              {props.stage.sessionMode && <li>Session mode: {props.stage.sessionMode}</li>}
              {props.stage.sessionAudience && <li>Session audience: {props.stage.sessionAudience}</li>}
              {props.stage.sessionDuration && <li>Session duration (minutes): {props.stage.sessionDuration}</li>}
              {props.stage.hostUserType && <li>Session allowed host roles: {props.stage.hostUserType}</li>}
            </ul>
          </>
        ) : null}
      </div>
      <div className="w-full">
        {!props.stage && (
          <Button color="blue" outline className="w-full text-center block"
                  onClick={() => props.handleOpenModal(props.index)}>
            Add Stage
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateStageCard;
