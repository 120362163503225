import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {QueryInterface} from "../../../core/interface/query/QueryInterface";

// Fetch queries
export const fetchQueriesApi = async (page: number, pageSize: number,): Promise<CustomResponse<QueryInterface[]>> => {
  const params = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  
  const response = await axios.get<CustomResponse<QueryInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/queries`, {params: params},
  );
  return response.data;
};

// Query details
export const fetchQueryDetailsApi = async (queryId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<QueryInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/query/${queryId}`);
  return res.data;
}

// Replay query
export const replyQueryApi = async (queryId: string, queryData: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/query/${queryId}`, queryData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};
