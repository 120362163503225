import {useQuery} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {fetchAssignApproverDetailsApi, fetchAssignApproversRequestApi} from "./assignApproversApi";

export const useGetAssignApproversRequestApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<any[]>>({
    queryKey: ['assignApprivers', {page, pageSize}],
    queryFn: () => fetchAssignApproversRequestApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchAssignApproverDetailsApi = (assignApproverId: any) => {
  return useQuery<DetailsCustomDataResponse<any>>({
    queryKey: ['assignAppriver', {assignApproverId}],
    queryFn: () => fetchAssignApproverDetailsApi(assignApproverId),
    enabled: !!assignApproverId
  })
}
