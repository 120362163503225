export enum EntityType {
  SCHOOL = "SCHOOL",
  COLLEGE = "COLLEGE",
  CORPORATE = "CORPORATE",
  GOV = "GOV",
  CSR = "CSR",
  NGO = "NGO",
  WHITELISTING = "WHITELISTING",
  CPCC = "CPCC"
}
