import {useQuery} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {fetchGroupSessionDetailsApi, fetchGroupSessionsApi} from "./groupSessionsApi";

export const useGetGroupSessionsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<any[]>>({
    queryKey: ['groupSessions', {page, pageSize}],
    queryFn: () => fetchGroupSessionsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchGroupSessionDetailsApi = (groupSessionId: any) => {
  return useQuery<DetailsCustomDataResponse<any>>({
    queryKey: ['groupSession', {groupSessionId}],
    queryFn: () => fetchGroupSessionDetailsApi(groupSessionId),
    enabled: !!groupSessionId
  });
};
