import React, {useState} from "react";
import logo from '../../../assets/logo/mere-mentor-name-logo.svg';
import {useNavigate} from "react-router-dom";
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import authService from '../api/authService';
import {Label, TextInput} from "flowbite-react";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";
import CustomPasswordInput from "../../../shared/components/form-wrapper/customPasswordInput";
import CommonFooter from "../../../shared/components/footers/commonFooter";
import ConfirmDialog from "../../../shared/components/modals/confirmDialog";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Enter a valid email'
    )
    .required('Username is required'),
  password: yup
    .string()
    .min(8, 'Password should be at least 8 characters long')
    .required('Password is required'),
});

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [email, setEmail] = useState(''); // State to store email
  const navigate = useNavigate();
  const {showToastMessage} = useToast();
  
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: { email: string; password: string }) => {
    setLoading(true);

    try {
      const user = await authService.signIn(data.email, data.password);

      if (user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        showToastMessage({type: ToastType.Success, message: 'OTP has been sent to your registered email!'});
        navigate('/email-otp', {state: {email: data.email}});
        return;
      } else if (user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        setEmail(data.email);
        setIsConfirmDialogVisible(true);
        return;
      } else {
        showToastMessage({type: ToastType.Error, message: 'Something went wrong, please try again.!'});
      }
    } catch (error) {
      const errorMessage = error?.toString() || '';

      const errorMap: { [key: string]: string } = {
        'NotAuthorizedException': 'Incorrect username or password.',
        'UserNotFoundException': 'User does not exist.',
        'UserAlreadyAuthenticatedException': 'There is already a signed in user.',
      };

      const message = Object.keys(errorMap).find((key) => errorMessage.includes(key));

      showToastMessage({
        type: ToastType.Error,
        message: message ? errorMap[message] : 'An unexpected error occurred. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateClick = () => {
    setIsConfirmDialogVisible(false);
    navigate('/create-new-password', {state: {email}}); // Use the email stored in state
  };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <ConfirmDialog
        submitButtonText={`Create`}
        submitButtonClass={`blue`}
        isVisible={isConfirmDialogVisible}
        onClose={() => setIsConfirmDialogVisible(false)}
        onClick={handleCreateClick}
        title="Create Password"
        subTitle="Being a first time user you need to create a new password."
      />

      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="mb-4">
          <img src={logo} alt="Mere Mentor"/>
        </div>
        <div className="w-full md:w-96">
          <p className="px-2 text-center text-gray-500 mb-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis luctus fermentum lacus, quis feugiat mauris
            faucibus nec.
          </p>

          <form className="p-8" onSubmit={handleSubmit(onSubmit)}>

            <div className="mb-4">
              <div className="mb-2 block">
                <Label htmlFor="userName" color={errors.email ? 'failure' : 'default'} value="User Name"/>
              </div>
              <TextInput
                type="email"
                className={`block w-full mt-2 ${errors.email ? 'border-red-500' : ''}`}
                placeholder="Email address"
                {...register('email')}
                disabled={loading}  // Disable input during loading
                color={errors.email ? "failure" : "success"}
                helperText={errors.email && <span className="text-red-600 text-sm mt-1">{errors.email.message}</span>}
              />
            </div>

            <CustomPasswordInput
              register={register} // Pass register function
              error={errors.password}
              loading={loading}
              fieldName="password"
              labelName="Password"
            />

            <div className="mb-6">
              <CustomLoaderButton
                size="lg"
                color="blue"
                type="submit"
                loading={loading}
                className="w-full"
              >
                Login
              </CustomLoaderButton>
            </div>
          </form>
        </div>
      </div>
      <CommonFooter/>
    </div>
  );
};

export default SignIn;
