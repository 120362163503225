import React, {useState} from 'react';
import {Label, TextInput} from 'flowbite-react';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import {PasswordInputProps} from "../../../core/interface/PasswordInputProps"; // Import icons from react-icons

const CustomPasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <div className="relative mb-4">
      <div className="mb-2 block">
        <Label
          htmlFor={props.fieldName}
          color={props.error ? 'failure' : 'default'}
          value={props.labelName}
        />
      </div>
      <div className="relative">
        <TextInput
          id={props.fieldName}
          type={showPassword ? 'text' : 'password'}
          className='block w-full mt-2 border-red-500'
          placeholder={props.labelName}
          disabled={props.loading}
          color={props.error ? 'failure' : 'success'}
          {...props.register(props.fieldName)} // Ensure register function is used
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500"
          aria-label={showPassword ? 'Hide password' : 'Show password'} // Accessibility improvement
        >
          {showPassword ? <FaEyeSlash/> : <FaEye/>}
        </button>
      </div>
      {props.error && (
        <p className="text-red-600 text-sm mt-1">{props.error.message}</p>
      )}
    </div>
  );
};

export default CustomPasswordInput;
