import React, {useEffect, useState} from "react";
import logo from '../../../assets/logo/mere-mentor-name-logo.svg';
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import authService from '../api/authService';
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";
import CustomPasswordInput from "../../../shared/components/form-wrapper/customPasswordInput";
import CommonFooter from "../../../shared/components/footers/commonFooter";
import {Label, TextInput} from "flowbite-react";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password should be at least 8 characters long'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword'),], 'Password must match')
});

const CreateNewPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {showToastMessage} = useToast();
  const email = location.state?.email;
  
  useEffect(() => {
    if (!email) {
      navigate('/signin');
    }
  }, [email, navigate]);
  
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(validationSchema),
  });
  
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await authService.confirmSignInNewPassword(data.newPassword);
    } catch (error: any) {
      if (error.name === 'UserLambdaValidationException' && error.message.includes('Precondition Failed: Email not verified.')) {
        showToastMessage({
          type: ToastType.Success,
          message: 'Your password has been successfully reset. You can now log in with your new password.'
        });
        navigate('/signin');
      } else {
        showToastMessage({type: ToastType.Error, message: 'An unexpected error occurred. Please try again'});
      }
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="mb-4">
          <img src={logo} alt="Mere Mentor"/>
        </div>
        <div className="w-full md:w-96">
          <p className="px-2 text-center text-gray-500 mb-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis luctus fermentum lacus, quis feugiat mauris
            faucibus nec.
          </p>
          
          <form className="p-8" onSubmit={handleSubmit(onSubmit)}>
            
            <div className="mb-4">
              <div className="mb-2 block">
                <Label htmlFor="userName" color='default' value="User Name"/>
              </div>
              <TextInput
                type="email"
                value={email}
                className={`block w-full mt-2 border-red-500`}
                disabled
                color={!email ? "failure" : "success"}
              />
            </div>
            
            <CustomPasswordInput
              register={register}
              error={errors.newPassword}
              loading={loading}
              fieldName="newPassword"
              labelName="New Password"
            />
            
            <CustomPasswordInput
              register={register}
              error={errors.confirmPassword}
              loading={loading}
              fieldName="confirmPassword"
              labelName="Confirm Password"
            />
            
            <div className="mb-6">
              <CustomLoaderButton
                size="lg"
                color="blue"
                type="submit"
                loading={loading}
                className="w-full">
                Reset Password
              </CustomLoaderButton>
            </div>
            
            <div className="text-center">
              <Link to={`/forgot-password`} className="text-sm text-gray-900 hover:text-blue-500 hover:underline">
                Forgot password?
              </Link>
            </div>
          </form>
        </div>
      </div>
      
      <CommonFooter/>
    </div>
  );
};

export default CreateNewPassword;
