import {Button, Modal} from 'flowbite-react';
import {ConfirmDialogProps} from "../../../core/interface/ConfirmDialogProps";
import React from "react";
import CustomLoaderButton from "../custom-buttons/customLoaderButton";

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props: ConfirmDialogProps) => {
  return (
    <Modal show={props.isVisible} size="md" dismissible={false}>
      <Modal.Body>
        {props.title && (
          <h1 className={`text-center text-lg font-bold mb-5`}>
            {props.title}
          </h1>
        )}
        <div className="text-center">
          {props.icon && (
            <div className="text-center mb-4">
              {props.icon}
            </div>
          )}
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {props.subTitle}
          </h3>
          <div className="flex justify-center gap-4">
            <CustomLoaderButton
              size="lg"
              color="blue"
              type="button"
              loading={props.loading}
              onClick={props.onClick}>
              {props.submitButtonText}
            </CustomLoaderButton>
            
            {props.showCancelButton && (
              <Button size='lg' outline color={`blue`} onClick={props.onClose}>
                {props.cancelButtonText}
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDialog;
