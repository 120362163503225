import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Avatar, Button, Checkbox, FileInput, Label} from "flowbite-react";
import FormInput from "../../../shared/components/form-wrapper/FormInput";
import FormTextarea from "../../../shared/components/form-wrapper/FormTextarea";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";
import {useNavigate, useParams} from "react-router-dom";
import {useCreateVlogApi, useFetchVlogDetailsApi, useUpdateVlogApi} from "../services/useVlogsApi";
import {FaPlay} from "react-icons/fa";

const validationSchema = (isChangeVideo: boolean, isChangeThumbnailImage: boolean) => yup.object({
  vlog_video: isChangeVideo ?
    yup.mixed() :
    yup.mixed()
    .required("Video is required")
    .test("fileType", "Only video files are allowed", (value) =>
      value instanceof FileList && value.length > 0 && value[0].type.startsWith("video/")
    ),
  
  vlog_thumbnail_image: isChangeThumbnailImage ?
    yup.mixed() :
    yup.mixed()
    .required("Thumbnail image is required")
    .test("fileType", "Only image files are allowed", (value) =>
      value instanceof FileList && value.length > 0 && value[0].type.startsWith("image/")
    ),
  
  title: yup
    .string()
    .required("Vlog title is required")
    .trim()
    .min(1, "Vlog title must be at least 1 character"),
  
  desc: yup
    .string()
    .required("Vlog description is required")
    .trim()
    .min(1, "Vlog description must be at least 1 character"),
  
  is_popular: yup
    .boolean()
});

const CreateVlog: React.FC = () => {
  
  const [loading, setLoading] = useState(false);
  const [isChangeVideo, setIsChangeVideo] = useState(false);
  const [isChangeThumbnailImage, setIsChangeThumbnailImage] = useState(false);
  
  const navigate = useNavigate();
  const {id} = useParams<{ id: string }>();
  const {mutate: createVlog} = useCreateVlogApi();
  const {mutate: updateVlog} = useUpdateVlogApi();
  const {data: vlogDetails} = useFetchVlogDetailsApi(id);
  
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema(isChangeVideo, isChangeThumbnailImage)),
  });
  
  useEffect(() => {
    if (id && vlogDetails) {
      setValue('title', vlogDetails.data.title);
      setValue('desc', vlogDetails.data.desc);
      setValue('is_popular', vlogDetails.data.is_popular);
      setIsChangeVideo(true);
      setIsChangeThumbnailImage(true);
    }
  }, [id, setValue, vlogDetails]);
  
  const onSubmit = (data: any) => {
    setLoading(true);
    const {vlog_video, vlog_thumbnail_image, ...json_data} = data;
    const vlogFormData = {
      video: vlog_video?.[0],
      thumbnail_image: vlog_thumbnail_image?.[0],
      json_data,
    }
    
    const callbackConfig = {
      onSuccess: () => navigate("/vlogs"),
      onSettled: () => setLoading(false),
      onError: () => setLoading(false),
    };
    
    id
      ? updateVlog({vlogId: id, data: vlogFormData}, callbackConfig)
      : createVlog(vlogFormData, callbackConfig);
  };
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/vlogs" label="Back"/>
        <h1 className="text-xl font-bold mb-4">{id ? "Update Vlog" : "Create Vlog"}</h1>
        <CustomBreadcrumb
          items={[
            {label: "Vlogs", to: "/vlogs"},
            {label: `${id ? "Update Vlog" : "Create Vlog"}`},
          ]}
        />
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex space-x-6 mb-4">
            <div className="w-1/2">
              <Label htmlFor="upload_video" className="mb-2 block">
                Upload Video
              </Label>
              {id && isChangeVideo ? (
                <div className="flex items-center space-x-4">
                  <div className={`relative`}>
                    <Avatar size="lg" img={vlogDetails?.data?.thumbnail_image_url} alt={vlogDetails?.data?.title}
                            rounded/>
                    <div className="flex items-center space-x-2 absolute bottom-0 right-0">
                      <div className="flex justify-center items-center bg-white rounded-full w-10 h-10">
                        <FaPlay color="#1A56DB"/>
                      </div>
                    </div>
                  </div>
                  <Button disabled={loading} outline color="blue" onClick={() => setIsChangeVideo(false)}>
                    Change Video
                  </Button>
                </div>
              ) : (
                <>
                  <FileInput
                    {...register("vlog_video")}
                    id="upload_video"
                    accept="video/*"
                    disabled={loading}
                  />
                  {errors?.vlog_video?.message && (
                    <span className="text-red-600">{errors.vlog_video.message as string}</span>
                  )}
                </>
              )}
            </div>
            
            <div className="w-1/2">
              <Label htmlFor="upload_thumbnail_image" className="mb-2 block">
                Upload Thumbnail Image
              </Label>
              {id && isChangeThumbnailImage ? (
                <div className="flex items-center space-x-4">
                  <Avatar size="lg" img={vlogDetails?.data.thumbnail_image_url} alt={vlogDetails?.data.title} rounded/>
                  <Button disabled={loading} outline color="blue" onClick={() => setIsChangeThumbnailImage(false)}>
                    Change Thumbnail Image
                  </Button>
                </div>
              ) : (
                <>
                  <FileInput
                    {...register("vlog_thumbnail_image")}
                    id="upload_thumbnail_image"
                    accept="image/*"
                    disabled={loading}
                  />
                  {errors?.vlog_thumbnail_image && (
                    <span className="text-red-600">{errors.vlog_thumbnail_image.message as string}</span>
                  )}
                </>
              )}
            </div>
          </div>
          <FormInput
            className="w-1/2 mb-4"
            label="Vlog Title"
            important
            register={register("title")}
            error={errors.title?.message}
            placeholder="Enter vlog Title"
            disabled={loading}
          />
          <FormTextarea
            className="w-full mb-4"
            subClassName="h-32"
            label="Vlog Description"
            important
            register={register("desc")}
            error={errors.desc?.message}
            placeholder="Enter Description"
            disabled={loading}
          />
          <div className="flex items-center space-x-3 mb-4">
            <Checkbox
              {...register("is_popular")}
              id="isPopular"
            />
            <div>
              <Label htmlFor="isPopular" className="font-medium text-gray-900">
                Set as a popular
              </Label>
            </div>
          </div>
          <CustomLoaderButton size="lg" color="blue" type="submit" loading={loading}>
            {id ? "Save" : "Create"}
          </CustomLoaderButton>
        </form>
      </div>
    </div>
  )
    ;
};

export default CreateVlog;
