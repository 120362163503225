import React, {useCallback, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.css";
import {Navigation} from "swiper/modules";
import {Button} from "flowbite-react";
import {LuChevronLeftCircle, LuChevronRightCircle} from "react-icons/lu";
import {IoCheckmarkDoneSharp} from "react-icons/io5";
import {TbProgressCheck} from "react-icons/tb";
import {PiDotsThreeCircle} from "react-icons/pi";
import {StageSwiperProps} from "../../../core/interface/custom-swiper/StageSwiperProps";
import {Link} from "react-router-dom";
import {StageState} from "../../../core/enums/StageState";

const StageSwiper: React.FC<StageSwiperProps> = (props) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  
  const handleSwiper = useCallback((swiper: { isBeginning: boolean; isEnd: boolean }) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, []);
  
  return (
    <div className="border rounded-lg bg-white shadow-inner">
      <Swiper
        slidesPerView={1.2}
        spaceBetween={16}
        navigation={true}
        modules={[Navigation]}
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
        }}
        onSwiper={handleSwiper}
        onSlideChange={handleSwiper}
      >
        {props.stages.map((stage, index) => (
          <SwiperSlide key={index} className="mt-4">
            <div className="relative py-6">
              <div className="absolute top-0 left-0 rounded-full p-2 bg-white">
                <div
                  className={`p-2 rounded-full ${
                    stage?.stage_state === StageState.COMPLETED
                      ? "bg-green-100"
                      : stage.stage_state === StageState.ACTIVE
                        ? "bg-yellow-100"
                        : "bg-gray-100"
                  }`}
                >
                  {stage.stage_state === StageState.COMPLETED ? (
                    <IoCheckmarkDoneSharp size={20} className="text-green-700" />
                  ) : stage.stage_state === StageState.ACTIVE ? (
                    <TbProgressCheck size={20} className="text-yellow-800" />
                  ) : (
                    <PiDotsThreeCircle size={20} className="text-gray-500" />
                  )}
                </div>
              </div>
              <div className="border-t p-6">
                <h6 className="text-sm font-medium text-gray-800 mb-2">{stage.stage_name || "Untitled Stage"}</h6>
                <p className="text-xs text-gray-500 mb-4 line-clamp-1">{stage.stage_description || "No description provided"}</p>
                <Link
                  to={`update-entity-plan-stage`}
                  state={{entityName: props.entityName}}
                >
                  <Button color="blue">View Details</Button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex justify-center space-x-4 mt-4">
        <Button size="none" color="none" disabled={isBeginning}>
          <LuChevronLeftCircle size={24} className={isBeginning ? "text-gray-400" : "text-blue-700"} />
        </Button>
        <Button size="none" color="none" disabled={isEnd}>
          <LuChevronRightCircle size={24} className={isEnd ? "text-gray-400" : "text-blue-700"} />
        </Button>
      </div>
    </div>
  );
};

export default StageSwiper;
