import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../../../assets/logo/mere-mentor-name-logo.svg';
import authService from '../api/authService';
import CommonFooter from "../../../shared/components/footers/commonFooter";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";
import { useToast } from "../../../shared/components/notification-messages/toastNotification";
import { ToastType } from "../../../core/enums/toastType";

const OTPVerification: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpError, setOtpError] = useState("");
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { showToastMessage } = useToast();
  const email = location.state?.email;
  
  const handleChange = useCallback((value: string, index: number) => {
    if (/^\d?$/.test(value)) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });
      
      if (value && index < 5) inputsRef.current[index + 1]?.focus();
      if (!value && index > 0) inputsRef.current[index - 1]?.focus();
    }
  }, []);
  
  const handlePaste = useCallback((e: React.ClipboardEvent) => {
    const pastedData = e.clipboardData.getData('text').slice(0, 6);
    if (/^\d{6}$/.test(pastedData)) {
      setOtp(pastedData.split(''));
      inputsRef.current[5]?.focus();
    }
  }, []);
  
  const handleSubmit = useCallback(async () => {
    const otpValue = otp.join('');
    
    if (otpValue.length < 6 || otp.includes("")) {
      setOtpError("OTP is required.");
      return;
    }
    
    setLoading(true);
    setOtpError("");
    
    try {
      const verifiedUser = await authService.signInVerifyOTP(otpValue);
      if (verifiedUser?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        showToastMessage({ type: ToastType.Error, message: 'Wrong OTP. Please try again.' });
        return;
      }
      navigate("/dashboard");
    } catch (error) {
      const errorMessage = error?.toString() || '';
      const message = errorMessage.includes('NotAuthorizedException')
        ? 'You\'ve exceeded the maximum OTP attempts. Please contact support for help.'
        : errorMessage.includes('UserNotFoundException')
          ? 'User does not exist.'
          : 'An unexpected error occurred. Please try again.';
      
      showToastMessage({ type: ToastType.Error, message });
      navigate("/signin");
    } finally {
      setLoading(false);
    }
  }, [otp, navigate, showToastMessage]);
  
  const handleReSendOtp = useCallback(async () => {
    await authService.resendOTP(email);
    showToastMessage({ type: ToastType.Success, message: 'OTP resent to your email successfully.' });
  }, [email, showToastMessage]);
  
  useEffect(() => {
    if (otp.every(digit => digit !== "")) {
      handleSubmit();
    }
  }, [otp, handleSubmit]);
  
  useEffect(() => {
    if (!email) navigate('/signin');
    else inputsRef.current[0]?.focus();
  }, [email, navigate]);
  
  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="mb-4">
          <img src={logo} alt="Mere Mentor" />
        </div>
        <div className="w-full md:w-96 text-center">
          <p className="text-gray-500 mb-2">
            Sent a verification code to verify your email address.
          </p>
          <span className='text-gray-500'>sent to {email}</span>
          
          <form className="p-8" onSubmit={(e) => e.preventDefault()}>
            <div className="flex justify-center space-x-2 mb-6">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={el => inputsRef.current[index] = el!}
                  type="text"
                  maxLength={1}
                  value={digit}
                  autoComplete="off"
                  onChange={(e) => handleChange(e.target.value, index)}
                  onPaste={handlePaste}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' && !otp[index] && index > 0) {
                      inputsRef.current[index - 1]?.focus();
                    }
                  }}
                  disabled={loading}
                  className="w-12 h-12 text-center text-xl border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              ))}
            </div>
            {otpError && <p className="text-red-500 text-sm mb-4">{otpError}</p>}
            
            <CustomLoaderButton size="lg" color="blue" type="button" onClick={handleSubmit} loading={loading} className="w-full">
              Verify & Proceed
            </CustomLoaderButton>
          </form>
          
          <div className="text-center">
            <button
              className="text-sm text-gray-900 hover:text-blue-500 hover:underline"
              onClick={handleReSendOtp}
            >
              Resend OTP
            </button>
          </div>
        </div>
      </div>
      
      <CommonFooter />
    </div>
  );
};

export default OTPVerification;
