import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import Logo from '../../../assets/logo/mere-mentor-name-logo.svg';
import authService from "../../../features/auth/api/authService";
import { useNavigate } from "react-router-dom";
import { useToast } from "../notification-messages/toastNotification";
import { ToastType } from "../../../core/enums/toastType";

const TopNavbar: React.FC = () => {
  
  const navigate = useNavigate();
  const { showToastMessage } = useToast();
  const [userName, setUserName] = useState<string | null>(null);
  
  // Mark handleSignOut as async
  const handleSignOut = useCallback(async () => {
    try {
      await authService.signOut(); // Await inside async function
      navigate('/signin');
      showToastMessage({ type: ToastType.Success, message: 'User sign out successfully' });
    } catch (error) {
      showToastMessage({ type: ToastType.Error, message: 'Error signing out' });
    }
  }, [navigate, showToastMessage]);
  
  useEffect(() => {
    const currentUser = async () => {
      try {
        const currentUser = await authService.currentSessionUser();
        setUserName(currentUser?.tokens?.idToken?.payload?.email as string);
      } catch (error) {
        handleSignOut(); // Handle errors if fetching user info fails
      }
    };
    
    currentUser();
  }, [handleSignOut]);
  
  return (
    <Navbar fluid rounded>
      <Navbar.Brand>
        <img src={Logo} className="mr-3 w-64" alt="Flowbite React Logo" />
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar rounded />
          }
        >
          <Dropdown.Header>
            <span className="block truncate text-sm font-medium">{userName}</span>
          </Dropdown.Header>
          <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
    </Navbar>
  );
};

export default TopNavbar;
