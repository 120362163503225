import React, {useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {Button} from 'flowbite-react';
import Stepper from '../../../shared/components/custom-stepper/customStepper';
import {CreatePlanForm} from './createPlanForm';
import {PlanStagesStep} from './planStagesStep';
import {useNavigate} from 'react-router-dom';
import {useToast} from '../../../shared/components/notification-messages/toastNotification';
import {ValidityType} from '../../../core/enums/validityType';
import {validateValidityDuration} from '../../../shared/validation/validityDurationValidator';
import {ToastType} from '../../../core/enums/toastType';
import {useCreatePlanApi} from "../services/usePlanApi";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {transformStages} from "./utils/transformStages";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";

const STEPS = ['Step 1: Add plan details', 'Step 2: Add plan stages'];

const FORM_DEFAULT_VALUES = {
  name: '',
  type: '',
  business_vertical: '',
  currency: 'INR',
  validity_type: '',
  validity_duration: '',
  applicable_grades: [],
  price: ''
};

// Validation schema using yup
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Plan type is required'),
  business_vertical: yup.string().required('Business Vertical is required'),
  validity_type: yup.string().required('Validity Type is required'),
  
  validity_duration: yup
    .string()
    .required('Validity duration is required')
    .test('is-valid-duration', function (value) {
      const {validity_type} = this.parent;
      const validityTypeEnumValue = ValidityType[validity_type.toUpperCase() as keyof typeof ValidityType];
      
      if (isNaN(Number(value)) || Number(value) <= 0) {
        return this.createError({message: 'Validity duration must be a positive number'});
      }
      
      const validationResult = validateValidityDuration(Number(value), validityTypeEnumValue);
      return validationResult === true
        ? true
        : this.createError({message: validationResult as string});
    }),
  
  applicable_grades: yup
    .array()
    .of(yup.string().required('Grade is required'))
    .min(1, 'At least one grade must be added')
    .required('Applicable Grades are required'),
  
  price: yup
    .string()
    .required('Price is required')
    .test('is-number', 'Price must be greater than 0', (value) => !isNaN(Number(value)) && Number(value) > 0),
});

type FormData = yup.InferType<typeof validationSchema>;

const CreatePlan = () => {
  
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stagesComplete, setIsStagesComplete] = useState(false);
  const [stages, setStages] = useState<any[]>([]);
  const navigate = useNavigate();
  const {showToastMessage} = useToast();
  const {mutate: createPlan} = useCreatePlanApi();
  
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: FORM_DEFAULT_VALUES,
  });
  
  const steps = useMemo(() => STEPS, []);
  
  // Function to move to the next step
  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };
  
  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  
  const handleStagesComplete = (complete: boolean, stagesData: any[]) => {
    setIsStagesComplete(complete);
    setStages(stagesData);
  };
  
  const onSubmit = async (data: FormData) => {
    if (currentStep === steps.length - 1) {
      if (!stagesComplete) return;
      
      setLoading(true);
      try {
        const transformedStages = transformStages(stages);
        const planData = {
          ...data,
          stages: transformedStages,
        };
        
        createPlan(planData, {
          onSuccess: () => {
            showToastMessage({type: ToastType.Success, message: 'Plan created successfully'});
            navigate('/plans');
          },
          onError: () => {
            showToastMessage({type: ToastType.Error, message: 'Something went wrong.'});
          },
        });
      } catch (error) {
        showToastMessage({ type: ToastType.Error, message: 'Something went wrong.' });
      } finally {
        setLoading(false);
      }
    } else {
      handleNext();
    }
  };
  
  return (
    <div className="py-10 px-20 bg-white rounded-xl shadow-md">
      <div className="mb-4">
        <h1 className="text-2xl font-bold mb-4">Create Plan</h1>
        <CustomBreadcrumb
          items={[
            {label: 'Plans', to: '/plans'},
            {label: 'Create Plan'},
          ]}
        />
      </div>
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="p-10 border rounded-lg shadow-lg">
          <Stepper currentStep={currentStep} steps={steps}/>
          {currentStep === 0 && (
            <CreatePlanForm register={register} errors={errors} setValue={setValue}/>
          )}
          {currentStep === 1 && (
            <PlanStagesStep isLoading={loading} onStagesComplete={handleStagesComplete}/>
          )}
        </div>
        
        <div className="flex space-x-8">
          {currentStep > 0 && (
            <Button size="lg" outline color="blue" type="button" onClick={handlePrev}>
              Previous: Plan Details
            </Button>
          )}
          
          <CustomLoaderButton
            size="lg"
            color="blue"
            type="submit"
            disabled={currentStep === steps.length - 1 && !stagesComplete}
            loading={loading}
          >
            {currentStep === steps.length - 1 ? 'Create Plan' : 'Next: Create Plan Stages'}
          </CustomLoaderButton>
        </div>
      </form>
    </div>
  );
};

export default CreatePlan;
