import {CustomResponse} from "../../../core/interface/CustomResponse";
import axios from "axios";
import {IndividualSessionsInterface} from "../../../core/interface/individualSession/IndividualSessionsInterface";

// Fetch individual sessions
export const fetchIndividualSessionsApi = async (page: number, pageSize: number): Promise<CustomResponse<IndividualSessionsInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<IndividualSessionsInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/individual-sessions`,
    { params: queryParams }
  );
  return response.data;
};
