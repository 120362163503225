import React, { useState } from "react";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import { Table } from "flowbite-react";

interface Host {
  name: string;
  role: string;
}

const CreateSession: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [hostList, setHostList] = useState<Host[]>([]);
  const [selectedHost, setSelectedHost] = useState<string | null>(null); // Track selected host
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  
  const dates = [
    { value: "25-11-2024, 11:00am", label: "25-11-2024, 11:00am" },
    { value: "28-11-2024, 02:00pm", label: "28-11-2024, 02:00pm" },
    { value: "10-12-2024, 10:00am", label: "10-12-2024, 10:00am" },
  ];
  
  const hosts: Record<string, Host[]> = {
    "25-11-2024, 11:00am": [
      { name: "Ahmad Westervelt", role: "Host Role" },
      { name: "Ahmad Dokidis", role: "Host Role" },
    ],
    "28-11-2024, 02:00pm": [
      { name: "Ahmad Westervelt", role: "Host Role" },
      { name: "Ahmad Dokidis", role: "Host Role" },
      { name: "Kaylynn Rhiel Madsen", role: "Host Role" },
    ],
    "10-12-2024, 10:00am": [
      { name: "Ahmad Dokidis", role: "Host Role" },
      { name: "Kadin Press", role: "Host Role" },
    ],
  };
  
  const handleDateChange = (date: string) => {
    setSelectedDate(date);
    setHostList(hosts[date] || []);
    setIsShowButton(false);
    setSelectedHost(null); // Reset selected host when date changes
  };
  
  const handleRowClick = (hostName: string) => {
    setSelectedHost(hostName);
  };
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/individual-sessions" label="Back" />
        <h1 className="text-xl font-bold mb-4">Create Session</h1>
        <CustomBreadcrumb
          items={[
            { label: "Individual Sessions", to: "/individual-sessions" },
            { label: "Create Session" },
          ]}
        />
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="mb-5">
          <p className="mb-4 font-medium">Preferred Date & Time</p>
          <div className="flex flex-wrap gap-4 mb-4">
            {dates.map(({ value, label }) => (
              <label
                key={value}
                className="flex items-center cursor-pointer space-x-2"
              >
                <input
                  type="radio"
                  name="preferredDate"
                  value={value}
                  checked={selectedDate === value}
                  onChange={() => {
                    setHostList([]);
                    setIsShowButton(true);
                    setSelectedDate(value);
                  }}
                  className="hidden peer"
                />
                <span className="w-5 h-5 border-2 border-gray-300 rounded-sm flex items-center justify-center peer-checked:bg-blue-500 peer-checked:border-blue-500">
                  <svg
                    className="w-3 h-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span>{label}</span>
              </label>
            ))}
          </div>
          <button
            className={`px-4 py-2 rounded ${
              isShowButton
                ? "bg-blue-500 text-white hover:bg-blue-600"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            onClick={() => handleDateChange(selectedDate)}
            disabled={!isShowButton}
          >
            Fetch Available Hosts
          </button>
        </div>
        
        {selectedDate && hostList.length > 0 && (
          <div>
            <h2 className="text-xl font-bold mb-4">Host List</h2>
            <p className="text-gray-500 mb-4">
              Host available on <strong>{selectedDate.split(",")[0]}</strong>
            </p>
            <Table className="w-full">
              <Table.Head>
                <Table.HeadCell>Host Name</Table.HeadCell>
                <Table.HeadCell>Host Role</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {hostList.map((host, index) => (
                  <Table.Row
                    key={index}
                    className={`cursor-pointer hover:bg-gray-100 ${
                      selectedHost === host.name ? "bg-blue-50" : ""
                    }`}
                    onClick={() => handleRowClick(host.name)} // Make row clickable
                  >
                    <Table.Cell>
                      <label className="flex items-center space-x-2">
                        <input
                          type="radio"
                          name="host"
                          checked={selectedHost === host.name} // Sync radio state with row selection
                          onChange={() => handleRowClick(host.name)} // Sync radio with row click
                          className="cursor-pointer"
                        />
                        <span>{host.name}</span>
                      </label>
                    </Table.Cell>
                    <Table.Cell>{host.role}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <button
              className={`mt-4 px-4 py-2 rounded ${
                selectedHost
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
              disabled={!selectedHost}
            >
              Create Session
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateSession;
