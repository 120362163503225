import React from 'react';
import { Link } from 'react-router-dom';
import { HiArrowSmLeft } from 'react-icons/hi';
import {BackButtonProps} from "../../../core/interface/BackButtonProps";

const BackButton: React.FC<BackButtonProps> = (props: BackButtonProps) => {
  return (
    <div className="flex items-center mb-2">
      <Link className="flex items-center text-gray-900 font-semibold"
            to={props.to}
            state={props.stateName ? {[props.stateName]: props.stateValue} : undefined}>
        <HiArrowSmLeft/> {props.label}
      </Link>
    </div>
  );
};

export default BackButton;
