import {Badge} from 'flowbite-react';
import {EntityPlanStatus} from "../../../core/enums/entityPlanStatus";

const StatusBadge = (status: EntityPlanStatus) => {
  switch (status) {
    case EntityPlanStatus.ACTIVE:
      return <Badge color="info">Active</Badge>;
    case EntityPlanStatus.EXPIRED:
      return <Badge color="failure">Expired</Badge>;
    case EntityPlanStatus.PAYMENT_COMPLETED:
      return <Badge color="success">Payment Completed</Badge>;
    case EntityPlanStatus.SETUP_PLAN_COMPLETED:
      return;
    case EntityPlanStatus.SETUP_ADD_PLAN_COMPLETED:
      return;
    case EntityPlanStatus.SETUP_ADD_CUSTOMER_INPROGRESS:
      return;
    default:
      return;
  }
};

export default StatusBadge;
