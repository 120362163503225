import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import ProfileField from "../../../shared/components/profile-field/profileField";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FormTextarea from "../../../shared/components/form-wrapper/FormTextarea";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";
import {useFetchQueryDetailsApi, useReplyQueryApi} from "../services/useQueryApi";
import {QueryInterface} from "../../../core/interface/query/QueryInterface";
import {Spinner} from "flowbite-react";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";

const validationSchema = yup.object({
  status: yup.string(),
  reply: yup.string().required("Replay message is required"),
});

const QueryDetails: React.FC = () => {
  const {register, handleSubmit, setValue, formState: {errors}} = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  
  const {id} = useParams<{ id: string }>();
  const {data, isLoading} = useFetchQueryDetailsApi(id);
  const queryDetails: QueryInterface | undefined = data?.data;
  setValue("status", queryDetails?.status);
  const {mutate: replyQuery} = useReplyQueryApi();
  
  const [loading, setLoading] = useState(false);
  
  const onSubmit = async (data: any) => {
    setLoading(true);
    if (id) {
      const mutationData = {queryId: id, data: data};
      replyQuery(mutationData, {
        onSuccess: () => {
          navigate('/queries');
        },
        onSettled: () => {
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        }
      });
    }
  }
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/queries" label="Back"/>
        <h1 className="text-xl font-bold mb-4">Query Details</h1>
        <CustomBreadcrumb
          items={[
            {label: "Queries", to: "/queries"},
            {label: "Query Details"},
          ]}
        />
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <div className="grid grid-cols-1 gap-x-8 gap-y-4 mb-4 bg-gray-50 rounded-md py-5 px-10">
            <ProfileField label={`Query title`} value={queryDetails?.title}/>
            <ProfileField label={`Message`} value={queryDetails?.message}/>
            <ProfileField label={`Date`} value={dateAndTimeFormatter(queryDetails?.created_at, 'DD-MM-YYYY')}/>
          </div>
          <div className={`bg-gray-50 rounded-md py-5 px-10`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormTextarea
                disabled={loading}
                className={`w-full mb-4`}
                subClassName="h-40 p-2 bg-white"
                label="Reply"
                important={true}
                register={register('reply')}
                error={errors.reply?.message}
                placeholder="Write text here ..."
              />
              
              <div className="col-span-2 flex justify-start">
                <CustomLoaderButton size="lg" color="blue" type="submit" className={`px-5`} loading={loading}>
                  Send message
                </CustomLoaderButton>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueryDetails;
