import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import {EntityPlanInterface} from "../../../../core/interface/entity/EntityPlanInterface";
import {
  EntityPlanCustomerInterface,
  EntityPlanCustomerRequest
} from "../../../../core/interface/entity-plan-customer/EntityPlanCustomerInterface";
import {
  EntityPlanCustomerUploadStatusInterface
} from "../../../../core/interface/entity/EntityPlanCustomerUploadStatusInterface";

// Fetch entity plans
export const fetchEntityPlansApi = async (
  entityId: any,
  page: number,
  pageSize: number,
  status: any
): Promise<CustomResponse<EntityPlanInterface[]>> => {
  const params = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  
  if (status && status.length > 0) {
    status.forEach((status: any) => {
      params.append("status", status);
    })
  }
  
  const response = await axios.get<CustomResponse<EntityPlanInterface[]>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${entityId}/plans`,
    {
      params: params,
    }
  );
  return response.data;
}

// Fetch entity plan details
export const fetchEntityPlanDetailsApi = async (entityId: any, entityPlanId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<EntityPlanInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${entityId}/plans/${entityPlanId}`);
  return res.data;
}

// Create entity plan
export const createEntityPlanApi = async (entityId: any, planData: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${entityId}/plan`, planData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

//  Fetch entity plan customers
export const fetchEntityPlanCustomersApi = async (data: EntityPlanCustomerRequest): Promise<CustomResponse<EntityPlanCustomerInterface[]>> => {
  const params = new URLSearchParams();
  
  if (data.currentPage) {
    params.set("page_num", data.currentPage.toString());
  }
  
  if (data.pageSize) {
    params.set("size", data.pageSize.toString());
  }
  
  if (data.searchValue) {
    params.set('search', data.searchValue);
  }
  
  const response = await axios.get<CustomResponse<EntityPlanCustomerInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${data.entityId}/plan/${data.entityPlanId}/customers`,
    {
      params: params,
    }
  );
  return response.data;
}

// Check entity plan customer upload status
export const entityPlanCustomerUploadStatusApi = async (entityId: any, entityPlanId: any) => {
  const response = await axios.get<DetailsCustomDataResponse<EntityPlanCustomerUploadStatusInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${entityId}/plans/${entityPlanId}/progress-status`)
  return response.data;
}
