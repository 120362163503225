import React, {useState} from "react";
import {useGetQueriesApi} from "../services/useQueryApi";
import {QueryInterface} from "../../../core/interface/query/QueryInterface";
import {Badge, Button, Pagination, Spinner, Table} from "flowbite-react";
import {QueryStatus} from "../../../core/enums/query/QueryStatus";
import {Link} from "react-router-dom";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";

const Queries: React.FC = () => {
  
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, isLoading} = useGetQueriesApi(currentPage, pageSize);
  const queries: QueryInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-semibold">Queries</h1>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className="border-b border-gray-200">
            <Table.HeadCell className="text-gray-500">Query Title</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Message</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Date</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Status</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Actions</Table.HeadCell>
          </Table.Head>
          
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : totalCount === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <div className="text-center text-gray-500">No query records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {queries.map((query) => (
                <Table.Row key={query.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {query.title.length > 20 ? `${query.title.substring(0, 20)}...` : query.title}
                  </Table.Cell>
                  <Table.Cell>
                    {query.message.length > 50 ? `${query.message.substring(0, 50)}...` : query.message}
                  </Table.Cell>
                  <Table.Cell>
                    {dateAndTimeFormatter(query?.created_at, 'DD-MM-YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex">
                      <Badge color={query.status === QueryStatus.RESOLVED ? "blue" : "gray"}>
                        {query.status === QueryStatus.RESOLVED ? "Reply Given" : "Reply Pending"}
                      </Badge>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="flex items-center space-x-2">
                    <Link to={`view-query/${query.id}`}>
                      <Button
                        className={`text-white font-semibold ${query.status === QueryStatus.RESOLVED ? "bg-gray-300" : "bg-blue-700"}`}
                        size="xs" disabled={query.status === QueryStatus.RESOLVED}>
                        Reply
                      </Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
        
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing{" "}
              <span className="font-semibold text-black">
          {pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}
        </span>{" "}
              of <span className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Queries;
