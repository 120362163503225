import CreateStageCard from "../createStageCard/createStageCard";
import {StageListProps} from "../../../../core/interface/StageFormFieldProps";

export const StageList: React.FC<StageListProps> = (props) => {
  
  return (
    <div className="mt-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {props.stages.map((stage, index) => {
          const showStage = index === 0 || (index > 0 && props.stages[index - 1] !== null);
          
          return (
            <CreateStageCard
              key={index}
              stage={stage}
              index={index}
              showStage={showStage}
              handleOpenModal={props.handleOpenModal}
            />
          );
        })}
      </div>
    </div>
  );
};
