import React, {useState} from 'react';
import {Avatar, Badge, Button, Pagination, Spinner, Table, ToggleSwitch, Tooltip} from "flowbite-react";
import {useDisableStaffApi, useGetStaffsApi} from "../services/useStaffApi";
import {
  StaffInterface,
  StaffsRequestParams
} from "../../../../core/interface/staff/StaffInterface";
import {IoEyeOutline} from "react-icons/io5";
import {FiEdit} from "react-icons/fi";
import BlockDialog from "../../../../shared/components/modals/BlockDialog";
import {Link} from "react-router-dom";
import {Roles} from "../../../../core/enums/roles";
import DropdownCheckboxFilter from "../../../../shared/components/custom-dropdown-box/dropdownCheckboxFilter";
import SearchInputBox from "../../../../shared/components/custom-search-box/searchInputBox";
import {DisableRequest} from "../../../../core/interface/DisableRequest";

const Staffs: React.FC = () => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedRolesOptions, setSelectedRolesOptions] = useState<string[]>([]);
  const [isUserBlockDialogVisible, setIsUserBlockDialogVisible] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<StaffInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  
  const filteredSearchQuery = searchQuery.length > 2 ? searchQuery : '';
  const staffRequest: StaffsRequestParams = {
    currentPage,
    pageSize,
    filteredSearchQuery,
    selectedRolesOptions
  }
  const {data, isLoading} = useGetStaffsApi(staffRequest);
  const {mutate: disableStaff} = useDisableStaffApi();
  
  const staffList: StaffInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const options = Object.entries(Roles).map(([key, value]) => ({
    label: value.replace(/-/g, ' '),
    value: value,
  }));
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  
  const handleStatusToggle = (staff: StaffInterface) => {
    setLoading(false);
    setSelectedStaff(staff);
    setIsUserBlockDialogVisible(true);
  };
  
  const handleUserBlockClick = (data: DisableRequest) => {
    setLoading(true);
    disableStaff({data}, {
      onSuccess: () => {
        setLoading(false);
        setIsUserBlockDialogVisible(false);
      },
      onSettled: () => {
        setLoading(true);
      }
    })
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-semibold">Staffs</h1>
        <div className="flex gap-4">
          <DropdownCheckboxFilter
            title='Roles'
            className='w-48'
            options={options}
            selectedOptions={selectedRolesOptions}
            onChange={setSelectedRolesOptions}
          />
          <SearchInputBox
            className='w-72'
            placeholderName='Search Name, Email, Phone...'
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
          />
          <Link to={'create-staff'}>
            <Button color="blue">Create Staff</Button>
          </Link>
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className='border-b border-gray-200'>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Phone</Table.HeadCell>
            <Table.HeadCell>DOJ</Table.HeadCell>
            <Table.HeadCell>Role</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : totalCount === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <div className="flex justify-center items-center py-5">
                    <div className="tet-center text-gray-500">No staff records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {staffList.map((staff) => (
                <Table.Row key={staff.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 flex items-center space-x-3">
                    <Avatar rounded img={staff?.profile_image}/>
                    <div>
                      <div className="text-sm font-semibold">
                        {staff?.first_name} {staff?.last_name}
                      </div>
                      <div className="text-sm text-gray-500">{staff?.email}</div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{staff?.phone_number}</Table.Cell>
                  <Table.Cell>{staff?.date_of_joining}</Table.Cell>
                  <Table.Cell className="flex">
                    <Badge color="green">{staff?.role}</Badge>
                  </Table.Cell>
                  <Table.Cell>
                    <ToggleSwitch
                      disabled={!staff?.is_active}
                      checked={staff.is_active}
                      label={staff.is_active ? "Block" : "Unblock"}
                      onChange={() => handleStatusToggle(staff)}
                    />
                  </Table.Cell>
                  <Table.Cell className="flex items-center space-x-2">
                    <Tooltip content="View" placement="bottom">
                      <Link to={`view-staff/${staff.id}`}>
                        <Button color="none" size="xs">
                          <IoEyeOutline size={`20`}/>
                        </Button>
                      </Link>
                    </Tooltip>
                    <Tooltip content="Edit" placement="bottom">
                      <Link to={`update-staff/${staff.id}`}>
                        <Button color="none" size="xs">
                          <FiEdit size="20"/>
                        </Button>
                      </Link>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        
        </Table>
        
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
      <BlockDialog
        staff={selectedStaff}
        submitButtonText="Confirm"
        submitButtonClass="blue"
        cancelButtonText="Cancel"
        isVisible={isUserBlockDialogVisible}
        loading={loading}
        onClose={() => setIsUserBlockDialogVisible(false)}
        onSubmit={(data: DisableRequest) => handleUserBlockClick(data)}
        title={selectedStaff?.is_active ? 'Block Staff' : 'Unblock Staff'}
        subTitle={`Please select a reason to ${selectedStaff?.is_active ? 'block' : 'unblock'}`}
      />
    </div>
  );
};

export default Staffs;
