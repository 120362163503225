import {Button, Label, TextInput} from 'flowbite-react';
import {StageInputProps} from "../../../../core/interface/StageFormFieldProps";

export const StageInput: React.FC<StageInputProps> = (props) => {
  return (
    <div className="flex space-x-4 mb-6">
      <div>
        <div className="flex items-center mb-2">
          <Label htmlFor="numberOfStages">
            Enter the number of stages<em>*</em>
          </Label>
        </div>
        <div className={`space-x-4 ${props.stageCount === 0 ? 'flex items-center' : 'hidden'}`}>
          <TextInput
            id="numberOfStages"
            type="number"
            value={props.inputValue}
            onChange={(e) => props.setInputValue(e.target.value)}
            placeholder="Enter number of stages"
          />
          <Button color="blue" onClick={props.handleGo}>Go</Button>
        </div>
        <p className={`${props.stageCount > 0 ? 'block' : 'hidden'}`}>{props.stageCount}</p>
      </div>
    </div>
  );
};
