import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {BlogInterface} from "../../../core/interface/blog/BlogInterface";

// Fetch blogs
export const fetchBlogsApi = async (page: number, pageSize: number,): Promise<CustomResponse<BlogInterface[]>> => {
  const params = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  
  const response = await axios.get<CustomResponse<BlogInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/blogs`, {params: params},
  );
  return response.data;
};

// Blog details
export const fetchBlogDetailsApi = async (vlogId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<BlogInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/blogs/${vlogId}`);
  return res.data;
}

// Blog create
export const createBlogApi = async (blogData: any) => {
  try {
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(blogData.json_data));
    if (blogData.thumbnail_image) {
      formData.append('thumbnail_image', blogData.thumbnail_image);
    }
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs/blogs`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Update blog by id
export const updateBlogApi = async (blogId: string, blogData: any) => {
  const formData = new FormData();
  formData.append('json_data', JSON.stringify(blogData.json_data));
  if (blogData.thumbnail_image) {
    formData.append('thumbnail_image', blogData.thumbnail_image);
  }
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/blogs/${blogId}`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Delete blog by ID
export const deleteBlogApi = async (blogId: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/blogs/${blogId}`
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error("An unexpected error occurred.");
  }
};
