import React from "react";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import ProfileField from "../../../shared/components/profile-field/profileField";
import {Link, useParams} from "react-router-dom";
import {useFetchWebinarDetailsApi} from "../services/useWebinarApi";
import {WebinarInterface} from "../../../core/interface/webinar/WebinarInterface";
import {Avatar, Button, Spinner} from "flowbite-react";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";

const WebinarsDetails: React.FC = () => {
  
  const {id} = useParams<{ id: string }>();
  const {data, isLoading} = useFetchWebinarDetailsApi(id);
  const webinarDetails: WebinarInterface | undefined = data?.data;
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/webinars" label="Back"/>
        <h1 className="text-xl font-bold mb-4">View Webinar</h1>
        <CustomBreadcrumb
          items={[
            {label: "Webinars", to: "/webinars"},
            {label: "Webinar Details"}
          ]}
        />
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
            <div className={`col-span-2 w-full flex flex-col justify-start items-start`}>
              <h3 className="text-xs font-medium text-blue-700 mb-2">Image</h3>
              <Avatar size='lg' img={webinarDetails?.image_url} alt={webinarDetails?.name} rounded/>
            </div>
            <ProfileField label={`Webinar  Title`} value={webinarDetails?.name}/>
            <ProfileField label={`Date and time`}
                          value={dateAndTimeFormatter(webinarDetails?.datetime, 'DD-MM-YYYY,  h:mm a')}/>
            <ProfileField className={`col-span-2 w-full`} label={`Description`} value={webinarDetails?.info}/>
            <ProfileField label={`Host`} value={webinarDetails?.host_name}/>
          </div>
          
          <div className={`flex justify-start`}>
            <Link to={`/webinars/update-webinar/${id}`}>
              <Button color='blue'>
                Edit
              </Button>
            </Link>
          </div>
        
        </div>
      )}
    </div>
  );
};

export default WebinarsDetails;
