import React from 'react';
import './app.scss';
import {AppRouter} from "./router/appRouter";
import {ToastNotification} from "../shared/components/notification-messages/toastNotification";

function App() {

  return(
    <ToastNotification>
      <AppRouter />
    </ToastNotification>
  );
}

export default App;
