import React from "react";
import {FormFieldProps} from "../../../core/interface/FormFieldProps";
import {Label} from "flowbite-react";

const FormTimePicker: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  return (
    <div className={props.className}>
      <Label htmlFor={props.name} className="mb-2 block">
        {props.label}{props.subLabel && (<span className='pl-1 text-blue-600'>{props.subLabel}</span>)}{props.important && (<em>*</em>)}
      </Label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M12 8V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v6H4a1 1 0 0 0-1 1v1h14V9a1 1 0 0 0-1-1h-4Zm5 9a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-2h14v2Zm0-4H3v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3Z"/>
          </svg>
        </div>
        <input
          id={props.name}
          type="time"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
          value={props.value}
          onChange={props.onChangeInput}
          placeholder={props.placeholder}
          disabled={props.disabled}
        />
      </div>
      {props.error && <span className="text-red-600">{props.error}</span>}
    </div>
  );
};

export default FormTimePicker;
