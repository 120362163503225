import {FormFieldProps} from "../../../core/interface/FormFieldProps";
import {Label, Textarea} from "flowbite-react";
import React from "react";

const FormTextarea: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  return (
    <div className={props.className}>
      <Label htmlFor={props.name} className="mb-2 block">
        {props.label}{props.important && (<em>*</em>)}
      </Label>
      <Textarea className={props.subClassName} {...props.register} placeholder={props.placeholder} disabled={props.disabled} />
      {props.error && <span className="text-red-600">{props.error}</span>}
    </div>
  );
};

export default FormTextarea
