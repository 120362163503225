import React, {Suspense} from 'react';
import {BrowserRouter as Router, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {Roles} from "../../core/enums/roles";
import SignIn from "../../features/auth/components/signIn";
import CreateNewPassword from "../../features/auth/components/createNewPassword";
import Dashboard from "../../features/dashboard/components/dashboard";
import ProtectedRoute from "../protectedRoute/protectedRoute";
import Layout from "../../shared/components/layout/layout";
import Staffs from "../../features/users/staffs/components/staffs";
import CreatePlan from "../../features/plans/components/createPlan";
import OtpVerification from "../../features/auth/components/otpVerification";
import Plans from "../../features/plans/components/plans";
import NotFound from "../../core/components/notFound";
import Entities from "../../features/entity/entity/components/entities";
import Customers from "../../features/users/customers/components/customers";
import RoleProtectedRoute from "../protectedRoute/RoleProtectedRoute";
import StaffCreate from "../../features/users/staffs/components/staffCreate";
import PlanDetails from "../../features/plans/components/planDetails";
import StaffDetails from "../../features/users/staffs/components/staffDetails";
import CreateEntity from "../../features/entity/entity/components/createEntity";
import EntityDetails from "../../features/entity/entity/components/entityDetails";
import AddEntityPlan from "../../features/entity/entity-plan/components/addEntityPlan";
import EntityPlans from "../../features/entity/entity-plan/components/entityPlans";
import EntityPlanDetails from "../../features/entity/entity-plan/components/entityPlanDetails";
import UpdateEntityPlanStageDetails from "../../features/entity/entity-plan/components/updateEntityPlanStageDetails";
import EntityPlanCustomers from "../../features/entity/entity-plan/components/entityPlanCustomers";
import CustomerDetails from "../../features/users/customers/components/customerDetails";
import CustomerPlans from "../../features/users/customers/components/customerPlans";
import Queries from "../../features/query/components/queries";
import Webinars from "../../features/webinar/components/webinars";
import QueryDetails from "../../features/query/components/queryDetails";
import CreateWebinar from "../../features/webinar/components/createWebinar";
import WebinarsDetails from "../../features/webinar/components/webinarsDetails";
import IndividualSessions from "../../features/individual-sessions/components/individual-sessions";
import CreateSession from "../../features/individual-sessions/components/createSession";
import AssignApproversRequest from "../../features/assign-approvers/components/assignApproversRequest";
import GroupSessions from "../../features/groupSessions/components/groupSessions";
import AssignApproverDetails from "../../features/assign-approvers/components/assignApproverDetails";
import AssignApprover from "../../features/assign-approvers/components/assignApprover";
import GroupSessionDetails from "../../features/groupSessions/components/groupSessionDetails";
import Vlogs from "../../features/vlog/components/vlogs";
import Blogs from "../../features/blog/components/blogs";
import CreateVlog from "../../features/vlog/components/createVlog";
import VlogDetails from "../../features/vlog/components/vlogDetails";
import CreateBlog from "../../features/blog/components/createBlog";
import BlogDetails from "../../features/blog/components/blogDetails";

export const AppRouter: React.FC = () => {
  
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace/>}/>
          <Route path="/signin" element={<SignIn/>}/>
          <Route path="/email-otp" element={<OtpVerification/>}/>
          <Route path="/create-new-password" element={<CreateNewPassword/>}/>
          
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Layout>
                  <Dashboard/>
                </Layout>
              </ProtectedRoute>
            }/>
          
          <Route
            path="/staffs"
            element={
              <ProtectedRoute>
                <Layout>
                  <Outlet/>
                </Layout>
              </ProtectedRoute>
            }>
            <Route index element={<Staffs/>}/>
            <Route path='view-staff/:id' element={<StaffDetails/>}/>
            <Route path="create-staff" element={<StaffCreate/>}/>
            <Route path="update-staff/:id" element={<StaffCreate/>}/>
          </Route>
          
          <Route
            path="/customers"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Customers/>}/>
            <Route path='view-customer/:id' element={<CustomerDetails/>}/>
            <Route path=':id/plans' element={<CustomerPlans/>}/>
          </Route>
          
          <Route
            path="/plans"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.SUPER_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Plans/>}/>
            <Route path='view-plan/:id' element={<PlanDetails/>}/>
            <Route path="create-plan" element={<CreatePlan/>}/>
          </Route>
          
          <Route
            path="/entities"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Entities/>}/>
            <Route path="create-entity" element={<CreateEntity/>}/>
            <Route path="update-entity/:entityId" element={<CreateEntity/>}/>
            <Route path='view-entity/:entityId' element={<EntityDetails/>}/>
            <Route path=':entityId/entity-plans' element={<EntityPlans/>}/>
            <Route path=':entityId/add-entity-plan' element={<AddEntityPlan/>}/>
            <Route path=':entityId/entity-plans/entity-plan-details/:entityPlanId' element={<EntityPlanDetails/>}/>
            <Route path=':entityId/entity-plans/entity-plan-details/:entityPlanId/update-entity-plan-stage' element={<UpdateEntityPlanStageDetails/>}/>
            <Route path=':entityId/entity-plans/:entityPlanId/entity-plan-customers' element={<EntityPlanCustomers/>}/>
          </Route>
          
          <Route
            path="/individual-sessions"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<IndividualSessions />}/>
            <Route path="create-session" element={<CreateSession/>}/>
          </Route>
          
          <Route
            path="/group-sessions"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<GroupSessions />}/>
            <Route path="group-session-details/:id" element={<GroupSessionDetails />}/>
          </Route>
          
          <Route
            path="/assign-approvers"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<AssignApproversRequest />}/>
            <Route path="assign-approver-details/:id" element={<AssignApproverDetails />}/>
            <Route path="approver-list/:id" element={<AssignApprover />}/>
          </Route>
          
          <Route
            path="/webinars"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Webinars/>}/>
            <Route path='view-webinar/:id' element={<WebinarsDetails/>}/>
            <Route path='create-webinar' element={<CreateWebinar/>}/>
            <Route path='update-webinar/:id' element={<CreateWebinar/>}/>
          </Route>
          
          <Route
            path="/queries"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Queries/>}/>
            <Route path='view-query/:id' element={<QueryDetails/>}/>
          </Route>
          
          <Route
            path="/vlogs"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Vlogs/>}/>
            <Route path='view-vlog/:id' element={<VlogDetails/>}/>
            <Route path='create-vlog' element={<CreateVlog/>}/>
            <Route path='update-vlog/:id' element={<CreateVlog/>}/>
          </Route>
          
          <Route
            path="/blogs"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Blogs/>}/>
            <Route path='view-blog/:id' element={<BlogDetails/>}/>
            <Route path='create-blog' element={<CreateBlog/>}/>
            <Route path='update-blog/:id' element={<CreateBlog/>}/>
          </Route>
          
          {/* NotFound Route */}
          <Route path="/not-found" element={<NotFound/>}/>
          <Route path="*" element={<NotFound/>}/>
        
        </Routes>
      </Suspense>
    </Router>
  );
};
