import React  from "react";
import {ProfileFieldProps} from "../../../core/interface/ProfileFieldProps";
import {Badge} from "flowbite-react";

const DetailField: React.FC<ProfileFieldProps> = (props) => {
  return (
    <div className={props.className}>
      <h3 className="text-xs font-medium text-blue-700 mb-2">{props.label}</h3>
      {Array.isArray(props.value) ? (
        <div className="flex space-x-2 border-b-2 border-blue-500 pb-1">
          {props.value.map((val, index) => (
            <Badge key={index} color="gray" className="py-1 text-sm text-gray-900 font-medium">
              {val}
            </Badge>
          ))}
        </div>
      ) : (
        <p className={`border-b-2 border-blue-500 pb-1 ${props.value ? 'text-gray-900' : 'text-gray-400'}`}>
          {props.value ? props.value : 'Not available'}
        </p>
      )}
    </div>
  );
};

export default DetailField
