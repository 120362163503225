import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";

// Fetch assign Approvers
export const fetchAssignApproversRequestApi = async (page: number, pageSize: number): Promise<CustomResponse<any[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<any[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/approver-request-list`,
    {params: queryParams}
  );
  return response.data;
};

// Fetch assign Approvers details
export const fetchAssignApproverDetailsApi = async (assignApproverId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<any>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/approver-request-list/${assignApproverId}`
  );
  return res.data;
}
