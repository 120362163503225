import React from 'react';
import {Breadcrumb} from 'flowbite-react';
import {Link} from 'react-router-dom';
import {CustomBreadcrumbProps} from "../../../core/interface/CustomBreadcrumbProps";

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = (props: CustomBreadcrumbProps) => {
  return (
    <Breadcrumb>
      {props.items.map((item, index) => {
        const isLastItem = index === props.items.length - 1;
        return (
          <Breadcrumb.Item key={index}>
            {item.to ? (
              <Link
                className={`hover:text-blue-600 hover:underline ${isLastItem ? 'text-gray-400' : 'text-gray-700'}`}
                to={item.to}
                state={item.stateName ? {[item.stateName]: item.stateValue} : undefined}>
                {item.label}
              </Link>
            ) : (
              <span className={isLastItem ? 'text-gray-400' : 'text-gray-700'}>{item.label}</span>
            )}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
