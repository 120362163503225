import {useQuery} from "@tanstack/react-query";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {fetchIndividualSessionsApi} from "./individualSessionsApi";
import {IndividualSessionsInterface} from "../../../core/interface/individualSession/IndividualSessionsInterface";

export const useGetIndividualSessionsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<IndividualSessionsInterface[]>>({
    queryKey: ['individualSessions', {page, pageSize}],
    queryFn: () => fetchIndividualSessionsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};
