import React, {useState} from 'react';
import {Badge, Button, Pagination, Spinner, Table, Tooltip} from 'flowbite-react';
import {Link} from "react-router-dom";
import {Grades} from "../../../core/enums/grades";
import {formatCurrency} from "../../../shared/utils/currencyFormatter";
import {useDisablePlanApi, useGetPlansApi} from "../services/usePlanApi";
import {PlanInterface} from "../../../core/interface/plan/PlanInterface";
import {IoEyeOutline} from "react-icons/io5";
import {MdOutlineBlock} from "react-icons/md";
import ConfirmDialog from "../../../shared/components/modals/confirmDialog";
import {FaCircleExclamation} from "react-icons/fa6";
import DropdownCheckboxFilter from "../../../shared/components/custom-dropdown-box/dropdownCheckboxFilter";

const Plans: React.FC = () => {
  
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [planName, setPlanName] = useState('');
  const [planId, setPlanId] = useState('');
  
  const {data, isLoading} = useGetPlansApi(currentPage, pageSize, selectedOptions);
  const {mutate: disablePlanId} = useDisablePlanApi();
  
  const plans: PlanInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const options = Object.entries(Grades).map(([key, value]) => ({
    label: value.replace(/-/g, ' '),
    value: value,
  }));
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const openConfirmDialog = (plan: PlanInterface) => {
    setPlanName(plan.name);
    setPlanId(plan.id);
    setIsConfirmDialogVisible(true);
  }
  
  const handleConfirmDialogClick = () => {
    disablePlanId(planId, {
      onSettled: () => {
        setIsConfirmDialogVisible(false);
      }
    });
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Plans</h1>
        <div className="flex gap-4">
          <DropdownCheckboxFilter
            title='Grades'
            className='w-48'
            options={options}
            selectedOptions={selectedOptions}
            onChange={setSelectedOptions}
          />
          <Link to="create-plan">
            <Button color="blue">
              Create Plan
            </Button>
          </Link>
        </div>
      </div>
      
      <div className={`overflow-x-auto relative shadow-md sm:rounded-lg`}>
        <div className="overflow-x-auto">
          <Table striped>
            <Table.Head className='border-b border-gray-200'>
              <Table.HeadCell className={`text-gray-500`}>NAME</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>BUSINESS VERTICAL</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>GRADES</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>PRICE</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>ACTIONS</Table.HeadCell>
            </Table.Head>
            {isLoading ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <div className="flex justify-center items-center py-5">
                      <Spinner size="lg"/>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : totalCount === 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <div className="flex justify-center items-center py-5">
                      <div className="tet-center text-gray-500">No plan records found.</div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body className="divide-y">
                {plans.map((plan) => (
                  <Table.Row key={plan.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {plan.name}
                    </Table.Cell>
                    <Table.Cell>{plan.business_vertical}</Table.Cell>
                    <Table.Cell className="flex gap-2">
                      {plan.applicable_grades.map((grade, idx) => (
                        <Badge className={`px-1 text-sm text-gray-600`} key={idx} color="gray">{grade}</Badge>
                      ))}
                    </Table.Cell>
                    <Table.Cell>{formatCurrency(plan.price)}</Table.Cell>
                    <Table.Cell className={`flex items-center space-x-2`}>
                      <Tooltip content="View" placement="bottom">
                        <Link to={`view-plan/${plan.id}`}>
                          <Button color="none" size="xs">
                            <IoEyeOutline size={`20`}/>
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Disable" placement="bottom">
                        <Button onClick={() => openConfirmDialog(plan)} color="none" size="xs">
                          <MdOutlineBlock size={`20`}/>
                        </Button>
                      </Tooltip>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>
        </div>
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
      
      <ConfirmDialog
        submitButtonText={`Confirm`}
        submitButtonClass={`blue`}
        cancelButtonText={`Cancel`}
        showCancelButton={true}
        icon={<FaCircleExclamation className="mx-auto mb-4 h-14 w-14 text-red-700"/>}
        isVisible={isConfirmDialogVisible}
        onClose={() => setIsConfirmDialogVisible(false)}
        onClick={handleConfirmDialogClick}
        title="Disable Plan"
        subTitle={
          <>
            Once a plan is disabled, plan can't be used anymore. Do you really want to disable the plan <span
            className="text-blue-600">"{planName}"</span>?
          </>
        }
      />
    </div>
  );
};

export default Plans;
