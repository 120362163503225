import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import authService from "../../features/auth/api/authService";
import {RoleProtectedRouteProps} from "../../core/interface/RoleProtectedRouteProps";

const RoleProtectedRoute: React.FC<RoleProtectedRouteProps> = (props) => {
  
  const navigate = useNavigate();
  const [userRole, setUserRole] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  
  React.useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const role = await authService.getUserRole();
        setUserRole(role as string);
      } catch (error) {
        await authService.signOut();
        navigate('/signin');
      } finally {
        setLoading(false);
      }
    };
    
    fetchUserRole();
  }, [navigate]);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  // Check if the user's role is in the list of allowed roles
  if (userRole && props.allowedRoles.includes(userRole)) {
    return <>{props.children}</>;
  }
  
  // If the user role is not allowed, redirect to a Not Found page
  return <Navigate to="/not-found" replace />;
};

export default RoleProtectedRoute;
