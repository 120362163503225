import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {WebinarInterface} from "../../../core/interface/webinar/WebinarInterface";

// Fetch webinars
export const fetchWebinarsApi = async (page: number, pageSize: number,): Promise<CustomResponse<WebinarInterface[]>> => {
  const params = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  
  const response = await axios.get<CustomResponse<WebinarInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/webinars`, {params: params},
  );
  return response.data;
};

// Webinar details
export const fetchWebinarDetailsApi = async (webinarId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<WebinarInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/webinar/${webinarId}`);
  return res.data;
}

// Webinar create
export const createWebinarApi = async (webinarData: any) => {
  try {
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(webinarData.json_data));
    if (webinarData.image) {
      formData.append('image', webinarData.image);
    }
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs/webinar`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Update webinar by id
export const updateWebinarApi = async (webinarId: string, webinarData: any) => {
  const formData = new FormData();
  formData.append('json_data', JSON.stringify(webinarData.json_data));
  if (webinarData.image) {
    formData.append('image', webinarData.image);
  }
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/webinar/${webinarId}`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};
