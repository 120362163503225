import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";

// Fetch group sessions
export const fetchGroupSessionsApi = async (page: number, pageSize: number): Promise<CustomResponse<any[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<any[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/group-sessions`,
    { params: queryParams }
  );
  return response.data;
};

// Fetch group session details
export const fetchGroupSessionDetailsApi = async (groupSessionId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<any>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/group-sessions/${groupSessionId}`
  );
  return res.data;
}
