import React from 'react';
import {Button, Spinner} from "flowbite-react";
import {CustomLoaderButtonProps} from "../../../core/interface/CustomLoaderButtonProps";

const CustomLoaderButton: React.FC<CustomLoaderButtonProps> = (props) => {
  
  return (
    <Button
      outline={props.outline}
      size={props.size}
      color={props.color}
      type={props.type}
      className={`tracking-wide text-white rounded-md transition ${props.className}`}
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
    >
      {props.loading ? (
        <>
          <Spinner aria-label="Loading" size="sm"/>
          <span className="pl-3">Loading...</span>
        </>
      ) : (
        props.children
      )}
    </Button>
  );
};

export default CustomLoaderButton;
