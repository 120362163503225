import React, {createContext, useContext, useState, ReactNode} from 'react';
import {Toast} from 'flowbite-react';
import {ToastContextType, ToastMessage} from "../../../core/enums/toastType";

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastNotification: React.FC<{ children: ReactNode }> = ({children}) => {

  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  const showToastMessage = (message: Omit<ToastMessage, 'id'>) => {
    const id = Date.now();
    setToasts([...toasts, {id, ...message}]);

    // Automatically remove the toast after 5 seconds
    setTimeout(() => {
      setToasts(toasts => toasts.filter(toast => toast.id !== id));
    }, 10000);
  };

  return (
    <ToastContext.Provider value={{showToastMessage}}>
      {children}
      <div className="fixed top-0 right-0 p-4 z-50">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            className={`mb-2 p-4 space-x-2 rounded shadow-md ${toast.type === 'success' ? 'bg-green-100 text-green-800' : toast.type === 'error' ? 'bg-red-100 text-red-800' : ''}`}>
            <div className="text-sm font-medium">
              {toast.message}
            </div>
            <Toast.Toggle/>
          </Toast>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('Something went wrong');
  }
  return context;
};
