import React from 'react';
import {Link} from 'react-router-dom';

const CommonFooter: React.FC = () => {
  return (
    <footer className="w-full text-center p-4 text-gray-400">
      &copy; 2024, All Rights Reserved by
      <Link
        to="https://www.merementor.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="underline text-indigo-600 hover:text-indigo-500 ml-1">
        Mere Mentor
      </Link>
    </footer>
  );
};

export default CommonFooter;
