import React, {useState, useRef, useEffect} from 'react';
import {IoChevronDown, IoClose} from 'react-icons/io5';
import {HiOutlineAdjustmentsHorizontal} from "react-icons/hi2";
import {Checkbox} from "flowbite-react";
import {CheckboxFilterProps} from "../../../core/interface/CheckboxFilterProps";

const DropdownCheckboxFilter: React.FC<CheckboxFilterProps> = (props: CheckboxFilterProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  const handleOptionToggle = (value: string) => {
    if (props.selectedOptions.includes(value)) {
      props.onChange(props.selectedOptions.filter((option) => option !== value));
    } else {
      props.onChange([...props.selectedOptions, value]);
    }
  };
  
  const clearFilters = () => {
    props.onChange([]);
  };
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <div className={`relative ${props.className}`} ref={dropdownRef}>
      <div
        className="flex items-center justify-between w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 cursor-pointer"
        onClick={() => setShowDropdown((prev) => !prev)}
      >
        <HiOutlineAdjustmentsHorizontal size={24} className="text-blue-600 mr-2"/>
        <span className="flex-1 text-gray-500">{props.title}</span>
        {props.selectedOptions.length > 0 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              clearFilters();
            }}
            className="text-gray-600 hover:text-red-600"
            title="Clear Filters"
          >
            <IoClose className='text-blue-600'/>
          </button>
        )}
        <IoChevronDown className='text-blue-600'/>
      </div>
      
      {showDropdown && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded shadow-lg">
          {props.options.map((option) => (
            <div
              key={option.value}
              className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleOptionToggle(option.value)}
            >
              <Checkbox
                checked={props.selectedOptions.includes(option.value)}
                readOnly
                className="form-checkbox text-blue-600 mr-2"
              />
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownCheckboxFilter;
