import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createEntity, fetchEntitiesApi, fetchEntityDetailsApi, updateEntityApi} from "./entityApi";
import {useToast} from "../../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../../core/enums/toastType";
import {CustomResponse, DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import {EntityInterface} from "../../../../core/interface/entity/EntityInterface";

export const useGetEntitiesApi = (page: number, pageSize: number, searchQuery?: string, businessModels?: any, entityTypes?: any,) => {
  return useQuery<CustomResponse<EntityInterface[]>>({
    queryKey: ['entities', {page, pageSize, searchQuery, businessModels, entityTypes}],
    queryFn: () => fetchEntitiesApi(page, pageSize, searchQuery, businessModels, entityTypes),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchEntityDetailsApi = (entityId: any) => {
  return useQuery<DetailsCustomDataResponse<EntityInterface>>({
    queryKey: ['entity', {entityId}],
    queryFn: () => fetchEntityDetailsApi(entityId),
    enabled: !!entityId
  })
}

export const useCreateEntityApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (data: any) => createEntity(data),
    
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['entities']});
      const message = response?.message || 'Entity created successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['entities']});
    }
  });
};

export const useUpdateEntityApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: ({entityId, data}: { entityId: string; data: any }) => updateEntityApi(entityId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['entity']});
      const message = response?.message || 'Entity updated successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['entity']});
    }
  });
};
