import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import {EntityInterface} from "../../../../core/interface/entity/EntityInterface";

// Fetch entities
export const fetchEntitiesApi = async (
  page: number,
  pageSize: number,
  searchValue?: string,
  businessModels?: string[],
  entityTypes?: string[]
): Promise<CustomResponse<EntityInterface[]>> => {
  const params = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
    ...(searchValue && { search_name: searchValue }),
  });
  
  businessModels?.forEach(model => params.append("business_models", model));
  entityTypes?.forEach(type => params.append("entity_types", type));
  
  const response = await axios.get<CustomResponse<EntityInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/entities`,
    { params }
  );
  
  return response.data;
};

// Fetch entity details
export const fetchEntityDetailsApi = async (entityId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<EntityInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${entityId}`);
  return res.data;
}

// Create entity
export const createEntity = async (entityData: any) => {
  try {
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(entityData.json_data));
    
    if (entityData.co_branding_logo) {
      formData.append('co_branding_logo', entityData.co_branding_logo);
    }
    
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs/entity`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Update the entity
  export const updateEntityApi = async (entityId: string, entityData: any) => {
    const formData = new URLSearchParams();
    formData.append('json_data', JSON.stringify(entityData));
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${entityId}`, formData);
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data;
      }
      throw new Error('An unexpected error occurred.');
    }
  };

