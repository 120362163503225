import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {PlanInterface} from "../../../core/interface/plan/PlanInterface";

// Fetch plans
export const fetchPlansApi = async (
  page?: number,
  pageSize?: number,
  grades?: string[]
): Promise<CustomResponse<PlanInterface[]>> => {
  const queryParams = new URLSearchParams({
    ...(page !== undefined ? { page_num: page.toString() } : {}),
    ...(pageSize !== undefined ? { size: pageSize.toString() } : {}),
  });
  
  grades?.forEach(grade => queryParams.append("applicable_grades", grade));
  
  const response = await axios.get<CustomResponse<PlanInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/plans`,
    { params: queryParams }
  );
  
  return response.data;
};

// Plan details
export const fetchPlanDetailsApi = async (planId: string | undefined) => {
  const res = await axios.get<DetailsCustomDataResponse<PlanInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}`);
  return res.data;
}

// Create a plan
export const createPlanApi = async (planData: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs/plan`, planData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Plan disable
export const disablePlanApi = async (planId: string) => {
  const params = new URLSearchParams({ status: 'DISABLE' });
  try {
    const response = await axios.patch<CustomResponse<any>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}`,{},{ params });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};


