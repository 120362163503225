import React, {useMemo, useState} from "react";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Button, Pagination, Spinner, Table, Tooltip} from "flowbite-react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {EntityPlanStatus} from "../../../../core/enums/entityPlanStatus";
import DropdownCheckboxFilter from "../../../../shared/components/custom-dropdown-box/dropdownCheckboxFilter";
import {EntityPlanInterface} from "../../../../core/interface/entity/EntityPlanInterface";
import {IoEyeOutline} from "react-icons/io5";
import moment from "moment";
import StatusBadge from "../../../../shared/components/status-badge/statusBadge";
import {useEntityPlansApi} from "../services/useEntityPlanApi";
import {RiShareForwardLine} from "react-icons/ri";


const EntityPlans: React.FC = () => {
  
  const pageSize = 10;
  const location = useLocation();
  const navigate = useNavigate();
  
  const {entityName} = location.state || {};
  const {entityId} = useParams<{ entityId: string }>();
  
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedEntityPlanStatus, setSelectedEntityPlanStatus] = useState<string[]>([]);
  
  const entityPlanStatusOptions = useMemo(() => Object.values(EntityPlanStatus), []);
  
  const {data, isLoading} = useEntityPlansApi(entityId, currentPage, pageSize, selectedEntityPlanStatus);
  const entityPlanList: EntityPlanInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const handlePlanSetup = (entityPlan: EntityPlanInterface) => {
    if (entityPlan?.status === EntityPlanStatus.SETUP_ADD_PLAN_COMPLETED ||
      entityPlan?.status === EntityPlanStatus.SETUP_ADD_CUSTOMER_INPROGRESS) {
      navigate(`/entities/${entityId}/add-entity-plan`, {
        state: {
          entityName,
          currentStep: 2,
          entityPlanId: entityPlan.id
        }
      });
    }
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <div className="mb-4">
            <BackButton to="/entities" label="Back"/>
          </div>
          <h1 className="text-xl font-bold mb-4">Entity Plan</h1>
          <CustomBreadcrumb items={[
            {label: 'Entities', to: '/entities'},
            {label: entityName},
            {label: 'Entity Plans'},
          ]}
          />
        </div>
        <div className='flex gap-4'>
          <DropdownCheckboxFilter
            title='Entity Plan Status'
            className='w-56'
            options={entityPlanStatusOptions.map((option) => ({value: option, label: option.replace(/-/g, ' ')}))}
            selectedOptions={selectedEntityPlanStatus}
            onChange={setSelectedEntityPlanStatus}
          />
          <Link
            to={`/entities/${entityId}/add-entity-plan`}
            state={{entityName}}>
            <Button color="blue">
              Add Plan
            </Button>
          </Link>
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className='border-b border-gray-200'>
            <Table.HeadCell>Plan Name</Table.HeadCell>
            <Table.HeadCell>Plan Type</Table.HeadCell>
            <Table.HeadCell>Start Date</Table.HeadCell>
            <Table.HeadCell>Expiry Date</Table.HeadCell>
            <Table.HeadCell>Customers Count</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Action</Table.HeadCell>
          </Table.Head>
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : entityPlanList?.length === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <div className="flex justify-center items-center py-5">
                    <div className="tet-center text-gray-500">No entity plan records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {entityPlanList?.map((entityPlan, index) => {
                const isSetupCompleted =
                  entityPlan?.status === EntityPlanStatus.SETUP_ADD_PLAN_COMPLETED ||
                  entityPlan?.status === EntityPlanStatus.SETUP_ADD_CUSTOMER_INPROGRESS;
                
                return (
                  <Table.Row key={index} className={`${
                    isSetupCompleted
                      ? "!bg-gray-100 !dark:bg-gray-200"
                      : "bg-white dark:bg-gray-800"
                  } dark:border-gray-700`}>
                    <Table.Cell>{entityPlan?.plan_name}</Table.Cell>
                    <Table.Cell>{entityPlan?.plan_type}</Table.Cell>
                    <Table.Cell>
                      {entityPlan?.start_date ? moment(entityPlan.start_date).format("YYYY-MM-DD") : ""}
                    </Table.Cell>
                    <Table.Cell>
                      {entityPlan?.expiry_date ? moment(entityPlan.expiry_date).format("YYYY-MM-DD") : ""}
                    </Table.Cell>
                    <Table.Cell>
                      {entityPlan?.customers_count > 0 && entityPlan?.status !== EntityPlanStatus.SETUP_ADD_CUSTOMER_INPROGRESS ? (
                        <div className='text-blue-600 underline'>
                          <Link
                            to={`${entityPlan?.id}/entity-plan-customers`}
                            state={{entityName}}>
                            <Button color="none" size="xs">
                              {entityPlan?.customers_count}
                            </Button>
                          </Link>
                        </div>
                      ) : (
                        <div>
                          {entityPlan?.customers_count}
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <div className="flex">
                        {StatusBadge(entityPlan?.status)}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {isSetupCompleted ? (
                        <div
                          className='flex items-center space-x-1 cursor-pointer text-amber-700 font-medium hover:!text-blue-700 hover:underline'
                          onClick={() => handlePlanSetup(entityPlan)}>
                          <span>Complete Setup</span>
                          <RiShareForwardLine size={20}/>
                        </div>
                      ) : (
                        <Tooltip content="View" placement="bottom">
                          <Link
                            to={`entity-plan-details/${entityPlan?.id}`}
                            state={{entityName}}>
                            <Button color="none" size="xs">
                              <IoEyeOutline size={`20`}/>
                            </Button>
                          </Link>
                        </Tooltip>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
        
        {entityPlanList?.length !== 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EntityPlans;
