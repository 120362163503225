import React from "react";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {useParams} from "react-router-dom";
import {useFetchEntityDetailsApi} from "../services/useEntityApi";
import {EntityInterface} from "../../../../core/interface/entity/EntityInterface";
import ProfileField from "../../../../shared/components/profile-field/profileField";
import EntityAdminView from "../../../users/entity-admin/components/entity-admin-view/entityAdminView";
import {Avatar, Spinner} from "flowbite-react";


const EntityDetails: React.FC = () => {
  
  const {entityId} = useParams<{ entityId: string }>();
  const {data, isLoading} = useFetchEntityDetailsApi(entityId);
  
  const entityDetails: EntityInterface | undefined = data?.data;
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/entities" label="Back"/>
        <h1 className="text-xl font-bold mb-4">View Entity</h1>
        <CustomBreadcrumb
          items={[
            {label: "Entities", to: "/entities"},
            {label: "View Entity"},
          ]}
        />
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center py-10">
          <Spinner size="lg"/>
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
            <ProfileField label={`Entity name`} value={entityDetails?.name}/>
            <ProfileField label={`Business model`} value={entityDetails?.business_model}/>
            <ProfileField label={`Entity type`} value={entityDetails?.type}/>
            <ProfileField label={`Region`} value={entityDetails?.region}/>
            <ProfileField label={`Address`} value={entityDetails?.address}/>
            <ProfileField label={`City`} value={entityDetails?.city}/>
            <ProfileField label={`State`} value={entityDetails?.state}/>
            <ProfileField label={`Pincode`} value={entityDetails?.pincode}/>
            <ProfileField label={`Phone`} value={entityDetails?.phone_number}/>
            <ProfileField label={`Email`} value={entityDetails?.email}/>
          </div>
          
          {entityDetails?.co_branding && (
            <div>
              <h2 className={`font-bold text-base mb-4 border-b pb-4`}>Co-Branding Details</h2>
              <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
                <ProfileField label={`Tagline`} value={entityDetails?.co_branding?.tagline}/>
                <ProfileField label={`Description`} value={entityDetails?.co_branding?.description}/>
                <div className='flex flex-col'>
                  <h3 className="text-xs font-medium text-blue-700 mb-2">Entity logo</h3>
                  <div className='flex'>
                    <Avatar
                      img={entityDetails?.co_branding.logo_url}
                      alt="Profile Avatar"
                      size="xl"
                      className="rounded-md border-2 border-gray-500 p-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {entityDetails?.entity_admin && (
            <EntityAdminView title='Admin Details' entityAdmin={entityDetails?.entity_admin}/>
          )}
        </div>
      )}
    </div>
  );
};

export default EntityDetails;
