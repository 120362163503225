import React, {useMemo, useState} from "react";
import DropdownCheckboxFilter from "../../../../shared/components/custom-dropdown-box/dropdownCheckboxFilter";
import {BusinessVertical} from "../../../../core/enums/businessVertical";
import SearchInputBox from "../../../../shared/components/custom-search-box/searchInputBox";
import {Button, Pagination, Spinner, Table, ToggleSwitch, Tooltip} from "flowbite-react";
import {Link} from "react-router-dom";
import {IoEyeOutline} from "react-icons/io5";
import {EntityType} from "../../../../core/enums/entityType";
import {CustomerInterface, CustomerRequest} from "../../../../core/interface/customer/CustomerInterface";
import {useDisableCustomerApi, useGetCustomersApi} from "../services/useCustomerApi";
import {FaCircleExclamation} from "react-icons/fa6";
import ConfirmDialog from "../../../../shared/components/modals/confirmDialog";
import {DisableRequest} from "../../../../core/interface/DisableRequest";
import DropdownRadioFilter from "../../../../shared/components/custom-dropdown-box/dropdownRadioFilter";

const Customers: React.FC = () => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedBusinessVerticalOptions, setSelectedBusinessVerticalOptions] = useState<string[]>([]);
  const [selectedEntityTypeOptions, setSelectedEntityTypeOptions] = useState<string[]>([]);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  
  const filteredSearchQuery = searchQuery.length > 2 ? searchQuery : '';
  const businessVerticalOptions = useMemo(() => Object.values(BusinessVertical), []);
  const entityTypeOptions = useMemo(() => Object.values(EntityType), []);
  
  const customerRequest: CustomerRequest = {
    currentPage,
    pageSize,
    filteredSearchQuery,
    selectedBusinessVerticalOptions,
    selectedEntityTypeOptions,
  }
  const {data, isLoading} = useGetCustomersApi(customerRequest);
  const {mutate: disableCustomer} = useDisableCustomerApi();
  
  const customersList: CustomerInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  
  const openConfirmDialog = (customer: CustomerInterface) => {
    setLoading(false);
    setSelectedCustomer(customer);
    setIsConfirmDialogVisible(true);
  }
  
  const handleConfirmDialogClick = () => {
    setLoading(true);
    const data: DisableRequest = {
      id: selectedCustomer?.id,
      reason: '',
      status: 'DISABLE',
    }
    
    disableCustomer({data}, {
      onSuccess: () => {
        setLoading(false);
      },
      onSettled: () => {
        setLoading(true);
        setIsConfirmDialogVisible(false);
      }
    })
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-semibold">Customers (B2B & B2C)</h1>
        <div className="flex gap-4">
          <DropdownRadioFilter
            title='Business vertical'
            className='w-52'
            options={businessVerticalOptions.map((option) => ({value: option, label: option.replace(/-/g, ' ')}))}
            selectedOptions={selectedBusinessVerticalOptions}
            onChange={setSelectedBusinessVerticalOptions}
          />
          <DropdownCheckboxFilter
            title='Entity type'
            className='w-48'
            options={entityTypeOptions.map((option) => ({value: option, label: option.replace(/-/g, ' ')}))}
            selectedOptions={selectedEntityTypeOptions}
            onChange={setSelectedEntityTypeOptions}
          />
          <SearchInputBox
            className='w-80'
            placeholderName='Search Name, Entity Name...'
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
          />
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className='border-b border-gray-200'>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Business Vertical</Table.HeadCell>
            <Table.HeadCell>Entity Type</Table.HeadCell>
            <Table.HeadCell>Entity Name</Table.HeadCell>
            <Table.HeadCell>Plans</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : totalCount === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <div className="flex justify-center items-center py-5">
                    <div className="tet-center text-gray-500">No customer records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {customersList?.map((customer) => (
                <Table.Row key={customer.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 flex items-center space-x-3">
                    <div>
                      <div className="font-bold">{customer.first_name} {customer.last_name}</div>
                      <div className="text-sm text-gray-500">Enrollment No. {customer.enrollment_number}</div>
                    </div>
                  </Table.Cell>
                  
                  <Table.Cell>{customer.business_vertical}</Table.Cell>
                  <Table.Cell>{customer.entity_type}</Table.Cell>
                  <Table.Cell>{customer.entity_name}</Table.Cell>
                  <Table.Cell className={customer.plans_count > 0 ? 'text-blue-600 underline' : 'text-gray-500 underline-offset-0'}>
                    {customer.plans_count > 0 ?
                      (
                        <Link
                          to={`${customer.id}/plans`}
                          state={{ customerName: `${customer.first_name} ${customer.last_name}` }}>
                          {customer.plans_count}
                        </Link>
                      ) :
                      (
                        customer.plans_count
                      )}
                  </Table.Cell>
                  <Table.Cell>
                    <ToggleSwitch
                      disabled={!customer?.is_active}
                      checked={customer.is_active}
                      label={customer.is_active ? "Block" : "Unblock"}
                      onChange={() => openConfirmDialog(customer)}
                    />
                  </Table.Cell>
                  <Table.Cell className="flex items-center space-x-2">
                    <Tooltip content="View" placement="bottom">
                      <Link to={`view-customer/${customer.id}`}>
                        <Button color="none" size="xs">
                          <IoEyeOutline size={`20`}/>
                        </Button>
                      </Link>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        
        </Table>
        
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
      
      <ConfirmDialog
        title="Delete Customer"
        submitButtonText={`Confirm`}
        submitButtonClass={`blue`}
        cancelButtonText={`Cancel`}
        showCancelButton={true}
        icon={<FaCircleExclamation className="mx-auto mb-4 h-14 w-14 text-red-700"/>}
        isVisible={isConfirmDialogVisible}
        loading={loading}
        onClose={() => setIsConfirmDialogVisible(false)}
        onClick={handleConfirmDialogClick}
        subTitle={
          <>
            Once a customer is disabled, plan can't be used anymore. Do you really want to disable the customer <span
            className="text-blue-600">"{selectedCustomer?.first_name} {selectedCustomer?.last_name}"</span>?
          </>
        }
      />
    </div>
  );
};

export default Customers;
