import React from "react";
import { FormFieldProps } from "../../../core/interface/FormFieldProps";
import { Label } from "flowbite-react";

const FormDatePicker: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  // Ref to access the input element
  const inputRef = React.useRef<HTMLInputElement>(null);
  
  // Handler to focus on the input when clicking on the input container
  const handleFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  return (
    <div className={props.className}>
      <Label htmlFor={props.name} className="mb-2 block">
        {props.label}
        {props.subLabel && (
          <span className="pl-1 text-blue-600">{props.subLabel}</span>
        )}
        {props.important && <em>*</em>}
      </Label>
      <div className="relative" onClick={handleFocusInput}>
        <input
          ref={inputRef} // Attach the ref to the input element
          id={props.name}
          type="date"
          {...props.register}
          value={props.value}
          onChange={props.onChangeInput}
          placeholder={props.placeholder}
          disabled={props.disabled}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
      </div>
      {props.error && <span className="text-red-600">{props.error}</span>}
    </div>
  );
};

export default FormDatePicker;
