import {BsFillPatchCheckFill} from "react-icons/bs";
import {StepperProps} from "../../../core/interface/StepperProps";

const Stepper = (props: StepperProps) => {
  return (
    <div className="flex justify-between items-center mb-6">
      {props.steps.map((step, index) => (
        <div key={index} className="flex justify-between items-center w-full">
          <div className={'flex  items-center'}>
            <BsFillPatchCheckFill
              className={index <= props.currentStep ? "text-blue-600 h-5 max-w-full" : "hidden text-gray-300"}
            />
            <span
              className={index <= props.currentStep ? 'text-blue-700 font-semibold ml-2' : 'text-gray-500 font-semibold ml-2'}>
            {step}
          </span>
          </div>
          
          {index < props.steps.length - 1 && (
            <div className="flex-1 h-0.5 bg-gray-300 mx-4 w-full"></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
