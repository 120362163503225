import React, {useEffect} from 'react';
import {Button, Modal, Select, TextInput} from 'flowbite-react';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {StageType} from "../../../core/enums/stageType";
import {AddStageModalProps, StageForm} from "../../../core/interface/StageFormFieldProps";
import {HostUserType, SessionAudience, SessionMode} from "../../../core/enums/sessionMode";


const validationSchema = (stageType: string) => {
  return yup.object().shape({
    name: yup.string().required('Name is required'),
    type: yup.string().required('Type is required'),
    sessionName: stageType.toLowerCase().includes('session') ? yup.string().required('Session name is required') : yup.string(),
    sessionMode: stageType.toLowerCase().includes('session') ? yup.string().required('Session Mode is required') : yup.string(),
    sessionAudience: stageType.toLowerCase().includes('session') ? yup.string().required('Session Audience is required') : yup.string(),
    sessionDuration: stageType.toLowerCase().includes('session')
      ? yup.number().typeError('Session Duration must be a number').required('Session Duration is required')
      : yup.number().nullable(),
    hostUserType: stageType.toLowerCase().includes('session') ? yup.string().required('HostUser is required') : yup.string(),
  });
}

export const AddStageModal: React.FC<AddStageModalProps> = (props: AddStageModalProps) => {
  
  const [stageType, setStageType] = React.useState<string>('');
  const [isSession, setIsSession] = React.useState(false);
  
  const {control, handleSubmit, reset, formState: {errors}} = useForm<StageForm>({
    resolver: yupResolver(validationSchema(stageType)),
    defaultValues: props.stageData,
  });
  
  const onChangeHandleStageType = (value: string) => {
    setStageType(value);
    if (value.toLowerCase().includes('session')) {
      setIsSession(true);
    } else {
      setIsSession(false);
    }
  }
  
  useEffect(() => {
    setIsSession(!!props.stageData?.sessionName);
    reset(props.stageData);
  }, [props.stageData, reset]);
  
  const onSubmit = (data: StageForm) => {
    props.onSave(data);
  };
  
  return (
    <Modal show={props.isOpen} onClose={props.onClose} size="md">
      <div className="flex justify-center pt-4">
        <span className="text-lg font-semibold">Add/Edit Stage Details</span>
      </div>
      
      <Modal.Body>
        <div className={`border-b border-blue-500 font-medium p-2 mb-2`}>
          <h2 className={`text-blue-700 text-sm`}>Stage Details</h2>
        </div>
        <div className="space-y-2">
          <div>
            <Controller
              control={control}
              name="name"
              render={({field}) => (
                <TextInput
                  sizing="sm"
                  id="stageName"
                  type="text"
                  placeholder="Stage name"
                  {...field}
                />
              )}
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
          </div>
          <div>
            <Controller
              control={control}
              name="type"
              render={({field}) => (
                <Select
                  sizing="sm"
                  id="type"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    onChangeHandleStageType(e.target.value);
                  }}
                >
                  <option value="" disabled>Select stage type</option>
                  {Object.values(StageType).map((stage) => (
                    <option key={stage} value={stage}>
                      {stage.replace(/-/g, ' ')}
                    </option>
                  ))}
                </Select>
              )}
            />
            {errors.type && <p className="text-red-500 text-sm">{errors.type.message}</p>}
          </div>
        </div>
        {isSession && (
          <div>
            <div className={`border-b border-blue-500 font-medium p-2 mb-2`}>
              <h2 className={`text-blue-700 text-sm`}>Session Details</h2>
            </div>
            <div className="space-y-2">
              <div>
                <Controller
                  control={control}
                  name="sessionName"
                  render={({field}) => (
                    <TextInput
                      sizing="sm"
                      id="sessionName"
                      type="text"
                      placeholder="Session name"
                      {...field}
                    />
                  )}
                />
                {errors.sessionName && <p className="text-red-500 text-sm">{errors.sessionName.message}</p>}
              </div>
              <div>
                <Controller
                  control={control}
                  name="sessionMode"
                  render={({field}) => (
                    <Select
                      sizing="sm"
                      id="sessionMode"
                      {...field}
                    >
                      <option value="" disabled>Select session mode type</option>
                      {Object.values(SessionMode).map((mode) => (
                        <option key={mode} value={mode}>
                          {mode.replace(/-/g, ' ')}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                {errors.sessionMode && <p className="text-red-500 text-sm">{errors.sessionMode.message}</p>}
              </div>
              <div>
                <Controller
                  control={control}
                  name="sessionAudience"
                  render={({field}) => (
                    <Select
                      sizing="sm"
                      id="sessionAudience"
                      {...field}
                    >
                      <option value="" disabled>Select session audience type</option>
                      {Object.values(SessionAudience).map((audience) => (
                        <option key={audience} value={audience}>
                          {audience.replace(/-/g, ' ')}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                {errors.sessionAudience && <p className="text-red-500 text-sm">{errors.sessionAudience.message}</p>}
              </div>
              <div>
                <Controller
                  control={control}
                  name="sessionDuration"
                  render={({ field }) => (
                    <TextInput
                      sizing="sm"
                      id="sessionDuration"
                      addon={'Minutes'}
                      type="number"
                      placeholder="Enter duration"
                      value={field.value ?? ''}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
                {errors?.sessionDuration?.message && <p className="text-red-500 text-sm">{errors?.sessionDuration?.message}</p>}
              </div>
              <div>
                <Controller
                  control={control}
                  name="hostUserType"
                  render={({field}) => (
                    <Select
                      sizing="sm"
                      id="hostUserType"
                      {...field}
                    >
                      <option value="" disabled>Select host user type</option>
                      {Object.values(HostUserType).map((hostUserType) => (
                        <option key={hostUserType} value={hostUserType}>
                          {hostUserType.replace(/-/g, ' ')}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                {errors.hostUserType?.message && <p className="text-red-500 text-sm">{errors.hostUserType.message}</p>}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      
      <Modal.Footer className="justify-center">
        <Button color={`blue`} onClick={handleSubmit(onSubmit)}>Save</Button>
        <Button color="gray" onClick={props.onClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
