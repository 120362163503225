import {
  StaffInterface,
  StaffsRequestParams
} from "../../../../core/interface/staff/StaffInterface";
import {CustomResponse, DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import axios from "axios";
import {DisableRequest} from "../../../../core/interface/DisableRequest";

// Staff list
export const fetchStaffsApi = async (staffRequest: StaffsRequestParams): Promise<CustomResponse<StaffInterface[]>> => {
  const { currentPage, pageSize, filteredSearchQuery, selectedRolesOptions } = staffRequest;
  
  const params = new URLSearchParams({
    ...(currentPage && { page_num: currentPage.toString() }),
    ...(pageSize && { size: pageSize.toString() }),
    ...(filteredSearchQuery && { search_key: filteredSearchQuery })
  });
  
  selectedRolesOptions?.forEach(role => {
    params.append("role_filter", role);
  });
  
  const response = await axios.get<CustomResponse<StaffInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs`,
    { params }
  );
  
  return response.data;
};

// Staff details
export const fetchStaffDetailsApi = async (staffId: string | undefined) => {
  const res = await axios.get<DetailsCustomDataResponse<StaffInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/${staffId}`);
  return res.data;
}

// Staff create
export const createStaffApiApi = async (staffData: any) => {
  try {
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(staffData.json_data));
    if (staffData.image) {
      formData.append('image', staffData.image);
    }
    if (staffData.video) {
      formData.append('video', staffData.video);
    }
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Update staff by staff id
export const updateStaffApi = async (staffId: string, staffData: any) => {
  const formData = new FormData();
  formData.append('json_data', JSON.stringify(staffData.json_data));
  if (staffData.image) {
    formData.append('image', staffData.image);
  }
  if (staffData.video) {
    formData.append('video', staffData.video);
  }
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/${staffId}`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Disable staff by staff id
export const disableStaffApi = async (disableData: DisableRequest) => {
  const data = { disable_reason: disableData.reason };
  const params = { status: disableData.status };
  
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/${disableData.id}`, data, { params }
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};
