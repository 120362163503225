import React, {useMemo, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Modal} from "flowbite-react";
import {useForm} from "react-hook-form";
import FormInput from "../../../../../shared/components/form-wrapper/FormInput";
import CustomLoaderButton from "../../../../../shared/components/custom-buttons/customLoaderButton";
import {Gender} from "../../../../../core/enums/Gender";
import FormSelect from "../../../../../shared/components/form-wrapper/FormSelect";
import {genderLabels} from "../../../../../shared/utils/custom-lavel/genderLabels";
import {AddEntityAdminProps, AddEntityAdminRequest} from "../../../../../core/interface/entity-admin/AddEntityAdminProps";
import {useCreateEntityAdminApi} from "../../services/useEntityAdminApi";
import {useNavigate} from "react-router-dom";

const entityAdminValidationSchema = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  gender: yup.string().required("Gender is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  phone_number: yup.string().required("Phone number is required"),
});

const AddEntityAdminFormDialog: React.FC<AddEntityAdminProps> = (props: AddEntityAdminProps) => {
  
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  
  const genderOptions = useMemo(() => {
    return Object.values(Gender).map(option => ({
      value: option,
      label: genderLabels[option],
    }));
  }, []);
  
  const {mutate: createEntityAdmin} = useCreateEntityAdminApi();
  const {
    register: registerEntityAdmin,
    handleSubmit: handleSubmitEntityAdmin,
    formState: {errors: EntityAdminErrors},
    reset
  } = useForm({
    resolver: yupResolver(entityAdminValidationSchema),
  });
  
  const onSubmitAdminForm = async (data: any) => {
    if (!data.phone_number.startsWith("+91")) {
      data.phone_number = `+91${data.phone_number}`;
    }
    setLoading(true);
      const entityAdminFormRequest: AddEntityAdminRequest = {
        entityId: props.entityId,
        entityAdminData: data
      };
      createEntityAdmin(entityAdminFormRequest, {
        onSuccess: () => {
          navigate('/entities');
          props.onClose?.();
        },
        onError: (error: any) => {
          setLoading(false);
        },
        onSettled: () => {
          setLoading(false);
        },
      });
  };
  
  const handleCancel = () => {
    reset();
    props.onClose?.();
  };
  
  return (
    <Modal dismissible show={props.isOpen} onClose={props.onClose} size="md">
      <div className="flex justify-center pt-4">
        <span className="text-lg font-semibold">Add Admin</span>
      </div>
      
      <Modal.Body>
        <form onSubmit={handleSubmitEntityAdmin(onSubmitAdminForm)}>
          <div className='flex flex-col space-y-2 mb-6'>
            <FormInput
              className="w-full"
              label="First name"
              important={true}
              register={registerEntityAdmin("first_name")}
              error={EntityAdminErrors.first_name?.message}
              placeholder="Enter first name"
            />
            <FormInput
              label="Last name"
              important={true}
              register={registerEntityAdmin("last_name")}
              error={EntityAdminErrors.last_name?.message}
              placeholder="Enter last name"
            />
            <FormSelect
              className='w-full'
              label="Gender"
              important={true}
              options={genderOptions}
              register={registerEntityAdmin("gender")}
              disabled={loading}
              error={EntityAdminErrors.gender?.message}
            />
            <FormInput
              label="Email"
              important={true}
              register={registerEntityAdmin("email")}
              error={EntityAdminErrors.email?.message}
              placeholder="Enter email address"
            />
            <FormInput
              label="Phone number"
              important={true}
              register={registerEntityAdmin("phone_number")}
              error={EntityAdminErrors.phone_number?.message}
              placeholder="Enter phone number"
            />
          </div>
          <div className="flex justify-center space-x-2">
            <Button size='lg' color="gray" onClick={handleCancel} type="button">
              Cancel
            </Button>
            <CustomLoaderButton
              size="lg"
              color="blue"
              type="submit"
              loading={loading}>
              Add Entity Admin
            </CustomLoaderButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEntityAdminFormDialog;
