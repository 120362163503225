import React, {useState} from "react";
import {useDeleteVlogApi, useGetVlogsApi} from "../services/useVlogsApi";
import {Link} from "react-router-dom";
import {Button, Pagination, Spinner, Table, Tooltip} from "flowbite-react";
import {FaCircleExclamation, FaPlus} from "react-icons/fa6";
import {IoEyeOutline} from "react-icons/io5";
import {FiEdit} from "react-icons/fi";
import {VlogInterface} from "../../../core/interface/vlog/VlogInterface";
import {FaPlay} from "react-icons/fa";
import ConfirmDialog from "../../../shared/components/modals/confirmDialog";
import {RiDeleteBin5Line} from "react-icons/ri";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";

const Vlogs: React.FC = () => {
  
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [vlogId, setVlogId] = useState('');
  const [vlogTitle, setVlogTitle] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  
  const { mutate: deleteVlog } = useDeleteVlogApi();
  const {data, isLoading} = useGetVlogsApi(currentPage, pageSize);
  const vlogs: VlogInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const openConfirmDialog = (vlog: VlogInterface) => {
    setIsConfirmDialogVisible(true);
    setVlogId(vlog.id);
    setVlogTitle(vlog.title);
  }
  
  const handleConfirmDialogClick = () => {
    setLoading(true);
    deleteVlog(vlogId, {
      onSettled: () => {
        setLoading(false);
        setIsConfirmDialogVisible(false);
      }
    });
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-semibold">Vlogs</h1>
        <div className="flex gap-4">
          <Link to="create-vlog">
            <Button color="blue">
              <span className={`flex items-center`}>
                <FaPlus className={`mr-2`}/> Add Vlogs
              </span>
            </Button>
          </Link>
        </div>
      </div>
      
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className="border-b border-gray-200">
            <Table.HeadCell className="text-gray-500">Thumbnails</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Video Title</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Description</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Date and time</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Actions</Table.HeadCell>
          </Table.Head>
          
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : totalCount === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <div className="text-center text-gray-500">No query records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {vlogs.map((vlog) => (
                <Table.Row key={vlog.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>
                    <div className={`relative w-32`}>
                      <img
                        src={vlog?.thumbnail_image_url}
                        alt={vlog?.title}
                        className="w-32 h-20 object-cover rounded-md"
                      />
                      <div className="flex items-center space-x-2 absolute bottom-0 right-0">
                        <div className="flex justify-center items-center bg-white rounded-full w-10 h-10">
                          <FaPlay color="#1A56DB"/>
                        </div>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {vlog.title.length > 20 ? `${vlog.title.substring(0, 20)}...` : vlog.title}
                  </Table.Cell>
                  <Table.Cell>
                    {vlog.desc.length > 50 ? `${vlog.desc.substring(0, 50)}...` : vlog.desc}
                  </Table.Cell>
                    <Table.Cell>
                      {dateAndTimeFormatter(vlog?.created_at, 'DD-MM-YYYY, h:mm a')}
                    </Table.Cell>
                  <Table.Cell>
                    <div className="flex items-center space-x-2">
                      <Tooltip content="View" placement="bottom">
                        <Link to={`view-vlog/${vlog.id}`}>
                          <Button color="none" size="xs">
                            <IoEyeOutline size={`20`}/>
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Edit" placement="bottom">
                        <Link to={`update-vlog/${vlog.id}`}>
                          <Button color="none" size="xs">
                            <FiEdit size="20"/>
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Delete" placement="bottom">
                        <Button color="none" size="xs" onClick={() => openConfirmDialog(vlog)}>
                          <RiDeleteBin5Line size="20"/>
                        </Button>
                      </Tooltip>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
        
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing{" "}
              <span className="font-semibold text-black">
                {pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}
              </span>{" "}of <span className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons
            />
          </div>
        )}
      </div>
      
      <ConfirmDialog
        submitButtonText={`Confirm`}
        submitButtonClass={`blue`}
        cancelButtonText={`Cancel`}
        showCancelButton={true}
        loading={loading}
        icon={<FaCircleExclamation className="mx-auto mb-4 h-14 w-14 text-red-700"/>}
        isVisible={isConfirmDialogVisible}
        onClose={() => setIsConfirmDialogVisible(false)}
        onClick={handleConfirmDialogClick}
        title="Delete Vlog"
        subTitle={
          <>
            Once a vlog is delete, vlog can't be used anymore. Do you really want to delete the vlog <span
            className="text-blue-600">{vlogTitle}</span>?
          </>
        }
      />
    
    </div>
  );
};

export default Vlogs;
