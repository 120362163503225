export enum ToastType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

export type ToastMessage = {
  id: number;
  type: ToastType;
  message: string;
};

export type ToastContextType = {
  showToastMessage: (message: Omit<ToastMessage, 'id'>) => void;
};
