import React from 'react';
import { DashboardCardProps } from "../../../../core/interface/dashboard/DashboardCardProps";

const DashboardCard: React.FC<DashboardCardProps> = (props) => {
  return (
    <div className="border rounded-lg p-6 shadow-md bg-white">
      <div className="flex items-center mb-4 text-gray-500">
        {props.icon}
      </div>
      <div className="text-sm text-gray-500">
        {props.description}
      </div>
      <div className="text-2xl font-bold text-gray-900">
        {props.mainText}
      </div>
    </div>
  );
};

export default DashboardCard;
