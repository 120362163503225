import axios from "axios";
import {DisableRequest} from "../../../../core/interface/DisableRequest";
import {AddEntityAdminRequest} from "../../../../core/interface/entity-admin/AddEntityAdminProps";

// Create entity admin
export const createEntityAdminApi = async (entityAdminData: AddEntityAdminRequest) => {
  try {
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(entityAdminData.entityAdminData));
    
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${entityAdminData.entityId}/admin`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Disable entity admin by admin id
export const disableEntityAdminApi = async (disableData: DisableRequest) => {
  const data = {disable_reason: disableData.reason};
  const params = {status: disableData.status};
  
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/admin/${disableData.id}`, data, {params}
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};
