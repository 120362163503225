import React from "react";
import {Link, useParams} from "react-router-dom";
import {VlogInterface} from "../../../core/interface/vlog/VlogInterface";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Avatar, Button, Spinner} from "flowbite-react";
import ProfileField from "../../../shared/components/profile-field/profileField";
import {useFetchVlogDetailsApi} from "../services/useVlogsApi";
import {FaPlay} from "react-icons/fa";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";

const VlogDetails: React.FC = () => {
  
  const {id} = useParams<{ id: string }>();
  const {data, isLoading} = useFetchVlogDetailsApi(id);
  const vlogDetails: VlogInterface | undefined = data?.data;
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/vlogs" label="Back"/>
        <h1 className="text-xl font-bold mb-4">View Vlog</h1>
        <CustomBreadcrumb
          items={[
            {label: "Vlogs", to: "/vlogs"},
            {label: "Vlog Details"}
          ]}
        />
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
            <div className={`col-span-2 w-full flex flex-col justify-start items-start`}>
              <h3 className="text-xs font-medium text-blue-700 mb-2">Video Thumbnail</h3>
              <div className='relative'>
                <Avatar size='xl' img={vlogDetails?.thumbnail_image_url} alt={vlogDetails?.title} rounded/>
                <div className="flex items-center space-x-2 absolute bottom-2 right-2">
                  <div className="flex justify-center items-center bg-white rounded-full w-10 h-10">
                    <FaPlay color="#1A56DB"/>
                  </div>
                </div>
              </div>
            </div>
            <ProfileField className={`col-span-2 w-full`} label={`Video  Title`} value={vlogDetails?.title}/>
            <ProfileField className={`col-span-2 w-full`} label={`Description`} value={vlogDetails?.desc}/>
            <ProfileField className={`col-span-2 w-full`} label={`Date and time`}
                          value={dateAndTimeFormatter(vlogDetails?.created_at, 'DD-MM-YYYY, h:mm a')}/>
          </div>
          
          <div className={`flex justify-start`}>
            <Link to={`/vlogs/update-vlog/${id}`}>
              <Button color='blue'>
                Edit
              </Button>
            </Link>
          </div>
        
        </div>
      )}
    </div>
  );
};

export default VlogDetails;
