import React, {useEffect, useMemo, useState} from "react";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {useLocation, useParams} from "react-router-dom";
import Stepper from "../../../../shared/components/custom-stepper/customStepper";
import CreateEntityPlanStep from "./create-entity-plan-steps/createEntityPlanStep";
import UploadCustomerCsvStep from "./create-entity-plan-steps/uploadCustomerCsvStep";
import ChoosePlanStep from "./create-entity-plan-steps/choosePlanStep";
import {PlanInterface} from "../../../../core/interface/plan/PlanInterface";

const STEPS = [
  'Step 1: Choose plan',
  'Step 2: Add plan',
  'Step 3: Upload customer (CSV)'
];

const AddEntityPlan: React.FC = () => {
  const steps = useMemo(() => STEPS, []);
  
  const { entityId } = useParams<{ entityId: string }>();
  const { entityName, currentStep: locationStep, entityPlanId: locationEntityPlanId } = useLocation().state || {};
  
  const [entityPlanId, setEntityPlanId] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<PlanInterface | null>(null);
  const [currentStep, setCurrentStep] = useState(locationStep !== undefined ? locationStep : 0);
  
  useEffect(() => {
    if (locationEntityPlanId) {
      setEntityPlanId(locationEntityPlanId);
    }
  }, [locationEntityPlanId]);
  
  const goToNextStep = () => {
    setCurrentStep((prevStep: number) => Math.min(prevStep + 1, STEPS.length - 1));
  };
  
  const backToPrevStep = () => {
    setCurrentStep((prevStep: number) => Math.max(prevStep - 1, 0)); // Ensure it does not go below zero
  };
  
  const handlePlanSelection = (plan: PlanInterface) => {
    setSelectedPlan(plan);
  };
  
  const handleEntityPlanId = (id: string) => {
    setEntityPlanId(id);
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-4 px-4">
        <div>
          <BackButton
            label="Back"
            to={`/entities/${entityId}/entity-plans`}
            stateName="entityName"
            stateValue={entityName}
          />
          <h1 className="text-xl font-bold mb-4">Add Entity Plan</h1>
          <CustomBreadcrumb
            items={[
              { label: "Entities", to: "/entities" },
              { label: entityName },
              {
                label: "Entity Plans",
                to: `/entities/${entityId}/entity-plans`,
                stateName: "entityName",
                stateValue: entityName,
              },
              { label: "Add Entity Plan" },
            ]}
          />
        </div>
      </div>
      <div className="flex flex-col overflow-x-auto relative shadow-md sm:rounded-lg px-10 py-4">
        <div className='px-6 pt-6'>
          <Stepper currentStep={currentStep} steps={steps} />
        </div>
        {currentStep === 0 && (
          <ChoosePlanStep
            goToNextStep={goToNextStep}
            onSelectPlan={handlePlanSelection}
          />
        )}
        {currentStep === 1 && (
          <CreateEntityPlanStep
            onEntityPlanId={handleEntityPlanId}
            goToNextStep={goToNextStep}
            selectedPlan={selectedPlan}
            backToPrevStep={backToPrevStep}
          />
        )}
        {currentStep === 2 && (
          <UploadCustomerCsvStep
            entityPlanId={entityPlanId}
            goToNextStep={goToNextStep}
          />
        )}
      </div>
    </div>
  );
};

export default AddEntityPlan;
