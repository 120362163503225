import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import authService from "../../features/auth/api/authService";
import { Roles } from "../../core/enums/roles";
import { useToast } from "../../shared/components/notification-messages/toastNotification";
import { ToastType } from "../../core/enums/toastType";

const ALLOWED_ROLES = Object.values(Roles);

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { showToastMessage } = useToast();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const session = await authService.currentSessionUser();
        const userRole = session?.tokens?.idToken?.payload['custom:user_role'];
        
        if (session.tokens?.idToken?.toString() && userRole) {
          if (ALLOWED_ROLES.includes(userRole as Roles)) {
            setIsAuthenticated(true);
          } else if (!hasError) {
            await authService.signOut();
            showToastMessage({ type: ToastType.Error, message: 'You are not authorized to access.' });
            setHasError(true);
          }
        } else if (!hasError) {
          setHasError(true);
        }
      } catch {
        if (!hasError) {
          setHasError(true);
        }
      } finally {
        setLoading(false);
      }
    };
    
    fetchSession();
  }, [showToastMessage, hasError]);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }
  
  return children;
};

export default ProtectedRoute;
