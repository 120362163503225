import {ValidityType} from "../../core/enums/validityType";

export const validateValidityDuration = (value: number, validityType: ValidityType): string | boolean => {
  
  const validationMap: { [key in ValidityType]: { min: number, max: number, message: string } } = {
    [ValidityType.DAY]: {
      min: 1,
      max: 31,
      message: `For 'DAY', duration must be between 1 and 31.`,
    },
    [ValidityType.WEEK]: {
      min: 1,
      max: 52,
      message: "For 'WEEK', duration must be between 1 and 52.",
    },
    [ValidityType.MONTH]: {
      min: 1,
      max: 12,
      message: "For 'MONTH', duration must be between 1 and 12.",
    },
  };
  
  const {min, max, message} = validationMap[validityType] || {};
  return value >= min && value <= max ? true : message;
};
