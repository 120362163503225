import { Progress } from 'flowbite-react';
import { Tooltip } from 'flowbite-react';
import {ProgressBarProps} from "../../../../core/interface/ProgressBarProps";

export const StageProgressBar = (props: ProgressBarProps) => {
  return (
    <div className="relative mb-6 flex items-center">
      <div className="text-lg font-semibold flex-shrink-0">Start</div>
      <div className="flex-grow mx-4 relative">
        <Progress color="blue" progress={props.progress} className="w-full" />
        <div
          className="absolute top-[-6px] transform -translate-y-1/2"
          style={{ left: `${props.progress}%`, transform: 'translateX(-50%)' }}
        >
          <Tooltip content={`Stage ${Math.ceil((props.progress / 100) * props.stageCount)}`} placement="top">
            <div className="w-6 h-6 bg-white border border-gray-300 rounded-full flex items-center justify-center hover:bg-blue-500"></div>
          </Tooltip>
        </div>
      </div>
      <div className="text-lg font-semibold flex-shrink-0">Finish</div>
    </div>
  );
};
