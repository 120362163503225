import React from "react";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {useParams} from "react-router-dom";
import {useFetchCustomerDetailsApi} from "../services/useCustomerApi";
import {CustomerInterface} from "../../../../core/interface/customer/CustomerInterface";
import {Avatar} from "flowbite-react";
import ProfileField from "../../../../shared/components/profile-field/profileField";

const CustomerDetails: React.FC = () => {
  
  const {id} = useParams<{ id: string }>();
  const {data, isLoading} = useFetchCustomerDetailsApi(id);
  
  const customerDetails: CustomerInterface | undefined = data?.data;
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/staffs" label="Back"/>
        <h1 className="text-xl font-bold mb-4">View Customer</h1>
        <CustomBreadcrumb
          items={[
            {label: "Customers", to: "/customers"},
            {label: "View Customer"}
          ]}
        />
      </div>
      
      <div className="border border-gray-300 rounded-md shadow-md p-6 pb-20 bg-white">
        <div className="flex justify-start space-x-10">
          <div>
            <Avatar size='xl' img={customerDetails?.profile_image}
                    alt={`${customerDetails?.first_name} ${customerDetails?.last_name}`} rounded/>
          </div>
          <div className='w-full'>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4 mb-5">
              <ProfileField label={`First Name`} value={customerDetails?.first_name ? customerDetails.first_name : 'N/A'}/>
              <ProfileField label={`Last Name`} value={customerDetails?.last_name ? customerDetails.last_name : 'N/A'}/>
              <ProfileField label={`Gender`} value={customerDetails?.gender ? customerDetails.gender : 'N/A'}/>
              <ProfileField label={`Enrollment Number`} value={customerDetails?.enrollment_number ? customerDetails.enrollment_number : 'N/A'}/>
              <ProfileField label={`Grade`} value={customerDetails?.grade ? customerDetails.grade : 'N/A'}/>
              <ProfileField label={`Email`} value={customerDetails?.email ? customerDetails.email : 'N/A'}/>
              <ProfileField label={`Phone`} value={customerDetails?.phone_number ? customerDetails.phone_number : 'N/A'}/>
              <ProfileField label={`City`} value={customerDetails?.city ? customerDetails.city : 'N/A'}/>
              <ProfileField label={`Address`} value={customerDetails?.address ? customerDetails?.address : 'N/A'}/>
              <ProfileField label={`Pincode`} value={customerDetails?.pincode ? customerDetails.pincode : 'N/A'}/>
              <ProfileField label={`State`} value={customerDetails?.state ? customerDetails.state : 'N/A'}/>
            </div>
            <h3 className={`text-base font-bold border-b pb-2 mb-5`}>Expert Details</h3>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4 mb-5">
              <ProfileField label={`Entity Name`} value={customerDetails?.entity_name ? customerDetails.entity_name : 'N/A'}/>
              <ProfileField label={`Entity Type`} value={customerDetails?.entity_type ? customerDetails.entity_type : 'N/A'}/>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default CustomerDetails;
