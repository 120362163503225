import React, {useCallback, useState, useMemo, useEffect} from "react";
import { debounce } from "lodash";
import { UpdateEntityPlanDetailsProps } from "../../../../../core/interface/entity/UpdateEntityPlanDetailsProps";
import { Button, Label, Modal, TextInput } from "flowbite-react";
import FormInput from "../../../../../shared/components/form-wrapper/FormInput";
import FormSelect from "../../../../../shared/components/form-wrapper/FormSelect";
import { HiPlus } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { Meeting } from "../../../../../core/interface/entity/EntityPlanInterface";
import FormDatePicker from "../../../../../shared/components/form-wrapper/formDatePicker";
import FormTimePicker from "../../../../../shared/components/form-wrapper/formTimePicker";
import { StaffInterface, StaffsRequestParams } from "../../../../../core/interface/staff/StaffInterface";
import { useGetStaffsApi } from "../../../../users/staffs/services/useStaffApi";

export const CreateMeetingDialog: React.FC<UpdateEntityPlanDetailsProps> = (props) => {
  const guestCount = 20;
  const [hostName, setHostName] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [numberOfMeetings, setNumberOfMeetings] = useState<number | null>(null);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [isSchedule, setIsSchedule] = useState<boolean>(false);
  
  const durationOptions = ["30 mins", "1 hour", "2 hours"];
  const guestOptions = ["1", "5", "10"];
  
  const resetDialog = useCallback(() => {
    setHostName("");
    setNumberOfMeetings(null);
    setMeetings([]);
    setIsSchedule(false);
    setSearchValue(''); // Reset search value
  }, []);
  
  const handleMeetingChange = useCallback((index: number, field: string, value: string) => {
    setMeetings((prevMeetings) =>
      prevMeetings.map((meeting, i) =>
        i === index ? { ...meeting, [field]: value } : meeting
      )
    );
  }, []);
  
  const handleSchedule = useCallback(() => {
    if (hostName && numberOfMeetings && numberOfMeetings > 0) {
      setIsSchedule(true);
      const newMeetings = Array.from({ length: numberOfMeetings }, () => ({
        hostName,
        date: "",
        time: "",
        duration: "",
        guests: "",
      }));
      setMeetings(newMeetings);
    } else {
      alert("Please fill in the host name and number of meetings.");
    }
  }, [hostName, numberOfMeetings]);
  
  const handleDeleteMeeting = useCallback((index: number) => {
    setMeetings((prevMeetings) => {
      const updatedMeetings = prevMeetings.filter((_, i) => i !== index);
      if (updatedMeetings.length === 0) resetDialog(); // Calling resetDialog
      return updatedMeetings;
    });
  }, [resetDialog]);
  
  const handleAddMeeting = useCallback(() => {
    setMeetings((prevMeetings) => [
      ...prevMeetings,
      { hostName, date: "", time: "", duration: "", guests: "5" },
    ]);
  }, [hostName]);
  
  const handleCancel = useCallback(() => {
    resetDialog(); // Calling resetDialog
    props.onClose();
  }, [resetDialog, props]);
  
  const handleSave = useCallback(() => {
    props.onSave(meetings);
    props.onClose();
  }, [meetings, props]);
  
  const debouncedSearch = useMemo(() =>
      debounce((value: string) => {
        setIsSearch(value.length >= 3); // Set search state only here
      }, 300),
    []);
  
  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value); // Update input immediately
    if (value) {
      debouncedSearch(value); // Only debounce the search
    } else {
      setIsSearch(false); // Hide options if input is empty
    }
  }, [debouncedSearch]);
  
  useEffect(() => {
    return () => {
      debouncedSearch.cancel(); // Cancel on unmount
    };
  }, [debouncedSearch]);
  
  const staffRequest: StaffsRequestParams = { filteredSearchQuery: searchValue };
  const { data } = useGetStaffsApi(staffRequest, isSearch);
  const staffList: StaffInterface[] = data?.data.items || [];
  const options = staffList.map((staff) => ({
    id: staff.id,
    value: `${staff.first_name} ${staff.last_name}`,
    label: `${staff.first_name} ${staff.last_name}`,
  }));
  
  return (
    <Modal show={props.isOpen} onClose={props.onClose} size="7xl">
      <div className="flex justify-center pt-4 mb-5">
        <span className="text-lg font-semibold">Update Entity Plan Stage Details</span>
      </div>
      
      <div className="px-10 mb-4">
        <div className="flex space-x-6 mb-4">
          <div className="w-1/2">
            <Label htmlFor="hostName" className="mb-2 block">Host Name</Label>
            <div className="relative">
              <TextInput
                disabled={isSchedule}
                type="search"
                placeholder="Search host name"
                onChange={handleSearch}
                value={searchValue}
              />
              {options.length > 0 && isSearch && ( // Show options only if there are search results
                <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-md mt-1">
                  {options.map((option) => (
                    <div
                      key={option.id}
                      onClick={() => {
                        setHostName(option.value);
                        setSearchValue(option.value); // Set the search value to the selected option
                        setIsSearch(false); // Hide the options after selection
                      }}
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          
          {isSchedule ? (
            <FormInput
              name="guestCount"
              className="w-1/2"
              disabled={isSchedule}
              label="Guest Count"
              type="text"
              placeholder="Enter guest count"
              value={guestCount}
            />
          ) : (
            <FormInput
              name="numberOfMeetings"
              className="w-1/2"
              label="Number of meetings"
              subLabel={`(Guest count: ${guestCount})`}
              type="number"
              placeholder="Enter number of meetings"
              value={numberOfMeetings || ""}
              onChangeInput={(e) => setNumberOfMeetings(Number(e.target.value))}
            />
          )}
        </div>
        
        {!isSchedule && (
          <div className="flex justify-start">
            <Button outline color="blue" onClick={handleSchedule} disabled={!hostName || !numberOfMeetings}>
              Schedule
            </Button>
          </div>
        )}
      </div>
      
      <Modal.Body>
        <div className="meeting-modal-height">
          {meetings.length > 0 && (
            <>
              <h2 className="text-base font-bold border-b pb-2 mb-5">Meeting Details</h2>
              {meetings.map((meeting, index) => (
                <div className="flex items-center space-x-2 mb-4" key={index}>
                  <div className="grid grid-cols-5 gap-4 items-center">
                    <FormInput
                      label="Host name"
                      value={meeting.hostName}
                      onChangeInput={(e) => handleMeetingChange(index, "hostName", e.target.value)}
                      placeholder="Enter host name"
                    />
                    <FormDatePicker
                      name="meetingDate"
                      label="Meeting Date"
                      value={meeting.date}
                      onChangeInput={(e) => handleMeetingChange(index, "date", e.target.value)}
                      placeholder="Select a date"
                    />
                    <FormTimePicker
                      name="meetingTime"
                      label="Meeting Time"
                      value={meeting.time}
                      onChangeInput={(e) => handleMeetingChange(index, "time", e.target.value)}
                      placeholder="Select time"
                    />
                    <FormSelect
                      label="Duration"
                      options={durationOptions.map((option) => ({ value: option, label: option }))}
                      onChangeSelect={(e) => handleMeetingChange(index, "duration", e.target.value)}
                    />
                    <FormSelect
                      label="No. of guests"
                      options={guestOptions.map((option) => ({ value: option, label: option }))}
                      onChangeSelect={(e) => handleMeetingChange(index, "guests", e.target.value)}
                    />
                  </div>
                  <Button className="mt-4" outline color="none" onClick={() => handleDeleteMeeting(index)}>
                    <MdDelete size={20} className="text-red-500" />
                  </Button>
                </div>
              ))}
              
              <div className='flex justify-end items-center'>
                <Button color="green" onClick={handleAddMeeting}>
                  <HiPlus size={20} color='green' className="mr-2" />
                  Add Meeting
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      
      <Modal.Footer className='flex justify-end items-center space-x-2'>
        <Button outline color='blue' onClick={handleCancel}>Cancel</Button>
        <Button color='blue' onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};
