import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import authService from "../../../features/auth/api/authService";
import {Roles} from "../../../core/enums/roles";
import {Sidebar} from 'flowbite-react';
import {HiChartPie} from 'react-icons/hi';
import {FaCalendarCheck, FaUser, FaThumbsUp, FaUserFriends} from 'react-icons/fa';
import {BiSolidBadgeCheck} from "react-icons/bi";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import {FaTv} from "react-icons/fa6";
import {PiReadCvLogoFill} from "react-icons/pi";
import { GrBlog } from "react-icons/gr";

const SidebarMenu: React.FC = () => {
  const location = useLocation();
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  
  const routes = {
    users: ['/staffs', '/customers'],
    staff: ['/staffs'],
    customers: ['/customers'],
    plans: ['/plans', '/plans/create-plan'],
    entities: ['/entities'],
    individualSessions: ['/individual-sessions'],
    groupSessions: ['/group-sessions'],
    assignApprovers: ['/assign-approvers'],
    queries: ['/queries'],
    webinars: ['/webinars'],
    vlogs: ['/vlogs'],
    blogs: ['/blogs'],
  };
  
  const isRouteActive = (paths: string[]) =>
    paths.some(path => location.pathname.startsWith(path));
  
  useEffect(() => {
    (async () => {
      try {
        const role = await authService.getUserRole();
        setUserRole(role as string);
      } catch {
        await authService.signOut();
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return (
    <Sidebar aria-label="mm sidebar-menu">
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <Sidebar.Item
            icon={() => (
              <HiChartPie size={24}
                          className={location.pathname === '/dashboard' ? 'text-blue-600' : 'text-gray-700'}
              />
            )}
            className={location.pathname === '/dashboard' ? 'text-blue-600' : 'text-gray-700'}
            as={NavLink}
            to="/dashboard"
          >
            Dashboard
          </Sidebar.Item>
          
          {(userRole === Roles.SUPER_ADMIN || userRole === Roles.STAFF_ADMIN) && (
            <Sidebar.Collapse
              icon={() => (
                <FaUser size={24} className={isRouteActive(routes.users) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              label="Users" open={isRouteActive(routes.users)}>
              <Sidebar.Item
                className={isRouteActive(routes.staff) ? 'text-blue-600' : 'text-gray-700'}
                as={NavLink}
                to="/staffs"
              >
                Staffs
              </Sidebar.Item>
              {userRole === Roles.STAFF_ADMIN && (
                <Sidebar.Item
                  className={isRouteActive(routes.customers) ? 'text-blue-600' : 'text-gray-700'}
                  as={NavLink}
                  to="/customers"
                >
                  Customers
                </Sidebar.Item>
              )}
            </Sidebar.Collapse>
          )}
          
          {userRole === Roles.SUPER_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <FaCalendarCheck size={24} className={isRouteActive(routes.plans) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.plans) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/plans"
            >
              Plans
            </Sidebar.Item>
          )}
          
          {userRole === Roles.STAFF_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <BiSolidBadgeCheck size={24}
                                   className={isRouteActive(routes.entities) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.entities) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/entities"
            >
              Entities
            </Sidebar.Item>
          )}
          
          {userRole === Roles.STAFF_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <FaUser size={24}
                        className={isRouteActive(routes.individualSessions) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.individualSessions) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/individual-sessions"
            >
              Individual Sessions
            </Sidebar.Item>
          )}
          
          {userRole === Roles.STAFF_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <FaUserFriends size={24}
                               className={isRouteActive(routes.groupSessions) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.groupSessions) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/group-sessions"
            >
              Group Sessions
            </Sidebar.Item>
          )}
          
          {userRole === Roles.STAFF_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <FaThumbsUp size={24}
                            className={isRouteActive(routes.assignApprovers) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.assignApprovers) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/assign-approvers"
            >
              Assign Approvers
            </Sidebar.Item>
          )}
          
          {userRole === Roles.STAFF_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <FaTv size={24} className={isRouteActive(routes.webinars) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.webinars) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/webinars"
            >
              Webinars
            </Sidebar.Item>
          )}
          
          {userRole === Roles.STAFF_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <BsFillQuestionCircleFill size={24}
                                          className={isRouteActive(routes.queries) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.queries) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/queries"
            >
              Queries
            </Sidebar.Item>
          )}
          
          {userRole === Roles.STAFF_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <PiReadCvLogoFill
                  size={24}
                  className={isRouteActive(routes.vlogs) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.vlogs) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/vlogs"
            >
              Manage Vlogs
            </Sidebar.Item>
          )}
          
          {userRole === Roles.STAFF_ADMIN && (
            <Sidebar.Item
              icon={() => (
                <GrBlog
                  size={24}
                  className={isRouteActive(routes.blogs) ? 'text-blue-600' : 'text-gray-700'}/>
              )}
              className={isRouteActive(routes.blogs) ? 'text-blue-600' : 'text-gray-700'}
              as={NavLink}
              to="/blogs"
            >
              Manage Blogs
            </Sidebar.Item>
          )}
        
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
};

export default SidebarMenu;
