import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {VlogInterface} from "../../../core/interface/vlog/VlogInterface";

// Fetch vlogs
export const fetchVlogsApi = async (page: number, pageSize: number,): Promise<CustomResponse<VlogInterface[]>> => {
  const params = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  
  const response = await axios.get<CustomResponse<VlogInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/vlogs`, {params: params},
  );
  return response.data;
};

// Vlog details
export const fetchVlogDetailsApi = async (vlogId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<VlogInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/vlogs/${vlogId}`);
  return res.data;
}

// Vlog create
export const createVlogApi = async (vlogData: any) => {
  try {
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(vlogData.json_data));
    if (vlogData.video) {
      formData.append('video', vlogData.video);
    }
    if (vlogData.thumbnail_image) {
      formData.append('thumbnail_image', vlogData.thumbnail_image);
    }
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs/vlogs`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Update vlog by id
export const updateVlogApi = async (vlogId: string, vlogData: any) => {
  const formData = new FormData();
  formData.append('json_data', JSON.stringify(vlogData.json_data));
  if (vlogData.video) {
    formData.append('video', vlogData.video);
  }
  if (vlogData.thumbnail_image) {
    formData.append('thumbnail_image', vlogData.thumbnail_image);
  }
  try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/vlogs/${vlogId}`, formData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Delete vlog by ID
export const deleteVlogApi = async (vlogId: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/vlogs/${vlogId}`
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error("An unexpected error occurred.");
  }
};

