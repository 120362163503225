import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getYear, getMonth} from "date-fns";
import {Button, Label} from "flowbite-react";
import { IoIosArrowBack, IoIosArrowForward} from "react-icons/io";



interface FormDateTimePickerProps {
  name: string;
  important: boolean;
  label: string;
  register: {
    value: Date | null;
    onChange: (value: Date | null) => void;
  };
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  minDate?: Date;
}

const FormDateTimePicker: React.FC<FormDateTimePickerProps> = (
  {
    name,
    important,
    label,
    register,
    error,
    placeholder,
    disabled,
    className,
    minDate
  }) => {
  const years = Array.from(
    {length: getYear(new Date()) - 1989 + 11},
    (_, i) => 1990 + i
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  
  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return selectedDate > currentDate;
  };
  
  return (
    <div className={className}>
      <Label htmlFor={name} className="mb-2 block">
        {label}
        {important && <span className="text-red-500 ml-1">*</span>}
      </Label>
      <DatePicker
        renderCustomHeader={({
                               date,
                               changeYear,
                               changeMonth,
                               decreaseMonth,
                               increaseMonth,
                               prevMonthButtonDisabled,
                               nextMonthButtonDisabled,
                             }) => (
          <div className="flex justify-center items-center gap-3 mb-3">
            <Button
              type="button"
              color="none"
              size="none"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className={prevMonthButtonDisabled ? "not-allowed" : "pointer"}
            >
              <IoIosArrowBack />
            </Button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) =>
                changeYear(parseInt(value, 10))
              }
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
            <Button
              type="button"
              color="none"
              size="none"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className={nextMonthButtonDisabled ? "not-allowed" : "pointer"}
            >
              <IoIosArrowForward />
            </Button>
          </div>
        )}
        showIcon
        selected={register.value}
        onChange={register.onChange}
        showTimeSelect
        timeFormat="HH:mm aa"
        timeIntervals={5}
        timeCaption="Time"
        dateFormat="MMMM d, yyyy h:mm aa"
        placeholderText={placeholder}
        disabled={disabled}
        filterTime={filterPassedTime}
        minDate={minDate}
        className="w-full p-2 border rounded-md" // Ensures the input box is full-width
      />
      <div>
        {error && <span className="text-red-600">{error}</span>}
      </div>
    </div>
  );
};

export default FormDateTimePicker;
