import React from "react";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Spinner} from "flowbite-react";
import ProfileField from "../../../shared/components/profile-field/profileField";
import {useParams} from "react-router-dom";
import {useFetchAssignApproverDetailsApi} from "../services/useAssignApproversApi";

const AssignApproverDetails: React.FC = () => {
  
  const {id} = useParams<{ id: string }>();
  const {data, isLoading} = useFetchAssignApproverDetailsApi(id);
  console.log('Data', data);
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/assign-approvers" label="Back"/>
        <h1 className="text-xl font-bold mb-4">Assign Approver Details</h1>
        <CustomBreadcrumb
          items={[
            {label: "Assign Approvers", to: "/assign-approvers"},
            {label: "Assign Approver Details"},
          ]}
        />
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
            <ProfileField label={`Plan name`} value={``}/>
            <ProfileField label={`Stage Name`} value={`Stage Name`}/>
            <ProfileField label={`Approver Name`} value={`Approver Name`}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignApproverDetails;
