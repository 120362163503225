import React, {useState} from "react";
import {BlockDialogProps} from "../../../core/interface/ConfirmDialogProps";
import {Button, Modal, Select, TextInput} from "flowbite-react";
import {FaCircleExclamation} from "react-icons/fa6";
import {DisableRequest} from "../../../core/interface/DisableRequest";
import CustomLoaderButton from "../custom-buttons/customLoaderButton";

const BlockDialog: React.FC<BlockDialogProps> = (props: BlockDialogProps) => {
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');
  
  const handleReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReason(e.target.value);
  };
  
  const handleOtherReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReason(e.target.value);
  };
  
  const handleSubmit = () => {
    const reason = selectedReason === "Other" ? otherReason : selectedReason;
    const data: DisableRequest = {
      id: props.staff?.id,
      reason: reason,
      status: props.staff?.is_active ? "DISABLE" : "ENABLE",
    }
    if (props.staff?.id) {
      props.onSubmit(data);
    }
  };
  
  return (
    <Modal show={props.isVisible} size="md" dismissible={false}>
      <Modal.Body>
        {props.title && (
          <h1 className={`text-center text-lg font-bold mb-5`}>
            {props.title}
          </h1>
        )}
        <div className="text-center">
          <FaCircleExclamation className="mx-auto mb-4 h-14 w-14 text-red-700"/>
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {props.subTitle}
          </h3>
          <div className="mb-4">
            <Select
              value={selectedReason}
              onChange={handleReasonChange}
              className="w-full"
            >
              <option value="">Select a reason</option>
              <option value="Spam">Spam</option>
              <option value="Inappropriate Content">Inappropriate Content</option>
              <option value="Violation of Terms">Violation of Terms</option>
              <option value="Other">Other</option>
            </Select>
          </div>
          {selectedReason === "Other" && (
            <div className="mb-4">
              <TextInput
                type="text"
                placeholder="Enter other reason"
                value={otherReason}
                onChange={handleOtherReasonChange}
                className="w-full"
              />
            </div>
          )}
          <div className="flex justify-center gap-4">
            {/*<Button color={props.submitButtonClass} onClick={handleSubmit}>*/}
            {/*  {props.submitButtonText}*/}
            {/*</Button>*/}
            <CustomLoaderButton
              size="lg"
              color={props.submitButtonClass}
              type="button"
              loading={props.loading}
              onClick={handleSubmit}>
              {props.submitButtonText}
            </CustomLoaderButton>
            <Button size='lg' outline color="blue" onClick={props.onClose}>
              {props.cancelButtonText}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BlockDialog;
