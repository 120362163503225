export enum Designation {
  STAFF_ADMIN = "Staff Admin",
  FINANCE_ADMIN = "Finance Admin",
  BD_ADMIN = "BD Admin",
  MARKETING_ADMIN = "Marketing Admin",
  HOD_COUNSELLOR = "HoD Counsellor",
  IN_HOUSE_COUNSELLOR = "In-House Counsellor",
  FREELANCE_COUNSELLOR = "Freelance Counsellor",
  INDUSTRY_EXPERT = "Industry Expert",
  STUDY_ABROAD_EXPERT = "Study Abroad Expert",
  ENTRANCE_PATHWAY_EXPERT = "Entrance Pathway Expert",
  CPCC_TRAINER = "CPCC Trainer",
  SCHOOL_STUDENT = "School Student",
  COLLEGE_STUDENT = "College Student",
  WORKING_PROFESSIONAL = "Working Professional",
  CPCC_STUDENT = "CPCC Student",
  SCHOOL_ADMIN = "School Admin",
  COLLEGE_ADMIN = "College Admin",
  CORPORATE_ADMIN = "Corporate Admin",
  CPCC_ADMIN = "CPCC Admin",
  CUSTOMER_ADMIN = "Customer Admin",
}
