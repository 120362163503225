import React, {useEffect, useMemo, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import FormInput from "../../../../shared/components/form-wrapper/FormInput";
import FormSelect from "../../../../shared/components/form-wrapper/FormSelect";
import {BusinessModel} from "../../../../core/enums/businessModel";
import {Region} from "../../../../core/enums/region";
import CustomLoaderButton from "../../../../shared/components/custom-buttons/customLoaderButton";
import {Avatar, Button, FileInput, Label} from "flowbite-react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {EntityType} from "../../../../core/enums/entityType";
import FormTextarea from "../../../../shared/components/form-wrapper/FormTextarea";
import {useCreateEntityApi, useFetchEntityDetailsApi, useUpdateEntityApi} from "../services/useEntityApi";
import AddEntityAdminFormDialog from "../../../users/entity-admin/components/add-entity-admin-form-dialog/addEntityAdminFormDialog";
import {EntityInterface} from "../../../../core/interface/entity/EntityInterface";
import {MdOutlineImageNotSupported} from "react-icons/md";
import EntityAdminView from "../../../users/entity-admin/components/entity-admin-view/entityAdminView";

const entityValidationSchema = (isCoBranding: boolean) =>
  yup.object({
    business_model: yup.string().required("Business Model is required"),
    name: yup.string().required("Name is required"),
    type: yup.string().required("Type is required"),
    region: yup.string().required("Region is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    pincode: yup.string().required("Pincode is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    phone_number: yup.string()
      .required('Phone number is required')
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .test('not-starts-with-0-5', 'Enter a valid phone number', (value: any) => {
        return value && !/^[0-5]/.test(value);
      }),
    logo_url: isCoBranding
      ? yup.mixed()
        .required("Logo is required")
        .test("fileSelected", "Please upload a logo file", value => value && (value instanceof File || !!value))
      : yup.mixed(),
    tagline: isCoBranding ? yup.string().required("Tagline is required") : yup.string(),
    description: isCoBranding ? yup.string().required("Description is required") : yup.string(),
  });


const CreateEntity: React.FC = () => {
  
  const {entityId} = useParams<{ entityId: string }>();
  const {data} = useFetchEntityDetailsApi(entityId);
  const entityDetails: EntityInterface | undefined = data?.data;
  
  const navigate = useNavigate();
  const businessModalOptions = useMemo(() => Object.values(BusinessModel), []);
  const regionOptions = useMemo(() => Object.values(Region), []);
  const entityTypeOptions = useMemo(() => Object.values(EntityType), []);
  
  const [loading, setLoading] = useState(false);
  const [isCoBranding, setIsCoBranding] = useState(false);
  const [isAddAdminFormDialog, setIsAddAdminFormDialog] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  const [isAddEntityAdmin, setIsAddEntityAdmin] = useState(false);
  const [entityIdByResponse, setEntityIdByResponse] = useState<any>('');
  
  const {mutate: createEntity} = useCreateEntityApi();
  const {mutate: updateEntity} = useUpdateEntityApi();
  const {
    register: registerEntity,
    setValue: setEntityValue,
    handleSubmit: handleSubmitEntity,
    formState: {errors: entityErrors},
  } = useForm({
    resolver: yupResolver(entityValidationSchema(isCoBranding)),
  });
  
  const onSubmitEntityForm = async (data: any) => {
    data.phone_number = `+91${data.phone_number}`;
    setLoading(true);
    const {logo_url, tagline, description, ...json_data} = data;
    
    if (isCoBranding) {
      json_data.co_branding = {tagline, description};
    }
    const entityFormData = {
      json_data,
      co_branding_logo: logo_url,
    };
    
    const updatedEntityData = {
      name: data.name,
      region: data.region,
      address: data.address,
      city: data.city,
      state: data.state,
      pincode: data.pincode,
      phone_number: data.phone_number,
      email: data.email,
    }
    
    if (entityId) {
      const mutationData = {entityId: entityId, data: updatedEntityData};
      updateEntity(mutationData, {
        onSuccess: () => {
          navigate('/entities');
        },
        onSettled: () => {
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        }
      });
    } else {
      createEntity(entityFormData, {
        onSuccess: (response) => {
          const idMatch = response.message.match(/with id ([a-zA-Z0-9]+)/);
          const entityId = idMatch ? idMatch[1] : null;
          setEntityIdByResponse(entityId);
          setIsDisableFields(true);
          setIsAddEntityAdmin(true);
        },
        onSettled: () => {
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        }
      });
    }
  };
  
  const openAddAdminFormDialog = () => {
    setIsAddAdminFormDialog(true);
  }
  
  const handleBusinessModelChange = (e: any) => {
    const selectedBusinessModel = e.target.value;
    if (selectedBusinessModel.includes('CO-BRANDING')) {
      setIsCoBranding(true);
    } else {
      setIsCoBranding(false);
      setEntityValue('logo_url', '');
      setEntityValue('tagline', '');
      setEntityValue('description', '');
    }
  };
  
  useEffect(() => {
    if (entityDetails) {
      Object.entries(entityDetails).forEach(([key, value]) => {
        setEntityValue(key as any, value);
      });
      
      const formattedPhoneNumber = entityDetails.phone_number?.replace(/^\+91/, '') || '';
      setEntityValue("phone_number", formattedPhoneNumber);
      
      if (entityDetails?.co_branding?.logo_url && entityDetails?.co_branding?.tagline && entityDetails?.co_branding?.description) {
        setIsCoBranding(true);
        setSelectedImage(entityDetails?.co_branding?.logo_url);
        setEntityValue('logo_url', entityDetails?.co_branding?.logo_url);
        setEntityValue('tagline', entityDetails?.co_branding?.tagline);
        setEntityValue('description', entityDetails?.co_branding?.description);
      }
      setIsAddEntityAdmin(true);
    }
  }, [entityId, setEntityValue, entityDetails]);
  
  
  const [selectedImage, setSelectedImage] = useState<string | undefined>('');
  
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setEntityValue('logo_url', file);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };
  
  return (
    <div>
      <div className="mb-4">
        <div className="mb-4">
          <BackButton to="/entities" label="Back"/>
        </div>
        <h1 className="text-xl font-bold mb-4">
          {entityId ? 'Update Entity' : 'Create Entity'}
        </h1>
        <CustomBreadcrumb
          items={[
            {label: 'Entities', to: '/entities'},
            {label: entityId ? 'Update Entity' : 'Create Entity'},
          ]}
        />
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-6">
        <form className='mb-8' onSubmit={handleSubmitEntity(onSubmitEntityForm)}>
          <div className="flex space-x-6 mb-4">
            <FormSelect
              className='w-1/2'
              disabled={loading || isDisableFields || !!entityId}
              label="Business model"
              important={true}
              options={businessModalOptions.map((option) => ({value: option, label: option}))}
              register={registerEntity("business_model")}
              onChangeSelect={handleBusinessModelChange}
              error={entityErrors.business_model?.message}
            />
            <FormInput
              disabled={loading || isDisableFields}
              className='w-1/2'
              label="Entity name"
              type='text'
              important={true}
              register={registerEntity("name")}
              error={entityErrors.name?.message}
              placeholder="Enter entity name"
            />
          </div>
          <div className="flex space-x-6 mb-4">
            <FormSelect
              className='w-1/2'
              disabled={loading || isDisableFields || !!entityId}
              label="Entity Type"
              important={true}
              options={entityTypeOptions.map((option) => ({value: option, label: option}))}
              register={registerEntity("type")}
              error={entityErrors.type?.message}
            />
            <FormSelect
              className='w-1/2'
              disabled={loading || isDisableFields}
              label="Region"
              important={true}
              options={regionOptions.map((option) => ({value: option, label: option}))}
              register={registerEntity("region")}
              error={entityErrors.region?.message}
            />
          </div>
          <div className="flex space-x-6 mb-4">
            <FormInput
              disabled={loading || isDisableFields}
              className='w-1/2'
              label="Address"
              type='text'
              important={true}
              register={registerEntity("address")}
              error={entityErrors.address?.message}
              placeholder="Enter address"
            />
            
            <FormInput
              disabled={loading || isDisableFields}
              className='w-1/2'
              label="City"
              type='text'
              important={true}
              register={registerEntity("city")}
              error={entityErrors.city?.message}
              placeholder="Enter city"
            />
          </div>
          <div className="flex space-x-6 mb-4">
            <FormInput
              disabled={loading || isDisableFields}
              className='w-1/2'
              label="State"
              important={true}
              register={registerEntity("state")}
              error={entityErrors.state?.message}
              placeholder="Enter state"
            />
            
            <FormInput
              disabled={loading || isDisableFields}
              className='w-1/2'
              label="Pincode"
              important={true}
              register={registerEntity("pincode")}
              error={entityErrors.pincode?.message}
              placeholder="Enter pincode"
            />
          </div>
          <div className="flex space-x-6 mb-4">
            <FormInput
              disabled={loading || isDisableFields}
              className='w-1/2'
              label="Phone number"
              important={true}
              register={registerEntity("phone_number")}
              error={entityErrors.phone_number?.message}
              placeholder="Enter phone number"
            />
            
            <FormInput
              disabled={loading || isDisableFields}
              className='w-1/2'
              label="Email"
              type='email'
              important={true}
              register={registerEntity("email")}
              error={entityErrors.email?.message}
              placeholder="Enter email address"
            />
          </div>
          
          {isCoBranding && (
            <div className={`mb-4`}>
              <h2 className={`font-bold text-base mb-4 border-b pb-4`}>Co-Branding Details</h2>
              <div className="flex space-x-6 mb-4">
                <FormInput
                  disabled={loading || isDisableFields || !!entityId}
                  className='w-1/2'
                  label="Tagline"
                  important={true}
                  register={registerEntity("tagline")}
                  error={entityErrors.tagline?.message}
                  placeholder="Enter tagline"
                />
                
                <FormTextarea
                  className={`w-1/2`}
                  disabled={loading || isDisableFields || !!entityId}
                  label="Description"
                  important={true}
                  register={registerEntity('description')}
                  error={entityErrors.description?.message}
                  placeholder="Write text here ..."
                />
              </div>
              
              <div className="flex space-x-6 mb-4">
                <div className="w-1/2">
                  <Label htmlFor="logo-lable" className="mb-2 block">
                    Entity logo<em>*</em>
                  </Label>
                  
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0 relative">
                      {selectedImage ? (
                        <Avatar
                          img={selectedImage}
                          alt="Profile Avatar"
                          size="xl"
                          className="rounded-md border-2 border-gray-500 p-2"
                        />
                      ) : (
                        <div
                          className="flex items-center justify-center w-40 h-40 rounded-md border-2 border-gray-500 p-2"> {/* Adjust to match Avatar size */}
                          <MdOutlineImageNotSupported className="text-gray-500"
                                                      size={64}/>
                        </div>
                      )}
                    </div>
                    
                    <div className="flex space-x-2">
                      <Label disabled={!!entityId || isDisableFields} htmlFor="entity-logo"
                             className={`p-2 text-sm font-medium border rounded cursor-pointer ${
                               !!entityId || isDisableFields ? 'text-gray-400 border-gray-400 cursor-not-allowed' : 'text-blue-600 border-blue-600 hover:bg-blue-50'
                             }`}>
                        Upload entity icon
                      </Label>
                      <FileInput
                        {...registerEntity('logo_url')} disabled={loading || isDisableFields || !!entityId}
                        id="entity-logo"
                        className="hidden"
                        onChange={handleImageUpload}
                        aria-label="Entity logo"
                      />
                      <Button
                        disabled={!selectedImage || !!entityId || isDisableFields}
                        color='gray'
                        size='sm'
                        type="button"
                        onClick={() => setSelectedImage('')}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                  {!selectedImage && (
                    <span className="text-red-600">Logo is required</span>
                  )}
                </div>
              </div>
            </div>
          )}
          
          <div className="flex space-x-8">
            <Link to={'/entities'}>
              <Button size="lg" outline color="blue" type="button">
                {isDisableFields ? 'Skip & Continue' : 'Go Back'}
              </Button>
            </Link>
            <CustomLoaderButton
              size="lg"
              color="blue"
              type="submit"
              disabled={isDisableFields}
              loading={loading}>
              {entityId ? 'Update Entity' : 'Create Entity'}
            </CustomLoaderButton>
          </div>
        </form>
        
        {entityId && entityDetails?.entity_admin ? (
          <EntityAdminView entityAdmin={entityDetails?.entity_admin} title='Admin Details' isButton={true}/>
        ) : (
          <div>
            <AddEntityAdminFormDialog
              entityId={entityId ? entityId : entityIdByResponse}
              isOpen={isAddAdminFormDialog}
              onClose={() => setIsAddAdminFormDialog(false)}
            />
            
            <h2 className={`font-bold text-base mb-4 border-b pb-4`}>Admin Details</h2>
            <div className='flex space-x-8'>
              <Button size="xl" color='blue' outline type="button" disabled={!isAddEntityAdmin}
                      onClick={openAddAdminFormDialog}>
                Add Admin
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateEntity;
