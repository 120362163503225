import moment from "moment-timezone";

export const dateAndTimeFormatter = (
  date: string | undefined | null,
  format: string = "DD-MM-YYYY, h:mm a",
  timezone: string = "Asia/Kolkata"
): string => {
  if (!date) return "Invalid Date";
  
  return moment.utc(date).tz(timezone).format(format);
};
